import API from "../utils/axios";
let common = "/v1/api";

export const GET_PAYMENT_PARTNERS = (data: any): Promise<any> => {
  return API.get(`${common}/payment/partners`, { params: data });
};

export const PAYMENT_PARTNER_CREATE = (data: any): Promise<any> => {
  return API.post(`${common}/payment/partners`, data);
};

export const PAYMENT_PARTNER_UPDATE = (id: any, data: any): Promise<any> => {
  return API.put(`${common}/payment/partners/${id}`, data);
};

export const PAYMENT_PARTNER_DELETE = (uuid: any): Promise<any> => {
  return API.delete(`${common}/payment/partners/${uuid}`);
};
