import API from "../utils/axios";
let common = "/v1/api/system";

export const GET_SYSTEM_PARAMS = (data: any): Promise<any> => {
  return API.get(`${common}/params`, { params: data });
};

export const SYSTEM_PARAMS_CREATE = (data: any): Promise<any> => {
  return API.post(`${common}/params`, data);
};

export const SYSTEM_PARAMS_UPDATE = (id: any, data: any): Promise<any> => {
  return API.put(`${common}/params/${id}`, data);
};

export const SYSTEM_PARAMS_DELETE = (id: any): Promise<any> => {
  return API.delete(`${common}/params/${id}`);
};
