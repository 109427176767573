import React from "react";
// import { connect } from "react-redux";
// import { Loading } from "../../redux/actions";

interface Props {
  LoadingReducer?: any;
}

export const Loader: React.FC<Props> = (props: Props) => {
  return (
    <>
      {props?.LoadingReducer && (
        <div className="text-center p-5 custom_spinner">
          <div className="spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      )}
    </>
  );
};

// const mapStateToProps = (state: any) => {
//   return {
//     LoadingReducer: state.LoadingReducer,
//   };
// };

// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     loading: (data: any) => dispatch(Loading(data)),
//   };
// };

// const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(Loader);
// export { connectedNavBar as Loader };
