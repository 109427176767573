import API from "../utils/axios";
let common = '/v1/api';

export const GET_SUBSCRIPTIONS = (data: any): Promise<any> => {
    return API.get(`${common}/subscriptions`, { params: data });
};

export const SUBSCRIPTION_CREATE = (data: any): Promise<any> => {
    return API.post(`${common}/subscriptions`, data);
};

export const SUBSCRIPTION_UPDATE = (id: any, data: any): Promise<any> => {
    return API.put(`${common}/subscriptions/${id}`, data);
};

export const SUBSCRIPTION_DELETE = (id: any): Promise<any> => {
    return API.delete(`${common}/subscriptions/${id}`);
};