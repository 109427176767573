import { DataTableCol } from "../../../../../../components/data-table/types";

export const IntegrationsDataGridCols: DataTableCol[] = [
    {
        title: 'Gateway Name',
        control: 'gateway_name',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'API Key',
        control: 'api_key',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Secret Key',
        control: 'secret_key',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        isView: false,
        isAddAddress: true,
        canShowColumn: true
    },
];