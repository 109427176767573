import API from "../utils/axios";
let common = "/v1/api";

export const GET_SITES = (data: any): Promise<any> => {
  return API.get(`${common}/sites`, { params: data });
};

export const SITE_CREATE = (data: any): Promise<any> => {
  return API.post(`${common}/sites`, data);
};

export const SITE_UPDATE = (id: any, data: any): Promise<any> => {
  return API.put(`${common}/sites/${id}`, data);
};

export const SITE_DELETE = (id: any): Promise<any> => {
  return API.delete(`${common}/sites/${id}`);
};
