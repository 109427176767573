import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { ToolsDecryptorPage } from '../pages/dashboard/tools/decryptor'
import { ToolsEncryptorPage } from '../pages/dashboard/tools/encryptor'
import { ToolsAPITesterPage } from '../pages/dashboard/tools/api-tester'

export const ToolsRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ToolsEncryptorPage />}></Route>
            <Route path="/encryptor" element={<ToolsEncryptorPage />}></Route>
            <Route path="/decryptor" element={<ToolsDecryptorPage />}></Route>
            <Route path="/API-tester" element={<ToolsAPITesterPage />}></Route>
        </Routes>
    )
}
