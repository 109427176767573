import React from 'react'

export const PaymentPartnerCreationForm = () => {
    return (
        <div className='container-fluid px-4 content-bg-color'>
            <div className="row my-3">
                <div className='col-md-12'>Create Payment Partner</div>
            </div>
        </div>
    )
}
