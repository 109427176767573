import React, { useEffect, useState } from 'react'
import { DynamicForm } from '../../../../components/form-builder/form'
import { Search } from '../../../../components/search/search';
import { DataTable } from '../../../../components/data-table';
import { DeploymentVersionDataGridCols } from './deployment-version-data-grid-cols';
import { useNavigate } from 'react-router-dom';
import { DEPLOYMENT_VERSION_CREATE, DEPLOYMENT_VERSION_DELETE, DEPLOYMENT_VERSION_UPDATE, GET_DEPLOYMENT_VERSION } from '../../../../app/services/deployment-versions.service';
import toast from 'react-hot-toast';
import { Loader } from '../../../../components/loader';
import moment from 'moment';

export const DeploymentVersions = () => {
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>([]);
  const navigation = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [tableListData, setTableListData] = useState<any>([]);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });

  const [formData, setFormData] = useState<any>([
    {
      title: "input",
      type: "number",
      label: "Version",
      name: "version",
      formFloating: false,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please enter version",
      error: "Please enter version",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "date",
      label: "Date",
      name: "deployed_dt",
      formFloating: false,
      value: '',
      required: true,
      placeholder: "Please select date",
      error: "Please select date",
      hideMandatoryStar: false,
    },
    {
      title: "dropdown",
      type: "dropdown",
      label: "Type",
      name: "type",
      option: [
        { label: "frontend", value: 'FRONTEND' },
        { label: "backend", value: 'BACKEND' },
        { label: "middleware", value: 'MIDDLEWARE' },
      ],
      value: 'FRONTEND',
      regex: "",
      required: true,
      placeholder: "Please select type",
      error: "Please select type",
      hideMandatoryStar: false
    },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-end my-2",
      buttons: [
        {
          title: 'Create',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16'
        }
      ]
    },
  ])

  const onActions = (data: any) => {
    setSelectedData(data?.data)
    if (data?.type === "Edit") {
      setIsEdit(true);
      const categoryFormData = formData;
      setFormData(categoryFormData);
      categoryFormData?.forEach((element: any) => {
        if (element?.type === "button") {
          for (let i = 0; i < element?.buttons?.length; i++) {
            const ele = element?.buttons;
            ele[0].title = "Update";
            ele[0].action = "edit";
          }
        }
        if (element.name === 'deployed_dt') {
          element.value = moment(data?.data[element.name]).format('YYYY-MM-DD');
        } else {
          element.value = data?.data[element.name];
        }
      });
      setFormData([...categoryFormData]);
      setShowCreatePopup(true);
    } else if (data?.type === "Delete") {
      setShowDeletePopup(true);
    }
  }

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getDeploymentVersion(pageCount);
  };

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getDeploymentVersion(pageCount);
  };

  const onShowCreatePopup = () => {
    setShowCreatePopup(true);
    setIsEdit(false);
    const categoryFormData = formData;
    categoryFormData?.forEach((element: any) => {
      if (element?.type === "button") {
        for (let i = 0; i < element?.buttons?.length; i++) {
          const ele = element?.buttons;
          ele[0].title = "Create";
          ele[0].action = "add";
        }
      }
      element.value = "";
    })
    setFormData([...formData])
  }

  useEffect(() => {
    getDeploymentVersion(pageCount);
  }, [])


  const getDeploymentVersion = (data: any) => {
    setLoading(true);
    GET_DEPLOYMENT_VERSION(data).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          setTableListData(res?.data);
          setLoading(false);
        }
      }
    });
  }
  const onFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
      const formData = event?.formData;
      delete formData['undefined'];
      setLoading(true);
      DEPLOYMENT_VERSION_CREATE(formData).then((res: any) => {
        if (res?.status === "fail") {
          setLoading(false);
          toast.error(res.error, { position: "top-right" });

        } else {
          if (res) {
            toast.success(res.message, { position: 'top-right' })
            setLoading(false);
            getDeploymentVersion(pageCount);
            setShowCreatePopup(false);
          }
        }
      });
    } else if (data?.type === "edit") {
      const formData = data?.formData;
      delete formData['undefined'];
      setLoading(true);
      DEPLOYMENT_VERSION_UPDATE(selectedData?.uuid, formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error, { position: "top-right" });
          setLoading(false);
        } else {
          if (res) {
            setShowCreatePopup(false);
            getDeploymentVersion(pageCount);
            toast.success(res.message, { position: 'top-right' })
            setLoading(false);
          }
        }
      });
    } else if (data?.type === "cancel") {
      setShowCreatePopup(false);
    }
  }
  const onSearch = (value: any) => { };

  const onDeleteUserConfirmation = () => {
    setLoading(true);
    DEPLOYMENT_VERSION_DELETE(selectedData?.uuid).then((res: any) => {
      if (res?.status === "fail") {
        toast.error(res.error, { position: 'top-right', })
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message, { position: 'top-right', })
          setLoading(false);
          setShowDeletePopup(false);
          getDeploymentVersion(pageCount)
        }
      }
    });
  }

  return (
    <div className='container-fluid content-bg-color'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='w-100'>
            <Search onSearch={(e: any) => onSearch(e)} />
          </div>
        </div>
        <div className='col-md-4 text-end'>
          <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create Deployment Version</button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 px-0'>
          <Loader LoadingReducer={loading} />
          <DataTable
            tableData={tableListData}
            TableCols={DeploymentVersionDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e) => onSelectPage(e)}
            pageCount={pageCount}
          />
        </div>
        {showCreatePopup && (
          <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
            <Loader LoadingReducer={loading} />
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-bottom-primary mx-4 px-0">
                  <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Deployment Version</strong></h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                </div>
                <div className="modal-body px-4 text-start">
                  <div className='row'>
                    <DynamicForm
                      userFormData={formData}
                      columns={2}
                      isEdit={isEdit}
                      onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                      customClass={"display-block"}
                      isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showDeletePopup && (
          <>
            <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
              <Loader LoadingReducer={loading} />
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-start">
                  <div className="modal-header border-bottom-primary mx-4 px-0">
                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                  </div>
                  <div className="modal-body pb-5">
                    <div className="row px-2">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.version || ""}</span>?</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-2 text-end'>
                      <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                      <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
