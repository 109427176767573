import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { BreadCrumb } from '../../../../components/bread-crumb';
import { SiteTabRoutes } from '../../../../routes/site-tabs-routes';
import { GET_SITES } from '../../../../app/services/sites.service';
export const CustomerSitesTabInfo = () => {
  const params = useParams();
  const location = useLocation();
  const currentPath = location.pathname.split("/");
  const [loading, setLoading] = useState<boolean>(false);

  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
      label: "Site Name",
      value: "Sites",
      routerLink: `/dashboard/sites`,
      isActive: false,
    },
    {
      label: "Site Name",
      value: "",
      routerLink: `/dashboard/sites/${params?.customer_site_uuid}`,
      isActive: true,
    },
  ]);
  
  const sitesData = [
    {
      id: "11",
      site: "www.blazon.com",
      site_name: "Blazon",
      country: "Etisalat-UAE",
    },
    {
      id: "12",
      site: "www.playforia.com",
      site_name: "Playforia",
      country: "DU-UAE",
    },
    {
      id: "13",
      site: "www.games.com",
      site_name: "Games",
      country: "Zain-Kuwait",
    },
  ];

  useEffect(() => {
    // const customerId = params?.customer_uuid;
    // const filterData = customersData?.filter((el: any) => el?.id === customerId);
    // const data = breadcrumbInfo;
    // // data[1].value = filterData[0]?.name;
    // // setBreadcrumbInfo([...data]);

    const siteId = params?.customer_site_uuid;
    getSiteDataById(siteId)
    const filterSiteData = sitesData?.filter((el?: any) => el?.id === siteId);
    const data = breadcrumbInfo;
    data[1].value = filterSiteData[0]?.site_name
    setBreadcrumbInfo([...data]);
    // const breadcrumbData = breadcrumbInfo;
    // breadcrumbData[1].value = filterSiteData[0].site_name
  }, [])


  const tabs = [
    {
      id: "1",
      name: "Subscriptions",
      link: `/dashboard/sites/${params?.customer_site_uuid}/subscriptions`,
    },
    {
      id: "2",
      name: "Content",
      link: `/dashboard/sites/${params?.customer_site_uuid}/content`,
    },
    // {
    //   id: "3",
    //   name: "Integrations",
    //   link: `/dashboard/sites/${params?.customer_site_uuid}/integrations`,
    // },
    // {
    //   id: "4",
    //   name: "Reports",
    //   link: `/dashboard/sites/${params?.customer_site_uuid}/reports`,
    // },
  ];

  const getSiteDataById = (siteData: any) => {
    setLoading(true);
    const user_uuid = sessionStorage?.getItem('user_uuid')
    GET_SITES({ user_uuid:user_uuid, uuid: siteData }).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          const response = res?.data?.rows[0];
          const data = breadcrumbInfo;
          data[1].value = response?.code
          setBreadcrumbInfo([...data])
          setLoading(false);
        }
      }
    });
  }


  return (
    <div className='col-md-12 mt-3 content-bg-color'>
      <div className='row mb-3'>
        <div className='col-md-12 px-4'>
          <BreadCrumb breadCrumbList={breadcrumbInfo}></BreadCrumb>
        </div>
      </div>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {tabs.map((data: any, index: number) => {
          return <NavLink key={index} to={data?.link} className={`nav-link text-dark px-4 fs-13
                    ${currentPath.length === 4 && data?.name === "Subscriptions"
              ? "active"
              : ""
            } `}>{data?.name}</NavLink>
        })}
      </ul>
      <div className="tab-content shadow" id="myTabContent">
        <SiteTabRoutes />
      </div>
    </div>
  )
}
