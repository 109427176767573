import { DataTableCol } from "../../../../components/data-table/types";

export const PaymentPartnerReportsDataGridCols: DataTableCol[] = [
  {
    title: "Payment Partner",
    control: "payment_partner_code",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Subscriptions Count",
    control: "total_subscriptions",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Amount",
    control: "total_amount",
    sortable: true,
    canShowColumn: true,
  },
  // {
  //   title: "Processed",
  //   control: "processed",
  //   sortable: true,
  //   canShowColumn: true,
  // },
  // {
  //   title: "Status",
  //   control: "status",
  //   sortable: true,
  //   canShowColumn: true,
  // },
];
