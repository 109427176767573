import { DataTableCol } from "../../../../components/data-table/types";

export const SiteGridCols: DataTableCol[] = [
  {
    title: "Code",
    control: "code",
    sortable: true,
    canShowColumn: true,
    isLink: true,
  },
  {
    title: "Service Id",
    control: "service_id",
    isServiceID:true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Api key",
    control: "apikey",
    isHideAndShow:true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Vector Key",
    control: "vector_key",
    isHideAndShow:true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Secret Key",
    control: "secret_key",
    isHideAndShow:true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Contact Email",
    control: "contact_email",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Contact Mobile",
    control: "contact_mobile",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Actions",
    control: "actions",
    sortable: false,
    isDelete: true,
    isEdit: true,
    isView: false,
    canShowColumn: true,
  },
];
