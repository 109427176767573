import React, { useState } from "react";
interface Props {
  onSearch?: (data: string) => void;
  vehicleSearchCss?: boolean;
  subscriptionSearch?: boolean;
}
export const Search = (props: Props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showWrongIcon, setWrongIconShow] = useState<boolean>(false);

  const handleSearchInput = (e: any) => {
    const search = e.target.value;
    if (!search) {
      setWrongIconShow(false);
      onClickSearch(false);
    }
    setSearchTerm(search);
  };

  const onClickSearch = (isEnpty: boolean) => {
    if (isEnpty) {
      setWrongIconShow(true);
    }
    if (props.onSearch) {
      props.onSearch(isEnpty ? searchTerm : '');
    }
  };

  const onClickCancel = () => {
    setSearchTerm("");
    setWrongIconShow(false);
    if (props.onSearch) {
      props.onSearch("");
    }
  };
  const onClickEnterSearch = (e: any) => {
    if (e.key === 'Enter') {
      onClickSearch(true);
    }
  }

  return (
    <div className="row px-0">
      <div className={`${props?.subscriptionSearch ? "col-md-3":"col-md-4 px-0"}`}>
        <input
          type="text"
          className="form-control search"
          value={searchTerm}
          placeholder="search"
          onChange={handleSearchInput}
          onKeyDown={onClickEnterSearch}
        ></input>
        <span className="search-position">
          {showWrongIcon ? (
            <i className="bi bi-x" onClick={onClickCancel}></i>
          ) : (
            <i className="bi bi-search" onClick={() => onClickSearch(true)}></i>
          )}
        </span>
      </div>
    </div>
  );
};
