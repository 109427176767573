import API from "../utils/axios";
let common = '/v1/api';

export const GET_PAYMENT_TYPES = (data: any): Promise<any> => {
    return API.get(`${common}/payment/types`, { params: data });
};

export const CREATE_PAYMENT_TYPE = (data: any): Promise<any> => {
    return API.post(`${common}/payment/types`, data);
};
export const UPDATE_PAYMENT_TYPE = (uuid: any, data: any): Promise<any> => {
    return API.put(`${common}/payment/types/${uuid}`, data);
};

export const DELETE_PAYMENT_TYPE = (data: any): Promise<any> => {
    return API.delete(`${common}/payment/types/${data}`);
};