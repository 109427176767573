import React from "react";
export const APIIntegrations = () => {
    return (
        <div className="m-2">
            <div className="container-fluid content-bg-color box-shadow">
                <h4 className="mb-0">API Integrations</h4>

                <div className="accordion mt-4" id="accordionExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Login API
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong> Url : </strong> https://blazon/login.<br />
                                <strong>Pay Load : </strong>{'{ user_name: \'user@mail.com\', password: \'Abc@123\' }'}<br />
                                <strong>Response : </strong>{'{ login_status: \'succes\', password: \'*******\' }'}
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Payment API
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong> Url : </strong> https://blazon/payment.<br />
                                <strong>Pay Load : </strong>{'{ currency: \'USD\', value: \'10.00\' }'}<br />
                                <strong>Response : </strong>{'{ payemt_status: \'succes\', value: \'10.00\' }'}

                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Subscription API
                            </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body">
                                <strong> Url : </strong> https://blazon/subscription.<br />
                                <strong>Pay Load : </strong>{'{ currency: \'USD\', value: \'100.00\' }'}<br />
                                <strong>Response : </strong>{'{ payemt_status: \'succes\', value: \'100.00\' }'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}