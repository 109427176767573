import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useNavigate, useParams } from "react-router-dom";
import { DynamicForm } from "../../../../../components/form-builder/form";
import { DataTable } from "../../../../../components/data-table";
import { BreadCrumb } from "../../../../../components/bread-crumb";
import { RevenueContentDataGridCols } from "./service-content-table-col-grid";

export const RevenueContentData = () => {
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();

  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
        label: "Revenue Reports",
        value: "Revenue Reports",
        routerLink: "/dashboard/reports/revenue", 
        isActive: false,
    },
    {
        label: "Site Name",
        value: "site : ",
        routerLink: `/dashboard/reports/revenue/${params?.site_id}/revenue-site`,
        isActive: false,
    },
    {
        label: "Customer Name",
        value: "Content : ",
        routerLink: ``,
        isActive: true,
    },
]);
  const [revenueReportsForm, setRevenueReportsForm] = useState<any>([
    {
      title: "dropdown",
      
      type: "text",
      label: "Content Type",
      name: "content",
      option: [
        {
          label: "Game",
          value: "game",
        },
        { label: "Video", value: "video" },
        {
          label: "Blog",
          value: "blog",
        },
      ],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Content Type",
      error: "Please select Content Type",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
    },
    {
      title: "dropdown",
      
      type: "text",
      label: "Contents",
      name: "Contents",
      option: [
        {
          label: "Content 1",
          value: "content1",
        },
        { label: "Content 2", value: "Content2" },
        {
          label: "Content 3",
          value: "Content3",
        },
        {
          label: "Content 4",
          value: "Content4",
        },
        { label: "Content 5", value: "Content5" },
      ],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Contents",
      error: "Please select Contents",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
    },
    {
      title: "input",
      
      type: "date",
      label: "Dates",
      name: "start_time",
      formFloating: false,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please select start time",
      error: "Please select start time",
      hideMandatoryStar: true,
    },
    {
      title: "dropdown",
      
      type: "text",
      label: "Products",
      name: "products",
      option: [
        { label: "TyShen Kids[etisalat-uae]", value: "TyShen Kids[etisalat-uae]" },
        { label: "Foodiee[ord-oman]", value: "Foodiee[ord-oman]" },
        { label: "mobilearn Daily[mobily-ksa]", value: "mobilearn Daily[mobily-ksa]" },
        { label: "mobilearn Daily[mobily-ksa]", value: "mobilearn Daily[mobily-ksa]" },
        { label: "WHYRD games[stc-ksa]", value: "WHYRD games[stc-ksa]" },
        { label: "mobillearn[zain-ksa]", value: "mobillearn[zain-ksa]" },
        { label: "Foodiee Daily[voda-egy]", value: "Foodiee Daily[voda-egy]" },
      ],
      value: '',
      regex: "",
      required: true,
      placeholder: "Please select products",
      error: "Please select products",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
      // submitButtonName: "SEARCH",
    },
    {
      type: "button",
      class: "col-md-8 d-flex justify-content-end my-2 mt-3 pt-1",
      buttons: [
          {
              title: 'Search',
              action: 'add',
              isCheckFormValidation: true,
              class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-3'
          },
          {
              title: 'Cancel',
              action: 'cancel',
              class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-3'
          }
      ]
  },
  ]);

  const [optionsMixedChart] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    stroke: {
      width: [4, 0, 0],
    },
    xaxis: {
      categories: [
        "2024-02-01",
        "2024-02-02",
        "2024-02-03",
        "2024-02-04",
        "2024-02-05",
        "2024-02-06",
        "2024-02-07",
        "2024-02-08",
      ],
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8,
      },
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 100,
    },
    plugins: {
      legend: {
        position: "top", // Placing legend at the top
      },
    },
  });

  const [seriesMixedChart, setSeriesMixedChart] = useState([
    {
      name: "New Subscription",
      type: "line",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
    {
      name: "Unsubscription",
      type: "column",
      data: [23, 12, 54, 61, 32, 56, 81, 19],
    },
    {
      name: "Renewals",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43],
    },
    {
      name: "Total Revenue",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43],
    },
  ]);

  const onFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
    } else if (data?.type === "cancel") {
    }
  };

  const SiteData = [
    {
      id: "1",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "2",
      date: "2024-20-03",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "3",
      date: "2024-20-05",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    ];
    const tableData={
        data:SiteData
      }
  const onActions = (data: any) => {
   
  };
  const onPageChange = (data: any) => {};
  const getReports = () => {
    setLoading(true);
  };
  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getReports();
  };
  const userData:any = [
    {
      id: "1",
      site: "site 1",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "2",
      site: "site 2",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "3",
      site: "site 3",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "4",
      site: "site 4",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "5",
      site: "site 5",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "6",
      site: "site 6",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "7",
      site: "site 7",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "8",
      site: "site 8",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    ];
    const ContentData:any = [
      {
        id: "1",
        content: "content 1",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "2",
        content: "content 2",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "3",
        content: "content 3",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "4",
        content: "content 4",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "5",
        content: "content 5",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "6",
        content: "content 6",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "7",
        content: "content 7",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "8",
        content: "content 8",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      ];
  useEffect(() => {
    const siteId = (params?.site_id);
    const contentId = (params?.content_id);
    const filterData = userData?.filter((el: any) => el?.id === siteId);
    const contentFilterData = ContentData?.filter((el:any)=>el?.id ===contentId)
    const data = breadcrumbInfo;
    data[1].value = filterData[0].site;
    data[2].value = contentFilterData[0].content;
    setBreadcrumbInfo([...data]);
}, [])

  return (
    <div>
        <div className="p-3">
        <BreadCrumb breadCrumbList={breadcrumbInfo}></BreadCrumb>
        </div>
      <div className="sub-header-primary-color py-2">
      <div className='col-md-12 px-4'>
                </div>
        <div className="h-25  ms-2 text-white border-start border-3 border-success">
          <div className="text-start ps-3">Check the Content performance</div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card rounded-0 bg-white">
                <div className="card-header text-start py-3">
                  <i className="bi bi-postcard"></i> Content Performance
                </div>
                <div className="card-body">
                  <div className="row">
                    <DynamicForm
                      userFormData={revenueReportsForm}
                      columns={3}
                      cancelRoute={""}
                      isEdit={false}
                      custom_button_text={"Login"}
                      onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                      customClass={"display-block"}
                      loading={""}
                      isNotEmptyFormAfterSubmit={true}
                    />
                  </div>
                </div>
                {/* <div className="mb-2 mb-3">
                  <button className="btn btn-secondary  float-end mx-2">
                    Reset
                  </button>
                  <button className="btn btn-primary float-end mx-2">
                    <i className="bi bi-search"></i> Search{" "}
                  </button>
                </div> */}
                <div className="px-2">
                  <div className="row">
                    <div className="col-12 mixed-chart">
                      <div className="card-header text-start py-3 ">
                        <i className="bi bi-postcard"></i>{" "}
                        <span className="text-start "> Content Chart </span>
                      </div>
                      <Chart
                        series={seriesMixedChart}
                        options={optionsMixedChart}
                        type="line"
                        height={500}
                      />
                    </div>
                  </div>
                  <div className="card-header text-start py-3 ">
                    <i className="bi bi-postcard"></i>{" "}
                    <span className="text-start "> Content Table </span>
                    <button className="float-end btn btn-outline-primary py-1 ">
                      {" "}
                      Export <i className="bi bi-cloud-arrow-up"></i>
                    </button>
                  </div>
                  <DataTable
                    tableData={tableData}
                    TableCols={RevenueContentDataGridCols}
                    onActions={onActions}
                    onPageChange={onPageChange}
                    onSelectPageChange={(e) => onSelectPageinator(e)}
                    pageCount={pageCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

