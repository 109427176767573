import { DataTableCol } from "../../../../components/data-table/types";

export const PaymentPartnerReportsDataGridCols: DataTableCol[] = [
    {
        title: "Payment Partner",
        control: "payment_partner_code",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Total Subscriptions",
        control: "total_subscriptions",
        sortable: true,
        canShowColumn: true,
    },
];
