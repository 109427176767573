import React from 'react'
import { Route, Routes } from 'react-router-dom';
import { Login } from '../pages/login/login'
import { Dashboard } from '../pages/dashboard';
import { ViewIndividualCustomer } from '../pages/individual-customer/view';
import { IndexIndividualCustomer } from '../pages/individual-customer';
import { WebSiteView } from '../pages/individual-customer/website';
import { RegisterPage } from '../pages/login/register';

export const RootNavigation = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/register" element={<RegisterPage />}></Route>
        <Route path="/dashboard/*" element={<Dashboard />}></Route>
        <Route path="/individual-customer/*" element={<ViewIndividualCustomer />}></Route>
        {/* <Route path="/ampere-customer/*" element={<IndexIndividualCustomer />}></Route> */}
        <Route path="/ampere-customer/*" element={<WebSiteView />}></Route>
      </Routes>
    </>
  )
}
