import React, { useEffect, useState } from 'react'
import PumpkinDash from './../../../../assets/images/pumpkin-dash.png';
import MagicDash from './../../../../assets/images/magic-dash.png';
import RangerVsZombies from './../../.././../assets/images/ranger-vs-zombies.png';
import { CustomCard } from '../../../../components/custom-card';
import { DynamicForm } from '../../../../components/form-builder/form';
import { Search } from '../../../../components/search/search';
import { CREATE_CONTENT, GET_CONTENT } from '../../../../app/services/content-service';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../components/loader';
import { GET_CATEGORIES } from '../../../../app/services/categories.service';
import toast from 'react-hot-toast';

export const GameContent = () => {
  const [showDetailsPopUp, setShowDetailsPopUp] = useState<boolean>(false);
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableListData, setTableListData] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<any>([]);
  const params = useParams();
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
    customer_uuid: params?.customer_uuid,
    site_uuid: params?.site_uuid
  });

  const gamesContentData = [
    {
      id: "1",
      game: "Pumpkin Dash",
      image: PumpkinDash,
      game_type: "Action Games",
      game_description: "Action games are mostly ypu are very much liked when you are having playing experience and you can feel more relaxation",
    },
    {
      id: "2",
      game: "Magic Dash",
      image: MagicDash,
      game_type: "Action Games",
      game_description: "Action games are mostly ypu are very much liked when you are having playing experience and you can feel more relaxation",
    },
    {
      id: "3",
      game: "Ranger Vs Zombies",
      image: RangerVsZombies,
      game_type: "Action Games",
      game_description: "Action games are mostly ypu are very much liked when you are having playing experience and you can feel more relaxation",
    },
    {
      id: "4",
      game: "Magic Dash",
      image: MagicDash,
      game_type: "Action Games",
      game_description: "Action games are mostly ypu are very much liked when you are having playing experience and you can feel more relaxation",
    },
    {
      id: "5",
      game: "Ranger Vs Zombies",
      image: RangerVsZombies,
      game_type: "Action Games",
      game_description: "Action games are mostly ypu are very much liked when you are having playing experience and you can feel more relaxation",
    },
  ]

  const [formData, setFormData] = useState<any>([
    {
      title: "input",
      type: "text",
      label: "Game Name",
      name: "name",
      formFloating: false,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please enter name",
      error: "Please enter name",
      hideMandatoryStar: false,
    },
    {
      title: "dropdown",
      type: "dropdown",
      label: "Category",
      name: "category",
      formFloating: false,
      option: [
        { label: "ACTION GAMES", value: "ACTION GAMES" },
        { label: "ADVENTURE GAMES", value: "ADVENTURE GAMES" },
        { label: "ANIMAL GAMES", value: "ANIMAL GAMES" },
        { label: "ART & CREATIVITY GAMES", value: "ART & CREATIVITY GAMES" },
        { label: "BOARD & CARD GAMES", value: "BOARD & CARD GAMES" },
        { label: "GIRL GAMES", value: "GIRL GAMES" },
        { label: "MULTIPLAYER GAMES", value: "MULTIPLAYER GAMES" },
        { label: "PUZZLE GAMES", value: "PUZZLE GAMES" },
        { label: "RACING GAMES", value: "RACING GAMES" },
        { label: "SHOOTING GAMES", value: "SHOOTING GAMES" },
        { label: "SIMULATION GAMES", value: "SIMULATION GAMES" },
        { label: "SKILL GAMES", value: "SKILL GAMES" },
        { label: "SPECIAL GAMES", value: "SPECIAL GAMES" },
        { label: "VEHICLE GAMES", value: "VEHICLE GAMES" },
        { label: "SPORTS GAMES", value: "SPORTS GAMES" },
        { label: "STRATEGY GAMES", value: "STRATEGY GAMES" },
      ],
      value: '',
      regex: "",
      required: false,
      placeholder: "Please select category",
      error: "Please select category",
      hideMandatoryStar: true,
      isParentDropDown: true,
    },
    {
      title: "textarea",
      type: "text",
      label: "Description",
      name: "notes",
      formFloating: false,
      value: '',
      regex: "",
      required: false,
      placeholder: "Please enter description",
      error: "Please enter description",
      hideMandatoryStar: true,
    },
    {
      title: "file",
      type: "file",
      label: "Game Upload",
      name: "media_url",
      value: "",
      required: false,
      regex: "",
      maxLength: "",
      isMultipleImages: false,
      placeholder: "Upload game",
      error: "Please upload game",
      hideMandatoryStar: true
    },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-end my-2",
      buttons: [
        {
          title: 'Upload',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16'
        }
      ]
    },
  ])

  const [searchFormData, setSearchFormData] = useState([
    {
      title: "input",

      type: "text",
      label: "Search",
      name: "search",
      formFloating: false,
      value: '',
      regex: "",
      placeholder: "Please enter search",
      error: "Please enter search",
      hideMandatoryStar: true,
    },
    {
      type: "button",
      class: "col-md-3 d-flex justify-content-start my-2 mt-3 pt-1",
      buttons: [
        {
          title: 'Search',
          action: 'add',
          isCheckFormValidation: false,
          class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-3'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-3'
        }
      ]
    },
  ])

  useEffect(() => {
    getGameContentData(pageCount, categoryData?.uuid);
    getCategoriesData();
  }, [])

  const getCategoriesData = () => {
    GET_CATEGORIES({ limit: 1000, page: 0 }).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          const response = res?.data?.rows;
          const filterData = response?.filter((el: any) => el?.name === "GAME");
          getGameContentData(pageCount, filterData[0]?.uuid)
          setCategoryData(filterData[0])
          setLoading(false);
        }
      }
    });
  }

  const getGameContentData = (data: any, categoryData: any) => {
    const pageData = data
    pageData.category_uuid = categoryData
    setLoading(true);
    GET_CONTENT(pageData).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          setTableListData(res?.data?.rows);
          setLoading(false);
        }
      }
    });
  }

  const onCardAction = (data: any) => {
    setSelectedData(data?.data)
    if (data?.type === "Game View") {
      setShowDetailsPopUp(true)
    } else if (data?.type === "Game Delete") {
      setShowDeletePopup(true);
    }
  }

  const onDeleteUserConfirmation = () => { }

  const onShowAddGame = () => {
    setShowCreatePopup(true);
    const gameFormData = formData;
    gameFormData?.forEach((element: any) => {
      if (element?.type === "button") {
        for (let i = 0; i < element?.buttons?.length; i++) {
          const ele = element?.buttons;
          ele[0].title = "Create";
          ele[0].action = "add";
        }
      }
      element.value = ""
    })
    setFormData([...gameFormData])
  }

  const onGameFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
      const formData = data?.formData
      formData.customer_uuid = params?.customer_uuid
      formData.site_uuid = params?.site_uuid
      formData.category_uuid = categoryData?.uuid
      formData.metadata = {}
      formData.blog_data = {}
      delete formData['undefined']
      setLoading(true);
      CREATE_CONTENT(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error, { position: "top-right" });
          setLoading(false);
        } else {
          if (res) {
            setShowCreatePopup(false);
            toast.success(res.message, { position: 'top-right', })
            setLoading(false);
            getGameContentData(pageCount, categoryData?.uuid);
          }
        }
      });
    } else if (data?.type === "cancel") {
      setShowCreatePopup(false)
    }
  }

  const onSearch = (value: any) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getGameContentData(pageData, categoryData?.uuid);
  }

  return (
    <div className='row px-3'>
      <div className='col-md-12'>
        <div className='row px-0'>
          <div className='col-md-8'>
            <div className='w-100 mt-4 pt-1'>
              <Search onSearch={(e) => onSearch(e)} />
            </div>
          </div>
          <div className='col-md-4 d-flex justify-content-end pe-5 align-items-center'>
            <button className='btn btn-primary border-0' onClick={onShowAddGame}>Upload</button>
          </div>
        </div>
      </div>
      <div className='row'>
        <Loader LoadingReducer={loading} />
        {tableListData?.length > 0 && (
          <CustomCard contentData={tableListData} customClass={true} gamesContents={true} cardAction={(e) => onCardAction(e)} />
        )}
        {tableListData?.length === 0 && (
          <div className='text-center'>No records found</div>
        )}
      </div>
      {showCreatePopup && (
        <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
          <Loader LoadingReducer={loading} />
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-primary mx-4 px-0">
                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Upload"} Game</strong></h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
              </div>
              <div className="modal-body px-4 text-start">
                <div className='row'>
                  <DynamicForm
                    userFormData={formData}
                    columns={2}
                    isEdit={isEdit}
                    onFormSubmitEvent={(e) => onGameFormSubmitEvent(e)}
                    customClass={"display-block"}
                    isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDetailsPopUp && (
        <div className={`modal modal-custom fade ${showDetailsPopUp ? "show" : "false"}  ${showDetailsPopUp ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-primary mx-4 px-0">
                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Game Details</strong></h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDetailsPopUp(false)}></button>
              </div>
              <div className="modal-body px-4 text-start">
                <div className='row'>
                  <div className='col-md-5'>
                    <img src={selectedData?.media_url} className='w-75' />
                  </div>
                  <div className='col-md-7'>
                    <div className='fw-bold fs-1'>{selectedData?.name}</div>
                    <div className='fs-3 fw-semibold'>{selectedData?.game_type || "ACTION GAMES"}</div>
                    <div className='fs-4'>{selectedData?.notes}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDeletePopup && (
        <>
          <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content text-start">
                <div className="modal-header border-bottom-primary mx-4 px-0">
                  <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                </div>
                <div className="modal-body pb-5">
                  <div className="row px-2">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.firstname || selectedData?.name || ""}</span> ?</p>
                      </div>
                    </div>
                  </div>
                  <div className='px-2 text-end'>
                    <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                    <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
