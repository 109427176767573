import React, { useEffect, useState } from "react";
import { AshramsDataGridCols } from "./roi-dashboard-table-columns";
import Chart from "react-apexcharts";
import { DataTable } from "../../../../components/data-table";
import { DynamicForm } from "../../../../components/form-builder/form";
import { useNavigate } from "react-router-dom";

export const RoiDashboard = () => {
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  // const [ashramsData, setAshramsData] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(false);
  const userRole: any = sessionStorage?.getItem("user_role");
  const [originalData, setOriginalData] = useState<any>(null);
  const [showUserCreationPopup, setShowUserCreationPopup] =
    useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const Navigate = useNavigate();

  const [revenueReportsForm, setRevenueReportsForm] = useState<any>([
    {
      title: "dropdown",

      type: "text",
      label: "Customers",
      name: "Customers",
      option: [
        {
          label: "Prasanth",
          value: "Prasanth",
        },
        { label: "Vignesh", value: "Vignesh" },
        {
          label: "Hari Babu",
          value: "Hari Babu",
        },
        {
          label: "Subba Reddy",
          value: "Subba Reddy",
        },
      ],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Customers",
      error: "Please select Customers",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
    },
    {
      title: "input",

      type: "date",
      label: "Subscription Dates",
      name: "subscription_dates",
      formFloating: false,
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Subscription Dates",
      error: "Please select Subscription Dates",
      hideMandatoryStar: true,
    },
    {
      title: "input",

      type: "date",
      label: "Charging Dates",
      name: "charging_dates",
      formFloating: false,
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Charging Dates",
      error: "Please select Charging Dates",
      hideMandatoryStar: true,
    },
    {
      title: "dropdown",

      type: "text",
      label: "Partner",
      name: "partner",
      option: [
        { label: "Fortuner", value: "Fortuner" },
        { label: "BMW", value: "BMW" },
        { label: "Volvo", value: "Volvo" },
        { label: "Thar", value: "Thar" },
        { label: "Audi", value: "Audi" },
        { label: "Kia", value: "Kia" },
        { label: "Tata", value: "Tata" },
      ],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Partner",
      error: "Please select Partner",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
      // submitButtonName: "SEARCH",
    },
    {
      title: "input",

      type: "number",
      label: "Campaign",
      name: "campaign",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "",
      hideMandatoryStar: true,
    },
    {
      title: "dropdown",

      type: "text",
      label: "Operator",
      name: "operator",
      option: [
        { label: "Fortuner", value: "Fortuner" },
        { label: "BMW", value: "BMW" },
        { label: "Volvo", value: "Volvo" },
        { label: "Thar", value: "Thar" },
        { label: "Audi", value: "Audi" },
        { label: "Kia", value: "Kia" },
        { label: "Tata", value: "Tata" },
      ],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Operator",
      error: "Please select Operator",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
      // submitButtonName: "SEARCH",
    },
    {
      title: "input",

      type: "number",
      label: "CPA (in $)",
      name: "cpa",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "Enter CPA",
      hideMandatoryStar: true,
    },
    {
      title: "input",

      type: "number",
      label: "Conversion Rate For USD",
      name: "conversion_rate_for_USD",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "Enter rate for USD",
      hideMandatoryStar: true,
    },
    {
      title: "input",

      type: "number",
      label: "Partner Share (in %)",
      name: "partner_share",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "Enter Partner Share %",
      hideMandatoryStar: true,
    },
    {
      type: "button",
      class: "col-md-4 d-flex justify-content-start mt-3 pt-3",
      buttons: [
        {
          title: 'Search',
          action: 'add',
          class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-1'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-1'
        }
      ]
    },
  ]);

  const onFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
    } else if (data?.type === "cancel") {
    }
  };

  const getAshrams = () => {
    setLoading(true);
    // GET_ASHRAMAM(pageCount).then((res: any) => {
    //     if (res.status === "fail") {
    //         toast.error(res.message, { position: "top-right" });
    //         setLoading(false);
    //     } else {
    //         if (res) {
    //             setAshramsData(res.data);
    //             setLoading(false);
    //         }
    //     }
    // });
  };

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getAshrams();
  };

  const onPageChange = (data: any) => {
    // const pageData = pageCount;
    // pageData.limit = data;
    // setPageCount(pageData);
    // getAshrams();
  };

  const onActions = (data: any) => {
    Navigate(`/dashboard/reports/roi/${data.data.id}/roi-site`);
  };

  const ashramsData = {
    data: [
      {
        id: "1",
        site: "site 1",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "2",
        site: "site 2",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "3",
        site: "site 3",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "4",
        site: "site 4",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "5",
        site: "site 5",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "6",
        site: "site 6",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "7",
        site: "site 7",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "8",
        site: "site 8",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
    ],
  };

  const [optionsMixedChart] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    stroke: {
      width: [4, 0, 0],
    },
    xaxis: {
      categories: [
        "site 1",
        "site 2",
        "site 3",
        "site 4",
        "site 5",
        "site 6",
        "site 7",
        "site 8",
      ],
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8,
      },
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 100,
    },
  });
  const [seriesMixedChart, setSeriesMixedChart] = useState([
    {
      name: "New Subscription",
      type: "line",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
    {
      name: "Unsubscription",
      type: "column",
      data: [23, 12, 54, 61, 32, 56, 81, 19],
    },
    {
      name: "Renewals",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43],
    },
    {
      name: "Total Revenue",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43],
    },
  ]);

  useEffect(() => {
    const data = revenueReportsForm;
    if (userRole !== "SUPER_ADMIN") {
      const updatedForm = [...revenueReportsForm];
      updatedForm.splice(0, 1); // Remove the first element
      setRevenueReportsForm(updatedForm);
    }
  }, [userRole])
  return (
    <div>
      <div className="sub-header-primary-color py-2">
        <div className="h-25  ms-2 text-white border-start border-3 border-danger">
          {/* <h4 className='text-start ps-3'>ROI Dashboard (v2)</h4> */}
          <div className="text-start ps-3">ROI Analysis (v2)</div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card rounded-0 bg-white">
                <div className="card-header text-start py-3">
                  <i className="bi bi-postcard"></i> ROI Reports
                </div>
                <div className="card-body">
                  <div className="row">
                    <DynamicForm
                      userFormData={revenueReportsForm}
                      columns={3}
                      cancelRoute={""}
                      isEdit={false}
                      custom_button_text={"Login"}
                      onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                      customClass={"display-block"}
                      loading={""}
                      isNotEmptyFormAfterSubmit={true}
                    />
                  </div>
                </div>
                {/* <hr className="border-bottom border-1  mx-5 w-80" /> */}
                {/* <div className="mb-2  mb-3">
                  <button className="btn btn-secondary  float-end mx-2">
                    Reset
                  </button>
                  <button className="btn btn-primary float-end mx-2">
                    <i className="bi bi-search"></i> Search{" "}
                  </button>
                </div> */}
                {/* <hr className="border-bottom border-1  mx-5 w-80 " /> */}

                <div className="px-2">
                  <div className="row">
                    <div className="col-12 mixed-chart">
                      <div className="card-header text-start py-3 ">
                        <i className="bi bi-postcard"></i>{" "}
                        <span className="text-start "> ROI Chart </span>
                      </div>
                      <Chart
                        options={optionsMixedChart}
                        series={seriesMixedChart}
                        type="line"
                        height={500}
                      />
                    </div>
                  </div>
                  <div className="card-header py-3 text-start ">
                    <i className="bi bi-postcard"></i>{" "}
                    <span className="text-start ">ROI Table</span>
                    <button className="float-end btn btn-outline-primary py-1 ">
                      {" "}
                      Export <i className="bi bi-cloud-arrow-up"></i>
                    </button>
                  </div>
                  <DataTable
                    tableData={ashramsData}
                    TableCols={AshramsDataGridCols}
                    onActions={onActions}
                    onPageChange={onPageChange}
                    onSelectPageChange={(e) => onSelectPageinator(e)}
                    pageCount={pageCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
