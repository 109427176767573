import React, { useEffect, useState } from 'react'
import { DataTable } from '../../../components/data-table';
import { CustomersDataGridCols } from '../customers/customer-data-grid-cols';
import { DynamicForm } from '../../../components/form-builder/form';
import { ContentsDataGridCols } from './content-data-grid-cols';
import { ContentTabInfo } from './content-tabs/content-tab-info';

export const Content = () => {
  const [tableListData, setTabListData] = useState<any>([]);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>([]);

  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });

  const [formData, setFormData] = useState<any>([
    {
      title: "input",
      
      type: "text",
      label: "Name",
      name: "content_name",
      formFloating: false,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please enter name",
      error: "Please enter name",
      hideMandatoryStar: true,
    },
    {
      title: "dropdown",
      
      type: "dropdown",
      label: "Content Type",
      name: "content_type",
      formFloating: false,
      option: [
        { label: "Game", value: "game" },
        { label: "Video", value: "video" },
        { label: "Blog", value: "blog" },
      ],
      value: '',
      regex: "",
      required: true,
      placeholder: "Please select content type",
      error: "Please select content type",
      hideMandatoryStar: true,
      isParentDropDown: true,
    },
    {
      title: "file",
      
      type: "file",
      label: "Profile Image",
      name: "proile_image",
      value: "",
      required: false,
      regex: "",
      maxLength: "",
      isMultipleImages: false,
      placeholder: "Upload image",
      error: "Please upload image",
      hideMandatoryStar: true
    },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-end my-2",
      buttons: [
        {
          title: 'Create',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16'
        }
      ]
    },
  ])


  const contentsData = [
    {
      name: "Cricket",
      content_type: "game",
      email: "prasanth@mail.com",
      gender: "Male",
      mobile_number: "7837879839",
      game_site: "blazon.com",
      status: "1",
    },
    {
      name: "Gaming video",
      content_type: "video",
      email: "vignesh@mail.com",
      gender: "Male",
      mobile_number: "8787879839",
      game_site: "blazon.com",
      status: "1",
    },
    {
      name: "Personal Blog",
      content_type: "Blog",
      email: "haribabu@mail.com",
      gender: "Male",
      mobile_number: "9837879839",
      game_site: "blazon.com",
      status: "1",
    },
  ]

  const [searchFormData, setSearchFormData] = useState([
    {
      title: "input",
      
      type: "text",
      label: "Search",
      name: "search",
      formFloating: false,
      value: '',
      regex: "",
      placeholder: "Please enter search",
      error: "Please enter search",
      hideMandatoryStar: true,
    },
    {
      type: "button",
      class: "col-md-3 d-flex justify-content-end my-2 mt-4",
      buttons: [
        {
          title: 'Search',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-3'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-3'
        }
      ]
    },
  ])

  const tableData = {
    total_count: "10",
    data: contentsData
  }

  const onActions = (data: any) => {
    if (data?.type === "Edit") {
      setIsEdit(true);
      setShowCreatePopup(true);
    } else if (data?.type === "Delete") {
      setShowDeletePopup(true);
    }
  }

  const onPageChange = (data: any) => { }
  const onSelectPage = (data: any) => { }

  const onShowCreateContentPopup = () => {
    setShowCreatePopup(true)
  }

  const onDeleteUserConfirmation = () => { }

  const onContentFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {

    } else if (data?.type === "cancel") {
      setShowCreatePopup(false)
    }
  }

  const onSearchFormSubmit = (event: any) => {

  }

  return (
    <div className='container-fluid content-bg-color'>
      <div className='row border-bottom-primary'>
        <div className='col-md-9 text-start d-flex align-items-center px-0'>
          <h4>Contents</h4>
        </div>
        <div className='col-md-3 text-end my-2 px-0'>
          {/* <button className='btn btn-primary border-0' onClick={onShowCreateContentPopup}>Create Content</button> */}
        </div>
      </div>
      {/* <div className='row mt-2'>
        <DynamicForm
          userFormData={searchFormData}
          columns={4}
          isEdit={false}
          onFormSubmitEvent={(e) => onSearchFormSubmit(e)}
          // onParentDropdownEvent={(e) => onChangeDropdown(e)}
          customClass={"display-block"}
          isNotEmptyFormAfterSubmit={true} cancelRoute={undefined}
        />
      </div> */}
      <div className='row my-3'>
        <div className='col-md-12 px-0'>
          {/* <DataTable
            tableData={tableData}
            TableCols={ContentsDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e) => onSelectPage(e)}
            pageCount={pageCount}
          /> */}
          <ContentTabInfo />

        </div>
        {showCreatePopup && (
          <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-bottom-primary mx-4 px-0">
                  <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Content</strong></h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                </div>
                <div className="modal-body px-4 text-start">
                  <div className='row'>
                    <DynamicForm
                      userFormData={formData}
                      columns={2}
                      isEdit={isEdit}
                      onFormSubmitEvent={(e) => onContentFormSubmitEvent(e)}
                      customClass={"display-block"}
                      isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showDeletePopup && (
          <>
            <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-start">
                  <div className="modal-header border-bottom-primary mx-4 px-0">
                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                  </div>
                  <div className="modal-body pb-5">
                    <div className="row px-2">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.firstname || selectedData?.name || ""}</span> ?</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-2 text-end'>
                      <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                      <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
