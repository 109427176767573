import React, { useEffect, useState } from 'react'
import { DynamicForm } from '../../../../components/form-builder/form';
import toast from 'react-hot-toast';
import { CustomCard } from '../../../../components/custom-card';
import SocialMedia from './../../../../assets/images/social_media_blog.webp';
import PersonalBlog from './../../../../assets/images/james-clear-personal-blog.webp';
import NoImage from './../../../../assets/images/no-image.jpg';
import { Search } from '../../../../components/search/search';
import { Loader } from '../../../../components/loader';
import { useParams } from 'react-router-dom';
import { GET_CATEGORIES } from '../../../../app/services/categories.service';
import { GET_CONTENT } from '../../../../app/services/content-service';


export const BlogContent = () => {
  const [showDetailsPopUp, setShowDetailsPopUp] = useState<boolean>(false);
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryData, setCategoryData] = useState<any>([]);
  const [tableListData, setTableListData] = useState<any>([]);
  const params = useParams();
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
    user_uuid: params?.customer_uuid,
    site_uuid: params?.site_uuid
  });

  const [formData, setFormData] = useState<any>([
    {
      title: "input",
      type: "text",
      label: "Blog Name",
      name: "name",
      formFloating: false,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please enter blog name",
      error: "Please enter blog name",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "text",
      label: "Blog url",
      name: "blog_url",
      formFloating: false,
      value: '',
      regex: "",
      required: false,
      placeholder: "Please enter video url",
      error: "Please enter video url",
      hideMandatoryStar: true,
    },
    {
      title: "textarea",
      type: "text",
      label: "Description",
      name: "notes",
      formFloating: false,
      value: '',
      regex: "",
      required: false,
      placeholder: "Please enter description",
      error: "Please enter description",
      hideMandatoryStar: true,
    },
    {
      title: "file",

      type: "file",
      label: "Blog Upload",
      name: "media_url",
      value: "",
      required: false,
      regex: "",
      maxLength: "",
      isMultipleImages: false,
      placeholder: "Upload blog",
      error: "Please upload blog",
      hideMandatoryStar: true
    },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-end my-2",
      buttons: [
        {
          title: 'Upload',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16'
        }
      ]
    },
  ])

  const blogsContentData = [
    {
      id: "1",
      blog_title: "James Clear",
      blog_image: PersonalBlog,
      blog_type: "Personal",
      blog_description: "JamesClear.com is one of the most popular personal blogs attracting millions of monthly visitors. If you want to build better habits, improve your decision-making, and continue improvement in work and life – this blog is a must-read for you.",
      blog_url: "",
      status: "1"
    },
    {
      id: "2",
      blog_title: "Inc. Magazine",
      blog_image: NoImage,
      blog_type: "Business ",
      blog_description: "Successfully start, grow, innovate, and lead your business today: Ideas, resources, advice, support, tools, strategies, real stories, and real business examples can be found on our website. Inc.com, the daily resource for entrepreneurs. Everything you need to know to start and grow your business now.",
      blog_url: "",
      status: "1"
    },
    {
      id: "3",
      blog_title: "Google Announces New AI Ad Tools at Marketing Live 2024",
      blog_image: SocialMedia,
      blog_type: "Social Media",
      blog_description: "Google has announced a range of new ad tools at its “Marketing Live” event, including some impressive creative editing tools (powered by AI), new Shorts ad formats, updated Google Business presentation, and more.",
      blog_url: "https://www.socialmediatoday.com/news/google-announces-ai-ad-tools-marketing-live-2024/716803/",
      status: "1"
    },
  ]

  const [searchFormData, setSearchFormData] = useState([
    {
      title: "input",

      type: "text",
      label: "Search",
      name: "search",
      formFloating: false,
      value: '',
      regex: "",
      placeholder: "Please enter search",
      error: "Please enter search",
      hideMandatoryStar: true,
    },
    {
      type: "button",
      class: "col-md-3 d-flex justify-content-start my-2 mt-3 pt-1",
      buttons: [
        {
          title: 'Search',
          action: 'add',
          isCheckFormValidation: false,
          class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-3'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-3'
        }
      ]
    },
  ])

  useEffect(() => {
    getBlogContentData(pageCount, categoryData?.uuid);
    getCategoriesData();
  }, [])

  const getCategoriesData = () => {
    GET_CATEGORIES({ limit: 1000, page: 0 }).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          const response = res?.data?.rows;
          const filterData = response?.filter((el: any) => el?.name === "BLOG");
          getBlogContentData(pageCount, filterData[0]?.uuid)
          setCategoryData(filterData[0])
          setLoading(false);
        }
      }
    });
  }

  const getBlogContentData = (data: any, category: any) => {
    const pageData = data
    pageData.category_uuid = category
    setLoading(true);
    GET_CONTENT(pageData).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          setTableListData(res?.data?.rows);
          setLoading(false);
        }
      }
    });
  }

  const onShowAddBlog = () => {
    setShowCreatePopup(true);
    const gameFormData = formData;
    gameFormData?.forEach((element: any) => {
      if (element?.type === "button") {
        for (let i = 0; i < element?.buttons?.length; i++) {
          const ele = element?.buttons;
          ele[0].title = "Create";
          ele[0].action = "add";
        }
      }
      element.value = ""
    })
    setFormData([...gameFormData])
  }

  const onBlogFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
      toast.success("Blog Created Successfully", { position: "top-right" })
      setShowCreatePopup(false)
    } else if (data?.type === "cancel") {
      setShowCreatePopup(false)
    }
  }

  const onDeleteUserConfirmation = () => { }

  const onCardAction = (data: any) => {
    setSelectedData(data?.data)
    if (data?.type === "Blog View") {
      setShowDetailsPopUp(true)
    } else if (data?.type === "Blog Delete") {
      setShowDeletePopup(true);
    }
  }

  const onViewMore = (data: any) => {
    if (data?.blog_url) {
      window.open(data.blog_url, "_blank");
    }
  }

  const onSearch = (value: any) => {
    // const pageData = pageCount;
    // pageData.search = value;
    // setPageCount(pageData);
  }

  return (
    <div className='row'>
      <div className='col-md-12 mb-3'>
        <div className='row'>
          <div className='col-md-8'>
            {/* <DynamicForm
              userFormData={searchFormData}
              columns={4}
              isEdit={false}
              onFormSubmitEvent={(e) => onSearchFormSubmit(e)}
              customClass={"display-block"}
              isNotEmptyFormAfterSubmit={true} cancelRoute={undefined}
            /> */}
            <div className='w-100 mt-4 pt-1 px-2'>
              <Search onSearch={(e) => onSearch(e)} />
            </div>
          </div>
          <div className='col-md-4 mt-4 pt-2 text-end'>
            <button className='btn btn-primary border-0' onClick={onShowAddBlog}>Upload</button>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='row'>
          <Loader LoadingReducer={loading} />

          <CustomCard contentData={blogsContentData} customClass={true} blogsContent={true} cardAction={(e) => onCardAction(e)} />
        </div>
      </div>
      {showCreatePopup && (
        <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-primary mx-4 px-0">
                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Upload"} Blog</strong></h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
              </div>
              <div className="modal-body px-4 text-start">
                <div className='row'>
                  <DynamicForm
                    userFormData={formData}
                    columns={2}
                    isEdit={isEdit}
                    onFormSubmitEvent={(e) => onBlogFormSubmitEvent(e)}
                    customClass={"display-block"}
                    isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDetailsPopUp && (
        <div className={`modal modal-custom fade ${showDetailsPopUp ? "show" : "false"}  ${showDetailsPopUp ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header border-bottom-primary mx-4 px-0">
                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Blog Details</strong></h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDetailsPopUp(false)}></button>
              </div>
              <div className="modal-body px-4 text-start">
                <div className='row'>
                  <div className='col-md-5 d-flex align-items-center'>
                    <img src={selectedData?.blog_image} className='w-75' />
                  </div>
                  <div className='col-md-7'>
                    <div className='fw-bold fs-1'>{selectedData?.blog_title}</div>
                    <div className='fs-3 fw-semibold'>{selectedData?.blog_type}</div>
                    <div className=''>{selectedData?.blog_description}</div>
                    {selectedData?.blog_url !== "" && <div className='fs-6 text-primary cursor-pointer' onClick={(e) => onViewMore(selectedData)}>View More</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showDeletePopup && (
        <>
          <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content text-start">
                <div className="modal-header border-bottom-primary mx-4 px-0">
                  <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                </div>
                <div className="modal-body pb-5">
                  <div className="row px-2">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.blog_title || ""}</span> ?</p>
                      </div>
                    </div>
                  </div>
                  <div className='px-2 text-end'>
                    <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                    <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
