import React from 'react';
import './App.css';
import './assets/css/custom.css';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { RootNavigation } from './routes';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <HashRouter>
        <Toaster />
        <RootNavigation />
      </HashRouter>

      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
