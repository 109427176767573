import API from "../utils/axios";
let common = '/v1/api';

export const GET_CATEGORIES = (data: any): Promise<any> => {
    return API.get(`${common}/categories`, { params: data });
};

export const CREATE_CATEGORY = (data: any): Promise<any> => {
    return API.post(`${common}/categories`, data);
};
export const UPDATE_CATEGORY = (uuid: any, data: any): Promise<any> => {
    return API.put(`${common}/categories/${uuid}`, data);
};

export const DELETE_CATEGORY = (data: any): Promise<any> => {
    return API.delete(`${common}/categories/${data}`);
};