import React, { useEffect, useState } from "react";
import { CustomCard } from "../../../components/custom-card";
import { GET_USERS, USER_UPDATE } from "../../../app/services/users.service";
import { Loader } from "../../../components/loader";
import Chart from "react-apexcharts";
import { DynamicForm } from "../../../components/form-builder/form";
import moment from "moment";
import { GET_DASHBOARD_CHARTS, GET_DASHBOARD_STATISTICS } from "../../../app/services/dashboard.service";

export const DashboardHome = () => {
  const [isEditing, setIsEditing] = useState(false);
  const loginUserRole = sessionStorage.getItem('user_role');
  const customerUuid = sessionStorage?.getItem("user_uuid");
  const [loading, setLoading] = useState<boolean>(false);
  const [customerDetails, setCustomerDetails] = useState<any>();
  const [subscriptionAmounts, setSubscriptionAmounts] = useState<number[]>([]);
  const [totalSubscriptionCounts, setTotalSubscriptionCounts] = useState<number[]>([]);
  const [daysChartData, setDaysChartData] = useState<number[]>([]);
  const [dashboardStatisticsData, setDashboardStatisticsData] = useState<any>();
  const [dashboardChartsData, setDashboardChartsData] = useState<any>();
  const [optionsMixedChart, setOptionsMixedChart] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "50%"
      }
    },
    stroke: {
      width: [4, 0, 0]
    },
    xaxis: {
      categories: daysChartData || []
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8
      }
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 500
    }
  });
  const [seriesMixedChart, setSeriesMixedChart] = useState([
    {
      name: "Total Amount",
      type: "line",
      data: subscriptionAmounts || []
    },
    {
      name: "Total Subscriptions",
      type: "column",
      data: totalSubscriptionCounts || []
    },
  ]);

  const currentDate: any = new Date();
  const defaultEndDate = currentDate.setDate(currentDate.getDate() - 30);
  const defaultFormattedDate = moment(defaultEndDate).format("YYYY-MM-DD")
  const [selectedDateRange, setSelectedDateRange] = useState<any>({
    from_date: defaultFormattedDate,
    to_date: moment().format("YYYY-MM-DD")
  });
  const [selectedChartsDateRange, setSelectedChartsDateRange] = useState<any>({
    from_date: defaultFormattedDate,
    to_date: moment().format("YYYY-MM-DD")
  });

  useEffect(() => {
    getCustomerDataById(customerUuid);
    const userRole = loginUserRole
    getDashboardStatistics(selectedDateRange, userRole === "CUSTOMER" ? customerUuid : "");
    getDashboardCharts(selectedChartsDateRange,userRole === "CUSTOMER" ? customerUuid : "");
  }, [customerUuid])

  const getCustomerDataById = (userUuid: any) => {
    setLoading(true);
    GET_USERS({ uuid: userUuid }).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          setCustomerDetails(res?.data?.rows[0])
          setLoading(false);
        }
      }
    });
  }

  const getDashboardStatistics = (dateRangeData: any, user_uuid: any) => {
    setLoading(true);
    let data = dateRangeData
    if (loginUserRole === "CUSTOMER") {
      data.customer_uuid = user_uuid
    }
    GET_DASHBOARD_STATISTICS(data).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          setDashboardStatisticsData(res?.data);
          setLoading(false);
        }
      }
    });
  }

  const getDashboardCharts = (dateRangeData: any, user_uuid: any) => {
    setLoading(true);
    let data = dateRangeData
    if (loginUserRole === "CUSTOMER") {
      data.customer_uuid = user_uuid
    }
    GET_DASHBOARD_CHARTS(data).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          const response = res.data;
          const daysData: any = response.map((item: any) => item.day);
          const subscriptionAmountData: any = response.map((item: any) => item.total_amount);
          const subscriptionsCountData: any = response.map((item: any) => item.total_subscriptions);

          setTotalSubscriptionCounts([...subscriptionsCountData]);
          setSubscriptionAmounts([...subscriptionAmountData]);
          setDaysChartData([...daysData]);
          setOptionsMixedChart((prevOptions: any) => ({
            ...prevOptions,
            xaxis: {
              ...prevOptions.xaxis,
              categories: daysData
            }
          }));

          setSeriesMixedChart([
            { name: "Total Subscription Amount", type: "line", data: subscriptionAmountData },
            { name: "Total Subscriptions", type: "column", data: subscriptionsCountData },
          ]);
          setLoading(false);
        }
      }
    });
  }


  const handleEditClick = () => {
    if (isEditing) {
      updateCustomerDetails();
    }
    setIsEditing(!isEditing);
  };

  const updateCustomerDetails = () => {
    setLoading(true);
    const userUuid = sessionStorage?.getItem("user_uuid");
    USER_UPDATE(userUuid, customerDetails).then((res: any) => {
      if (res?.data?.status === "success") {
        setLoading(false);
        getCustomerDataById(customerUuid);
      } else {
        setLoading(false);
      }
    });
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setCustomerDetails({
      ...customerDetails,
      [name]: value,
    });
  };

  const cardsData = [
    {
      content_title: "Total services",
      description: "Live & Testing",
      count: dashboardStatisticsData?.total_payment_partners ? dashboardStatisticsData?.total_payment_partners : "0",//dashboardStatisticsData?.,
      class: "totalSerives  textdark",
    },
    {
      content_title: "Total Subscribers Base",
      description: "Including all services",
      count: dashboardStatisticsData?.total_subscribers ? dashboardStatisticsData?.total_subscribers : "0",
      class: "totalSubscribers text-dark",
    },
    {
      content_title: "Total Transactions",
      description: "Total transactions of the all customers",
      count: dashboardStatisticsData?.total_subscriptions ? dashboardStatisticsData?.total_subscriptions : "0",
      class: "unsubscriberYesterday Yesterday text-dark",
    },
    {
      content_title: "Total Sites",
      description: "Total number of sites",
      count: dashboardStatisticsData?.total_sites ? dashboardStatisticsData?.total_sites : "0",
      class: "unsubscriberYesterday Yesterday  text-dark",
    },
    {
      content_title: "Total Users",
      description: "",
      count: dashboardStatisticsData?.total_end_users ? dashboardStatisticsData?.total_end_users : "0",
      class: "totalSubscribers text-dark",
    },
  ];

  const subscribersCardsData = [
    {
      content_title: "Subscriber Yesterday",
      description: "Customer opted for services",
      count: dashboardStatisticsData?.yesterday_subscriptions ? dashboardStatisticsData?.yesterday_subscriptions : "0",
      class: "subscriberYesterday  text-dark",
    },
    {
      content_title: "Un Subscribers Yesterday",
      description: "Customer opted-out from services",
      count: dashboardStatisticsData?.yesterday_unsubscriptions ? dashboardStatisticsData?.yesterday_unsubscriptions : "0",
      class: "subscriberYesterday Yesterday text-dark",
    },
  ]

  const [searchFormData, setSearchFormData] = useState([
    {
      title: "input",
      type: "date",
      label: "Start Date",
      name: "from_date",
      formFloating: false,
      value: selectedDateRange?.from_date,
      regex: "",
      required: false,
      placeholder: "Please select start date",
      error: "Please select start date",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "date",
      label: "End Date",
      name: "to_date",
      formFloating: false,
      value: selectedDateRange?.to_date,
      regex: "",
      required: false,
      placeholder: "Please select end date",
      error: "Please select end date",
      hideMandatoryStar: false,
    },
    {
      type: "button",
      class: "col-md-3 d-flex justify-content-start my-2 mt-3 pt-1",
      buttons: [
        {
          title: 'Search',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1 height_35 mt-3'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16 height_35 mt-3'
        }
      ]
    },
  ])

  const [chartsSearchFormData, setChartsSearchFormData] = useState([
    {
      title: "input",
      type: "date",
      label: "Start Date",
      name: "from_date",
      formFloating: false,
      value: selectedDateRange?.from_date,
      regex: "",
      required: false,
      placeholder: "Please select start date",
      error: "Please select start date",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "date",
      label: "End Date",
      name: "to_date",
      formFloating: false,
      value: selectedDateRange?.to_date,
      regex: "",
      required: false,
      placeholder: "Please select end date",
      error: "Please select end date",
      hideMandatoryStar: false,
    },
    {
      type: "button",
      class: "col-md-3 d-flex justify-content-start my-2 mt-3 pt-1",
      buttons: [
        {
          title: 'Search',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1 height_35 mt-3'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16 height_35 mt-3'
        }
      ]
    },
  ])

  const onSearchFormSubmit = (type: any) => {
    const data = type;
    if (data?.type === "add") {
      const formData = data?.formData
      delete formData['undefined']
      setSelectedDateRange({ ...selectedDateRange, from_date: formData?.from_date, to_date: formData?.to_date })
      getDashboardStatistics(formData, customerUuid)
    } else if (data?.type === "cancel") {
      const dateData = {
        from_date: defaultFormattedDate,
        to_date: moment().format("YYYY-MM-DD")
      };
      const searchData = searchFormData;
      searchData[0].value = defaultFormattedDate;
      searchData[1].value = moment().format("YYYY-MM-DD");

      setSelectedDateRange({
        ...selectedDateRange,
        from_date: dateData.from_date,
        to_date: dateData.to_date
      });
      getDashboardStatistics(dateData, customerUuid);
    }
  }


  const onSearchChartsFormSubmit = (type: any) => {
    const data = type;
    if (data?.type === "add") {
      const formData = data?.formData
      delete formData['undefined']
      setSelectedChartsDateRange({ ...selectedChartsDateRange, from_date: formData?.from_date, to_date: formData?.to_date })
      getDashboardCharts(formData, customerUuid)
    } else if (data?.type === "cancel") {
      const dateData = {
        from_date: defaultFormattedDate,
        to_date: moment().format("YYYY-MM-DD")
      };
      const searchData = chartsSearchFormData;
      searchData[0].value = defaultFormattedDate
      searchData[1].value = moment().format("YYYY-MM-DD")

      setSelectedChartsDateRange({
        ...selectedChartsDateRange,
        from_date: dateData.from_date,
        to_date: dateData.to_date
      });
      getDashboardCharts(dateData, customerUuid);
    }
  }

  return (
    <div className="px-4 mb-3">
      <Loader LoadingReducer={loading} />
      <div className="card border-0 box-shadow">
        <div className="card-body px-4">
          <div className="col-md-12 px-0 my-3">
            <div className="text-start border-start border-4 border-danger py-1 fw-bold ps-3 bg-dark_brown text-white">
              Welcome To Ampere
            </div>
          </div>
          {loginUserRole === 'CUSTOMER' && <div className="card text-start my-3">
            <div className="card-body ">
              <div className="row">
                <div className="col-6">
                  <h4>Customer Details</h4>
                </div>
                <div className="col-6 text-end">
                  <button
                    className="btn btn-primary border-0 px-4"
                    onClick={handleEditClick}
                  >
                    {isEditing ? "Update" : "Edit"}
                  </button>
                </div>
                <div className="col-6">
                  <h6>Name</h6>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={customerDetails?.name}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-6">
                  <h6>Contact Number</h6>
                  <input
                    className="form-control"
                    type="number"
                    name="contactNumber"
                    value={customerDetails?.mobile_number}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-6 my-2">
                  <h6>Payment Gateway</h6>
                  <input
                    className="form-control"
                    type="text"
                    name="paymentGateway"
                    value={customerDetails?.paymentGateway}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-6 my-2">
                  <h6>Payment Date</h6>
                  <input
                    className="form-control"
                    type="text"
                    name="paymentDate"
                    value={customerDetails?.paymentDate}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-6 my-2">
                  <h6>Email Id</h6>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    value={customerDetails?.email}
                    disabled={!isEditing}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>}
          <div className="row my-2">
            <DynamicForm
              userFormData={searchFormData}
              columns={4}
              isEdit={false}
              onFormSubmitEvent={(e) => onSearchFormSubmit(e)}
              customClass={"display-block"}
              isNotEmptyFormAfterSubmit={true}
              cancelRoute={undefined}
            />
          </div>
          <div className="row border-bottom-primary pb-2">
            <CustomCard contentData={cardsData} customClass={true} />
          </div>
          <div className="row border-bottom-primary pt-3 pb-1">
            <CustomCard contentData={subscribersCardsData} customClass={true} />
          </div>
          <div className="row shadow my-4 p-3">
            <h4 className="col-md-12 fw-bold">Total Subscriptions and Amount</h4>
            <div className="row my-2">
              <DynamicForm
                userFormData={chartsSearchFormData}
                columns={4}
                isEdit={false}
                onFormSubmitEvent={(e) => onSearchChartsFormSubmit(e)}
                customClass={"display-block"}
                isNotEmptyFormAfterSubmit={true}
                cancelRoute={undefined}
              />
            </div>
            <div className="col-12 mixed-chart py-2">
              {/* <div className='card-header text-start pt-3'>
                <i className="bi bi-postcard"></i> <span className='text-start '>  Revenue Chart </span>
              </div> */}
              <Chart
                options={optionsMixedChart}
                series={seriesMixedChart}
                type="line"
                height={500}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};