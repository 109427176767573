import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { PaymentPartnerReportsDataGridCols } from './payment-partner-reports-data-grid-cols';
import { DataTable } from '../../../../components/data-table';
import Chart from "react-apexcharts";
import { DynamicForm } from '../../../../components/form-builder/form';
import { GET_PAYMENT_PARTNER_REPORTS } from '../../../../app/services/reports.service';
import { Loader } from '../../../../components/loader';
import { GET_PAYMENT_PARTNERS } from '../../../../app/services/payment-partners.service';
import * as XLSX from 'xlsx';

export const PaymentPartnerReports = () => {
    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);
    const navigate = useNavigate();
    const userRole = sessionStorage?.getItem("user_role");
    const [customerNames, setCustomerNames] = useState<string[]>([]);
    const [subscriptionAmounts, setSubscriptionAmounts] = useState<number[]>([]);
    const [totalSubscriptionCounts, setTotalSubscriptionCounts] = useState<number[]>([]);
    const [totalSitesCount, setTotalSitesCount] = useState<number[]>([]);

    const [reportsSearchForm, setReportsSearchForm] = useState<any>([
        {
            title: "input",
            type: "date",
            label: "From Date",
            name: "from_dt",
            formFloating: false,
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select from date",
            error: "Please select from date",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "date",
            label: "To Date",
            name: "to_dt",
            formFloating: false,
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select to date",
            error: "Please select to date",
            hideMandatoryStar: true,
        },
        {
            title: "dropdown",
            type: "text",
            label: "Payment Partners",
            name: "payment_partner_uuid",
            option: [],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select payment partner",
            error: "Please select payment partner",
            hideMandatoryStar: true,
            isParentDropDown: false,
            isApiCall: false,
        },
        {
            type: "button",
            class: "col-md-4 d-flex justify-content-start my-2 mb-2",
            buttons: [
                {
                    title: 'Search',
                    action: 'add',
                    class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 border-0'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16 height_30'
                }
            ]
        },
    ]);

    const [optionsMixedChart, setOptionsMixedChart] = useState({
        chart: {
            id: "basic-bar",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                columnWidth: "50%",
            },
        },
        stroke: {
            width: [4, 0, 0],
        },
        xaxis: {
            categories: customerNames,
        },
        markers: {
            size: 6,
            strokeWidth: 3,
            fillOpacity: 0,
            strokeOpacity: 0,
            hover: {
                size: 8,
            },
        },
        yaxis: {
            tickAmount: 5,
            min: 0,
            max: 5000,
        },
        plugins: {
            legend: {
                position: "top", // Placing legend at the top
            },
        },
    });

    const [seriesMixedChart, setSeriesMixedChart] = useState([
        {
            name: "Total Subscription Amount",
            type: "line",
            data: subscriptionAmounts,
        },
        {
            name: "Total Subscriptions",
            type: "column",
            data: totalSubscriptionCounts,
        },
    ]);


    useEffect(() => {
        getPaymentPartnerReports(pageCount);
        getPaymentPartners();
    }, [])

    const getPaymentPartnerReports = (pageData: any) => {
        setLoading(true);
        GET_PAYMENT_PARTNER_REPORTS(pageData).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data);
                    const customerData = res.data.rows;
                    const names: any = customerData.map((item: any) => item.payment_partner_code);
                    const subscriptionAmounts: any = customerData.map((item: any) => item.total_amount);
                    const sitesCount: any = customerData.map((item: any) => item.sites);
                    const totalSubscriptionCounts: any = customerData.map((item: any) => item.total_subscriptions);

                    setCustomerNames([...names]);
                    setSubscriptionAmounts([...subscriptionAmounts]);
                    setTotalSubscriptionCounts([...totalSubscriptionCounts]);
                    setTotalSitesCount([...sitesCount])

                    setOptionsMixedChart((prevOptions: any) => ({
                        ...prevOptions,
                        xaxis: {
                            ...prevOptions.xaxis,
                            categories: names
                        }
                    }));

                    setSeriesMixedChart([
                        { name: "Total Subscription Amount", type: "line", data: subscriptionAmounts },
                        { name: "Total Subscriptions", type: "column", data: totalSubscriptionCounts },
                    ]);
                    setLoading(false);
                }
            }
        });
    }

    const getPaymentPartners = () => {
        GET_PAYMENT_PARTNERS({ limit: 1000, page: 0 }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const paymentPartners = [];
                    for (let i = 0; i < response?.length; i++) {
                        const element = response[i];
                        paymentPartners.push({ label: element?.code, value: element?.uuid, })
                    }
                    const data = reportsSearchForm
                    data[2].option = paymentPartners
                    setReportsSearchForm([...data])
                    setLoading(false);
                }
            }
        });
    }

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const pageData = pageCount;
            pageData.page = 0
            const formDataObj = { ...pageData, ...data?.formData };
            delete formDataObj['undefined'];
            for (var propName in formDataObj) {
                if (!formDataObj[propName]) {
                    delete formDataObj[propName];
                }
            }
            const searchData = formDataObj
            searchData.page = 0
            setPageCount({ ...searchData });
            getPaymentPartnerReports(formDataObj)
        } else if (data?.type === "cancel") {
            const formData = reportsSearchForm;
            formData?.forEach((element: any) => {
                element.value = ""
            })
            setReportsSearchForm([...formData]);
            const pageData = {
                limit: 10,
                page: 0,
            }
            getPaymentPartnerReports(pageData);
        }
    };

    const onActions = (data: any) => {
        if (data.type === "link") {
            //   Navigate(`/dashboard/reports/service/${data.data.id}/service-site`);
        }
    };

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getPaymentPartnerReports(pageData);
    };

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getPaymentPartnerReports(pageData);
    };

    const onExportData = () => {
        setLoading(true);
        GET_PAYMENT_PARTNER_REPORTS({ limit: 1000, page: 0 }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows
                    const userData: any = [];
                    response.forEach((element: any) => {
                        userData.push(
                            {
                                "Payment Partner": element?.payment_partner_code,
                                "Total Subscriptions": element?.total_subscriptions,
                                "Total Subscription Amount": element?.total_amount,
                            }
                        );
                    })
                    const worksheet = XLSX.utils.json_to_sheet(userData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                    XLSX.writeFile(workbook, `payment_partners_reports.xlsx`);
                    setLoading(false);
                    setLoading(false);
                }
            }
        });
    }

    return (
        <div>
            <div className="sub-header-primary-color py-2">
                <div className="h-25 ms-2 text-white border-start border-3 border-success">
                    <div className="text-start ps-3">Payment Partner Reports</div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card rounded-0 bg-white">
                                {/* <div className="card-header text-start py-3">
                                    <i className="bi bi-postcard"></i>
                                </div> */}
                                <div className="card-body">
                                    <div className="row">
                                        <DynamicForm
                                            userFormData={reportsSearchForm}
                                            columns={3}
                                            cancelRoute={""}
                                            isEdit={false}
                                            custom_button_text={"Login"}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            loading={""}
                                            isNotEmptyFormAfterSubmit={true}
                                        />
                                    </div>
                                </div>
                                <div className="px-2">
                                    <div>
                                        <div className="card-header text-start py-3 ">
                                            <i className="bi bi-postcard"></i>{" "}
                                            <span className="text-start "> Payment Partner Table</span>
                                            <button className="float-end btn btn-primary py-1 border-0" onClick={onExportData}>
                                                {" "}
                                                Export <i className="bi bi-cloud-arrow-up"></i>
                                            </button>
                                        </div>
                                        <Loader LoadingReducer={loading} />
                                        <DataTable
                                            tableData={tableListData}
                                            TableCols={PaymentPartnerReportsDataGridCols}
                                            onActions={onActions}
                                            onPageChange={onPageChange}
                                            onSelectPageChange={(e) => onSelectPage(e)}
                                            pageCount={pageCount}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mixed-chart">
                                            <div className="card-header text-start py-3 ">
                                                <i className="bi bi-postcard"></i>{" "}
                                                <span className="text-start ">Payment Partner Chart </span>
                                            </div>
                                            {optionsMixedChart?.xaxis?.categories?.length > 0 && (
                                                <Chart
                                                    series={seriesMixedChart}
                                                    options={optionsMixedChart}
                                                    type="line"
                                                    height={500}
                                                />
                                            )}
                                            {optionsMixedChart?.xaxis?.categories?.length === 0 && (
                                                <div className='text-center py-5 my-5'>No Data Available</div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
