import React, { useState } from "react"
import Logo from './../../assets/images/blazon-logo.png';
import GAMEBANNER from './../../assets/images/game_banner.png';
import TELEGRAMIMG from './../../assets/images/telegram_img.png';
import GLOBEIMG from './../../assets/images/globe_ideas.png';
import CREATIVEIMG from './../../assets/images/creative_ideas.png';
import COREPLATFORM from './../../assets/images/Core-Platform-Feature.webp';
import STRATATGY from './../../assets/images/strategy.png';
import UIDESIGN from './../../assets/images/ui-design.webp';
import CONTENT from './../../assets/images/content.png';
import MARKETING from './../../assets/images/marketing.png';
import WEBIMG from './../../assets/images/web.png';
import SOCIALMEDIA from './../../assets/images/social-media.png';
import { DynamicForm } from "../../components/form-builder/form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";



export const ViewIndividualCustomer = () => {
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [showSignUpPopup, setShowSignUpPopup] = useState<boolean>(false);
    const navigate = useNavigate();
    const formData = [
        {
            title: "input",
            type: "text",
            formFloating: false,
            label: "Email",
            name: "user_email",
            value: "",
            required: true,
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{3}$/,
            maxLength: "",
            placeholder: "Please enter email",
            error: "Please enter email",
            submitButtonName: "Login",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "password",
            formFloating: false,
            label: "Password",
            name: "password",
            value: "",
            required: true,
            regex: "",
            maxLength: "",
            placeholder: "",
            error: "Please enter valid password",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 ms-auto d-flex justify-content-center my-2",
            buttons: [
                {
                    title: 'Login',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'primary-button btn d-block d-lg-inline-block mb-0 mb-lg-0 w-xs-100 rounded-1 btn border-0 btn-primary px-5 btn-sm f16 me-1 text-uppercase fw-bold py-1'
                },
            ]
        }
    ];
    const signUpFormData = [
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            formFloating: false,
            label: "Email",
            name: "user_email",
            value: "",
            required: true,
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{3}$/,
            maxLength: "",
            placeholder: "Please enter email",
            error: "Please enter email",
            hideMandatoryStar: false,
        },

        {
            title: "input",
            type: "text",
            label: "Phone Number",
            formFloating: false,
            name: "mobile_number",
            value: '',
            maxLength: 10,
            regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
            required: true,
            placeholder: "Please enter phone number",
            error: "Please enter phone number",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "password",
            formFloating: false,
            label: "Password",
            name: "password",
            value: "",
            required: true,
            regex: "",
            maxLength: "",
            placeholder: "",
            error: "Please enter valid password",
            hideMandatoryStar: false,
        },
        // {
        //     title: "input",
        //     type: "password",
        //     formFloating: false,
        //     label: "Confirm Password",
        //     name: "confirm_password",
        //     value: "",
        //     required: true,
        //     regex: "",
        //     maxLength: "",
        //     placeholder: "",
        //     error: "Please enter valid password",
        //     hideMandatoryStar: true,
        // },
        {
            type: "button",
            class: "col-md-12 ms-auto d-flex justify-content-center my-2",
            buttons: [
                {
                    title: 'Sign Up',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'sign_btn btn-sm py-1 text-white text-uppercase fw-bold ms-2 me-4 px-5 f14'
                },
            ]
        }
    ];
    const contactFormData = [
        {
            title: "input",

            type: "text",
            label: "Name",
            name: "name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "text",
            formFloating: false,
            label: "Email",
            name: "user_email",
            value: "",
            required: true,
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{3}$/,
            maxLength: "",
            placeholder: "Please enter email",
            error: "Please enter email",
            submitButtonName: "Login",
            hideMandatoryStar: true,
        },

        {
            title: "input",

            type: "text",
            label: "Phone Number",
            formFloating: false,
            name: "mobile_number",
            value: '',
            maxLength: 10,
            regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
            required: true,
            placeholder: "Please enter phone number",
            error: "Please enter phone number",
            hideMandatoryStar: true,
        },
        {
            title: "textarea",

            type: "text",
            label: "Comment or Message",
            name: "message",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter Comment or Message",
            error: "Please enter Comment or Message",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 ms-auto d-flex justify-content-center my-2",
            buttons: [
                {
                    title: 'Submit',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'sign_btn btn-sm py-1 text-white text-uppercase fw-bold  mt-3 me-4 px-5 f14'
                },
            ]
        }
    ];
    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            if (data?.formData?.user_email === "hari@mail.com" && data?.formData?.password === "123456") {
                sessionStorage.setItem('user_role', 'INDIVIDUAL_CUSTOMER');
                sessionStorage.setItem('user_name', 'Hari');
                navigate("/dashboard/api-integrations");
                toast.success("Loggedin successfully", { position: "top-right" })
            }
        }
    }
    const onSignUpFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            if (data?.formData?.user_email === "customer@mail.com" && data?.formData?.password === "123456") {
                sessionStorage.setItem('user_role', 'CUSTOMER');
                sessionStorage.setItem('user_name', 'Customer');
                toast.success("Sign Up successfully", { position: "top-right" })
            }
        }
    }
    const onSubmitContactForm = (event: any) => {

    }
    const handleSignUp = () => {
        setShowSignUpPopup(true);
    }
    const handleLogin = () => {
        setShowCreatePopup(true);
    }
    return (
        <div className="bg_blu blz_block">
            {showCreatePopup && (
                <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-primary mx-4 px-0">
                                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Login </strong></h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                            </div>
                            <div className="modal-body px-4 text-start">
                                <div className='row'>
                                    <DynamicForm
                                        userFormData={formData}
                                        columns={1}
                                        isEdit={false}
                                        onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                        customClass={"display-block"}
                                        isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showSignUpPopup && (
                <div className={`modal modal-custom fade ${showSignUpPopup ? "show" : "false"}  ${showSignUpPopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-primary mx-4 px-0">
                                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">SignUp </strong></h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowSignUpPopup(false)}></button>
                            </div>
                            <div className="modal-body px-4 text-start">
                                <div className='row'>
                                    <DynamicForm
                                        userFormData={signUpFormData}
                                        columns={2}
                                        isEdit={false}
                                        onFormSubmitEvent={(e) => onSignUpFormSubmitEvent(e)}
                                        customClass={"display-block"}
                                        isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="text-end mt-4 pt-4 me-5">
                <button
                    className=" btn primary-button  border-0 btn-primary px-5 btn-sm f16 text-uppercase fw-bold py-1" onClick={handleLogin}>
                    Login
                </button>
                <button
                    className=" sign_btn btn-sm py-1 text-white text-uppercase fw-bold ms-2 me-4 px-5 f14" onClick={handleSignUp}>
                    Sign Up
                </button>
            </div>
            <div className="row justify-content-center ">
                <div className="col-12 col-md-10 px-5 ">

                    <h1 className=" mt-1 mb-5 pb-2">
                        <img src={Logo} alt="" className="my-4 blz_logo" />
                    </h1>
                    <div className="row">
                        <div className="col-12 col-md-7 mt-5">
                            <div>
                                <h1 className="white-label-text txt_green f18">WHITE LABEL HTML5 CASUAL GAMING PLATFORM</h1>
                                <h1 className="tournaments-text">Casual Games</h1>
                                <h1 className="tournaments-text">& Tournaments</h1>
                                <div className="content-kingdom-text">
                                    <div className="brd_btn wp-block-buttons wp-block-button__link white_txt">
                                        <a>ANYWHERE | ANYTIME | PLAYTIME</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-12 col-md-5">
                            <div className="game_banner">
                                <img src={GAMEBANNER} alt="" className="my-4 " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mb-2">
                <div className="col-12 col-md-10">
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="wp-block-column ">
                                <div className="wp-block-group is-style-customborderhover">
                                    <figure>
                                        <img className="wp-block-image" src={TELEGRAMIMG} alt="WHITE LABEL HTML5 CASUAL GAMING PLATFORM" />
                                    </figure>
                                    <h2 className="wp-block-heading white_txt">Full Featured</h2>
                                    <p className="mb-0 has-zeever-bodytext-color has-text-color">A comprehensive white label gaming platform, that’s highly configurable, features localization & offers API integration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="wp-block-column ">
                                <div className="wp-block-group premium_brd">
                                    <figure>
                                        <img className="wp-block-image" src={GLOBEIMG} alt="WHITE LABEL HTML5 CASUAL GAMING PLATFORM" />
                                    </figure>
                                    <h2 className="wp-block-heading white_txt">Premium Content</h2>
                                    <p className="mb-0 has-zeever-bodytext-color has-text-color">Catalog of premium HTML5 casual games of skill, with live leaderboards, chat & game Quests. Emphasis is on quality over quantity.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="wp-block-column ">
                                <div className="wp-block-group is-style-customborderhover">
                                    <figure>
                                        <img className="wp-block-image" src={CREATIVEIMG} alt="WHITE LABEL HTML5 CASUAL GAMING PLATFORM" />
                                    </figure>
                                    <h2 className="wp-block-heading white_txt">Engaging</h2>
                                    <p className="mb-0 has-zeever-bodytext-color has-text-color">Our core focus is on building scalable & highly engaging casual gaming communities, that reward users & keep them coming back.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row game_platform_block justify-content-center mt-5 pt-4">
                <div className="col-12 col-md-10">
                    <h1 className="is-style-lineseparator games-platform-heading-text white_txt">Gaming Platform.</h1>
                    <h2 className="white-label-text txt_green f18 mt-4">HOSTED ON HIGH Availability Global CLOUD INFRASTRUCTURE</h2>
                    <div className="text-center">
                        <figure>
                            <img className="core_platform_img" src={COREPLATFORM} alt="COREPLATFORM" />
                        </figure>
                        <div className="row">
                            <div className="col-12 col-md-4"></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-10 mt-5">
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="wp-block-column ">
                                <div className="wp-block-group is-style-customborderbottomhover">
                                    <figure>
                                        <img className="wp-block-image" src={STRATATGY} alt="WHITE LABEL HTML5 CASUAL GAMING PLATFORM" />
                                    </figure>
                                    <h2 className="wp-block-heading white_txt">Revenue Streams</h2>
                                    <p className="mb-0 has-zeever-bodytext-color has-text-color">
                                        Configure the user access based on your strategy, as Free or with Coins, Pods, Subscriptions, XP Level & / or Pre-roll Ads.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="wp-block-column ">
                                <div className="wp-block-group has-zeever-secondary-background-color">
                                    <figure>
                                        <img className="wp-block-image" src={UIDESIGN} alt="WHITE LABEL HTML5 CASUAL GAMING PLATFORM" />
                                    </figure>
                                    <h2 className="wp-block-heading ">Cross Device</h2>
                                    <p className="mb-0 has-zeever-bodytext-color ">
                                        A responsive web based solution for all browsers, deployable in native apps, with optimized layouts across devices.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="wp-block-column ">
                                <div className="wp-block-group is-style-customborderbottomhover">
                                    <figure>
                                        <img className="wp-block-image" src={CONTENT} alt="WHITE LABEL HTML5 CASUAL GAMING PLATFORM" />
                                    </figure>
                                    <h2 className="wp-block-heading white_txt">Localization</h2>
                                    <p className="mb-0 has-zeever-bodytext-color has-text-color">
                                        The platform makes use of AWS Translate to translate all the site text elements, control panel access allows for tweaking.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-10 mt-5">
                    <div className="row">
                        <div className="col-md-4 col-12">
                            <div className="wp-block-column ">
                                <div className="wp-block-group is-style-customborderbottomhover">
                                    <figure>
                                        <img className="wp-block-image" src={MARKETING} alt="WHITE LABEL HTML5 CASUAL GAMING PLATFORM" />
                                    </figure>
                                    <h2 className="wp-block-heading white_txt">Engagement</h2>
                                    <p className="mb-0 has-zeever-bodytext-color has-text-color">
                                        Emphasis has been placed on player engagement with premium games, quests, tournaments, PvP challenges & rewards.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="wp-block-column ">
                                <div className="wp-block-group is-style-customborderbottomhover">
                                    <figure>
                                        <img className="wp-block-image" src={WEBIMG} alt="WHITE LABEL HTML5 CASUAL GAMING PLATFORM" />
                                    </figure>
                                    <h2 className="wp-block-heading white_txt">API Integration</h2>
                                    <p className="mb-0 has-zeever-bodytext-color has-text-color">A full suite of JSON API’s is available to facilitate player onboarding, login, purchase updates (tokenized).</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <div className="wp-block-column ">
                                <div className="wp-block-group is-style-customborderbottomhover">
                                    <figure>
                                        <img className="wp-block-image" src={SOCIALMEDIA} alt="WHITE LABEL HTML5 CASUAL GAMING PLATFORM" />
                                    </figure>
                                    <h2 className="wp-block-heading white_txt">Community</h2>
                                    <p className="mb-0 has-zeever-bodytext-color has-text-color">Create friends lists, invite players to challenges, live leaderboards, chat, avatars & community activity page.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="game_platform_block ">
                <div className="guten-hfNvY5 mt-5">
                    <div className="guten-background-overlay pt-5">
                        <div className="guten-container">
                            <div className=" d_align_block">
                                <div className="guten-column-wrapper">
                                    <div className="mx-5 px-5">
                                        <div className="d-flex justify-content-center">
                                            <div className="mt-4 mb-5">
                                                <span className="media_icons "><a href="https://www.twitter.com"><i className="fab fa-twitter  text-white f18"></i></a></span>
                                                <span className="media_icons ms-3"><a href="https://www.facebook.com"><i className="fab fa-facebook-f text-white f18 p-1"></i></a></span>
                                                <span className="media_icons ms-3"><a href="https://www.youtube.com"><i className="fab fa-youtube text-white f18 "></i></a></span>
                                                <span className="media_icons ms-3"><a href="https://www.instagram.com"><i className="fab fa-instagram text-white f18 p-1"></i></a></span>
                                            </div>
                                        </div>
                                        <h2 className="text_center header_text">Get in on the action, <br /> contact us now!</h2>
                                        <div className="form_width mt-5">
                                            <DynamicForm
                                                userFormData={contactFormData}
                                                columns={1}
                                                isEdit={false}
                                                onFormSubmitEvent={(e) => onSubmitContactForm(e)}
                                                customClass={"display-block"}
                                                isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                                        </div>
                                        <div className="content-kingdom-text">
                                            <div className="brd_btn wp-block-buttons wp-block-button__link text-white f18 text-uppercase">
                                                <a>Get in touch To deploy your own custom white label gaming platform</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}