import API from "../utils/axios";
let common = '/v1/api';

export const GET_CONTENT = (data: any): Promise<any> => {
    return API.get(`${common}/contents`, { params: data });
};

export const CREATE_CONTENT = (data: any): Promise<any> => {
    return API.post(`${common}/contents`, data);
};
export const UPDATE_CONTENT = (uuid: any, data: any): Promise<any> => {
    return API.put(`${common}/contents/${uuid}`, data);
};

export const DELETE_CONTENT = (data: any): Promise<any> => {
    return API.delete(`${common}/contents/${data}`);
};