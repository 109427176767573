import { DataTableCol } from "../../../../components/data-table/types";

export const CategoriesDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Notes',
        control: 'notes',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        isView: false,
        isAddAddress: true,
        canShowColumn: true
    },
];