import React, { useEffect, useState } from 'react'
import CCAvenue from './../../../assets/images/cc-avenue.png';
import { CustomCard } from '../../../components/custom-card';
import { DynamicForm } from '../../../components/form-builder/form';
import Paypal from './../../../assets/images/pay-pal.png';
import PayTabs from './../../../assets/images/pay-tabs.png';
import MOLpay from './../../../assets/images/mol-pay.png';
import Ipay88 from './../../../assets/images/ipay88.png';
import Dana from './../../../assets/images/dana.png';
import Countries from '../../../app/utils/countries.json'
import { GET_PAYMENT_PARTNERS, PAYMENT_PARTNER_CREATE, PAYMENT_PARTNER_DELETE, PAYMENT_PARTNER_UPDATE } from '../../../app/services/payment-partners.service';
import toast from 'react-hot-toast';
import { Loader } from '../../../components/loader';
import { useNavigate } from 'react-router-dom';
export const PaymentPartners = () => {
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [showDetailsPopUp, setShowDetailsPopUp] = useState<boolean>(false);

    const paymentPartnersData = [
        {
            title: "Dana",
            image: Dana
        },
        {
            title: "CC Avenue",
            image: CCAvenue
        },
        {
            title: "Paypal",
            image: Paypal
        },
        {
            title: "Pay Tabs",
            image: PayTabs
        },
        {
            title: "MolPay",
            image: MOLpay
        },
        {
            title: "Ipay88",
            image: Ipay88
        },

    ]
    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);

    useEffect(() => {
        getCustomers(pageCount);
    }, [])


    const getCustomers = (data: any) => {
        setLoading(true);
        GET_PAYMENT_PARTNERS(data).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data?.rows);
                    setLoading(false);
                }
            }
        });
    }

    const onShowCreatePaymentPartnersPopup = () => {
        // setShowCreatePopup(true);
        navigation(`./1`)
    }
    const onDeleteUserConfirmation = () => {
        setLoading(true);
        PAYMENT_PARTNER_DELETE(selectedData?.uuid).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.error, { position: 'top-right', })
                setLoading(false);
            } else {
                if (res) {
                    toast.success(res.message, { position: 'top-right', })
                    setLoading(false);
                    setShowDeletePopup(false);
                    getCustomers(pageCount)
                }
            }
        });
    }

    const onCardAction = (event: any) => {
        const data = event;
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            navigation(`./${data?.data?.uuid}`)
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        } else if (data?.type === "View") {
            setShowDetailsPopUp(true)
        }
    }
    return (
        <div className='container-fluid px-4 content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Payment Partners</h4>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    <button className='primary-button btn btn-primary text-dark fw-bold border-0' onClick={onShowCreatePaymentPartnersPopup}>Create Payment Partner</button>
                </div>
            </div>
            <div className='row my-3'>
                <Loader LoadingReducer={loading} />
                {tableListData?.length > 0 && (
                    <CustomCard contentData={tableListData} paymentPartners={true} customClass={true} cardAction={(e) => onCardAction(e)} />
                )}
                {tableListData?.length <= 0 && (
                    <div className='text-center'>No records found.</div>
                )}
            </div>
            {showDeletePopup && (
                <>
                    <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content text-start">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                </div>
                                <div className="modal-body pb-5">
                                    <div className="row px-2">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.code|| ""}</span> ?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='px-2 text-end'>
                                        <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                        <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {showDetailsPopUp && (
                <div className={`modal modal-custom fade ${showDetailsPopUp ? "show" : "false"}  ${showDetailsPopUp ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-primary mx-4 px-0">
                                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Details</strong></h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDetailsPopUp(false)}></button>
                            </div>
                            <div className="modal-body px-4 text-start">
                                <div className='row'>
                                    <div className='col-md-5'>
                                        <img src={selectedData?.image_url} className='w-75' />
                                    </div>
                                    <div className='col-md-7'>
                                        <div className='fw-bold fs-5'>{selectedData?.code}</div>
                                        <div className='fs-6 fw-semibold'>{selectedData?.type}</div>
                                        <div className='fs-6'>{selectedData?.notes}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
