import { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { DynamicForm } from '../../components/form-builder/form';
// import Logo from './../../assets/images/blazon-logo.png';
import LogoTwo from './../../assets/images/ampere-logo.jpeg';
import toast from 'react-hot-toast';
import { GET_USER_COUNTRY_MOBILE_CODE, LOGIN, REGISTER } from '../../app/services/common.service';
import CountryCodes from '../../app/utils/mobile-number-country-code.json';


export const RegisterPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loginFormData, setLoginFormData] = useState<any>([
    {
      title: "input",
      type: "text",
      label: "Name",
      name: "name",
      formFloating: true,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please enter name",
      error: "Please enter name",
      hideMandatoryStar: false,
      customParentClass: "form-floating",
    },
    {
      title: "input",
      type: "text",
      formFloating: true,
      label: "Email",
      name: "email",
      value: "",
      required: true,
      regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{3}$/,
      maxLength: "",
      placeholder: "Please enter email",
      error: "Please enter email",
      hideMandatoryStar: false,
      customParentClass: "form-floating",
    },
    // {
    //   title: "dropdown",
    //   type: "text",
    //   label: "code",
    //   name: "mobile_country_code",
    //   formFloating: true,
    //   value: '',
    //   option: CountryCodes,
    //   regex: "",
    //   required: true,
    //   placeholder: "Code",
    //   error: "Please select code",
    //   hideMandatoryStar: false,
    //   paddingZero: true,
    //   column: 3,
    //   customParentClass: "pe-0 form-floating",
    //   customClass: "rounded-end-0"
    // },
    {
      title: "dropdown",
      type: "text",
      label: "",
      name: "mobile_country_code",
      formFloating: true,
      value: '',
      option: CountryCodes,
      regex: "",
      required: true,
      placeholder: "Code",
      error: "Please select code",
      hideMandatoryStar: true,
      column: 3,
      paddingZero: true,
      customParentClass: "pe-0 form-floating",
      customClass: "rounded-end-0"
    },
    {
      title: "input",
      type: "text",
      formFloating: true,
      label: "Mobile Number",
      name: "mobile_number",
      value: "",
      required: true,
      regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
      maxLength: "15",
      placeholder: "",
      error: "Please enter mobile number",
      submitButtonName: "Login",
      hideMandatoryStar: true,
      column: 9,
      paddingZero: true,
      customParentClass: "ps-0 form-floating",
      customClass: "rounded-start-0"
    },
    // {
    //   title: "input",
    //   type: "text",
    //   label: "Phone Number",
    //   formFloating: false,
    //   name: "mobile_number",
    //   value: '',
    //   regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
    //   maxLength: "15",
    //   required: true,
    //   placeholder: "Please enter phone number",
    //   error: "Please enter phone number",
    //   hideMandatoryStar: false,
    //   column: 9,
    //   paddingZero: true,
    //   customParentClass: "ps-0 form-floating",
    //   customClass: "rounded-start-0"
    // },
    {
      title: "input",
      type: "password",
      formFloating: true,
      label: "Password",
      name: "password",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "Please enter  password",
      error: "Please enter valid password",
      hideMandatoryStar: false,
      customParentClass: "form-floating",

    },
    {
      title: "input",
      type: "text",
      label: "Address",
      name: "message",
      formFloating: true,
      value: '',
      regex: "",
      placeholder: "Please enter address",
      error: "Please enter address",
      hideMandatoryStar: true,
      column: '12',
      customParentClass: "form-floating",

    },
    {
      type: "button",
      class: "col-md-12 ms-auto d-flex justify-content-center my-2",
      buttons: [
        {
          title: 'Sign Up',
          action: 'add',
          isCheckFormValidation: true,
          class: 'sign_btn btn-sm py-1 text-white text-uppercase fw-bold ms-2 me-4 px-5 f14'
        },
      ]
    }
  ]);
  const navigation = useNavigate();

  const location = useLocation();
  const locationRef = useRef(location);

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  useEffect(() => {

    navigator.geolocation.getCurrentPosition(function (position) {
      var lat = position.coords.latitude;
      var lon = position.coords.longitude;

      // Use a geolocation API to get country code from lat/lon
      var geolocationAPI = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`;

      fetch(geolocationAPI)
        .then((response) => response.json())
        .then((data) => {
          var countryCode = data.countryCode;
          const locationCountry = CountryCodes.find(
            (country: any) => country?.code === countryCode
          );
          const loginForm = loginFormData;
          loginForm[2].value = locationCountry?.dial_code;
          setLoginFormData([...loginForm]);
        });
    });

    const handlePopState = () => {
      // Perform any action you need to do on back button click
    };

    const handleLocationChange = () => {
      if (locationRef.current.pathname !== window.location.pathname) {
        handlePopState();
      }
    };

    window.addEventListener('popstate', handleLocationChange);

    return window.removeEventListener('popstate', handleLocationChange);
  }, []);

  const onSignUpFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
      // if (data?.formData?.user_email === "customer@mail.com" && data?.formData?.password === "123456") {
      //     sessionStorage.setItem('user_role', 'CUSTOMER');
      //     sessionStorage.setItem('user_name', 'Customer');
      //     toast.success("Sign Up successfully", { position: "top-right" })
      // }
      const formData = event?.formData;
      formData.user_role = "CUSTOMER";
      formData.dob = formData.dob ? formData.dob : null;
      formData.address = formData.address ? formData.address : '';
      formData.profile_img = '';
      formData.metadata = {};
      formData.type = "mobilepass";
      delete formData['undefined'];
      setLoading(true);
      REGISTER(formData).then((res: any) => {
        if (res?.status === "fail") {
          setLoading(false);
          toast.error(res.error, { position: "top-right" });
        } else {
          if (res) {
            toast.success(res.message, { position: 'top-right' })
            setLoading(false);
            navigation("/");
          }
        }
      });
    }
  }
  return (
    <div className="modal login-bg" style={{ display: "block" }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="">
            <div className="primary-bg login-bg rounded-3 d-flex flex-column justify-content-center">
              <div className="">
                <form className="m-4">
                  <div className="Login-form-content">
                    <h2 className="Login-form-title login_logo text-center fw-bold p-5">
                      <img src={LogoTwo} alt="" className="my-4 login-logo rounded-3" />
                    </h2>
                    <h4 className="Login-form-title text-white text-center fw-bold my-4">REGISTER</h4>
                    <DynamicForm
                      userFormData={loginFormData}
                      columns={1}
                      isEdit={false}
                      onFormSubmitEvent={(e) => onSignUpFormSubmitEvent(e)}
                      customClass={"display-block"}
                      loading={loading}
                      isNotEmptyFormAfterSubmit={true} cancelRoute={undefined}
                    />
                    <div className="flex-column justify-content-center ">
                      <div className="my-2 text-center">
                        <NavLink
                          to="/"
                          className="text-white "
                        >
                          Login
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer/> */}
      </div >
    </div >
  )
}