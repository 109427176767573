import React, { useState } from 'react'
import { DynamicForm } from '../../../../components/form-builder/form';

export const ToolsAPITesterPage = () => {
  const [responseData, setResponseData] = useState<any>();
  const [form, setForm] = useState<any>([
    {
      title: "input",
      type: "text",
      label: "URL",
      name: "URL",
      formFloating: false,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please enter URL",
      error: "Please enter URL",
      hideMandatoryStar: false,
    },
    {
      title: "dropdown",
      type: "dropdown",
      label: "Type",
      name: "type",
      formFloating: false,
      option: [
        {
          label: 'GET',
          value: 'GET'
        },
        {
          label: 'POST',
          value: 'POST'
        },
        {
          label: 'PUT',
          value: 'PUT'
        },
        {
          label: 'PATCH',
          value: 'PATCH'
        },
        {
          label: 'DELETE',
          value: 'DELETE'
        },
        {
          label: 'HEAD',
          value: 'HEAD'
        },
        {
          label: 'OPTION',
          value: 'OPTION'
        }
      ],
      value: '',
      regex: "",
      required: true,
      placeholder: "Please enter type",
      error: "Please enter type",
      hideMandatoryStar: false,
    },
    {
      title: "formArray",
      label: 'Header',
      name: 'headers',
      fields: [
        {
          fields: [
            {
              title: "input",
              type: "text",
              label: "Key",
              formFloating: false,
              name: "key",
              value: '',
              required: false,
              placeholder: "Please enter key",
              error: "Please enter key",
              hideMandatoryStar: true,
              column: 5
            },
            {
              title: "input",
              type: "text",
              label: "Value",
              formFloating: false,
              name: "Value",
              value: '',
              required: false,
              placeholder: "Please enter value",
              error: "Please enter value",
              hideMandatoryStar: true,
              column: 5
            },
            {
              type: "button",
              class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
              buttons: [
                {
                  title: 'Add',
                  icons: 'bi bi-plus-circle',
                  action: 'addHeader',
                  class: 'btn btn-primary px-4 btn-sm f16 me-1'
                }
              ]
            },
          ]
        }
      ]
    },
    {
      title: "textarea",
      type: "textarea",
      label: "Body",
      name: "body",
      formFloating: false,
      value: '',
      regex: "",
      required: false,
      placeholder: "Please enter data",
      error: "Please enter data",
      hideMandatoryStar: true,
    },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-center my-2",
      buttons: [
        {
          title: 'Submit',
          action: 'Submit',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1'
        },
      ]
    },
  ]);

  const onFormSubmit = (event: any) => {
    const data = event;
    if (data?.type === "Submit") {
      const formData = event?.formData;

      if (formData?.headers) {
        const header: any = {}
        formData?.headers?.forEach((element: any) => {
          header[element.key] = element.value
        });
        formData.headers = header;
      }
      delete formData['undefined'];
      if (formData?.type === 'POST') {
        fetch(formData?.URL, {
          method: formData?.type,
          referrerPolicy: "strict-origin-when-cross-origin",
          body: formData?.body,
          headers: formData?.headers,
        })
          .then((response) => {
            
            if (!response.ok) {
              setResponseData(response);
            }
            return response.json();
          })
          .then((data) => {
            if (data) {
              setResponseData(data);
            }
          }).catch((error) => console.error("Error:", error));

      } else {
        fetch(formData?.URL, {
          method: formData?.type,
          referrerPolicy: "strict-origin-when-cross-origin",
          headers: formData?.headers,
        })
          .then((response) => {
            if (!response.ok) {
              setResponseData(data?.data);
            }
            return response.json();
          })
          .then((data) => {
            if (data) {
              setResponseData(data?.data);
            }
          }).catch((error) => console.error("Error:", error));
      }

    } else if (data?.type === 'addHeader') {
      const formData = form;
      formData[event.index].fields.push(
        {
          fields: [
            {
              title: "input",
              type: "text",
              label: "Key",
              formFloating: false,
              name: "key",
              value: '',
              required: true,
              placeholder: "Please enter key",
              error: "Please enter key",
              hideMandatoryStar: true,
              column: 5
            },
            {
              title: "input",
              type: "text",
              label: "Value",
              formFloating: false,
              name: "Value",
              value: '',
              required: true,
              placeholder: "Please enter value",
              error: "Please enter value",
              hideMandatoryStar: true,
              column: 5
            },
            {
              type: "button",
              class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
              buttons: [
                {
                  title: 'Add',
                  icons: 'bi bi-plus-circle',
                  action: 'addHeader',
                  class: 'btn btn-primary px-4 btn-sm f16 me-1'
                }
              ]
            },
          ]
        }
      )
      formData[event.index].fields?.forEach((element: any, index: number) => {
        if ((formData[event.index].fields.length - 1) !== index)
          element.fields[2].buttons = [
            {
              title: 'Delete',
              icons: 'bi bi-trash',
              action: 'deleteHeader',
              class: 'btn btn-danger px-4 btn-sm f16 me-1'
            }
          ]
      });
      setForm([...formData])
    } else if (data?.type === 'deleteHeader') {
      const formData = form;
      formData[event.index].fields.splice(event.sunIndex, 1);
      formData[event.index].fields?.forEach((element: any, index: number) => {
        if ((formData[event.index].fields.length - 1) !== index)
          element.fields[2].buttons = [
            {
              title: 'Delete',
              icons: 'bi bi-trash',
              action: 'deleteHeader',
              class: 'btn btn-danger px-4 btn-sm f16 me-1'
            }
          ]
      });
      setForm([...formData]);
    }
  }
  return (
    <div className='container-fluid content-bg-color'>
      <div className='row my-3'>
        <DynamicForm
          userFormData={form}
          columns={1}
          isEdit={false}
          onFormSubmitEvent={(e) => onFormSubmit(e)}
          customClass={"display-block"}
          isNotEmptyFormAfterSubmit={true} cancelRoute={undefined}
        />
      </div>
      <div>
        <h6>Result:</h6>
        <div>
          <textarea name="" id="" className='form-control border-primary-medium'  value={JSON.stringify(responseData?.data, null, 2)}></textarea>
        </div>
        <div className='mt-3'>
          <button className='btn btn-primary px-4 btn-sm f16 me-1' onClick={() => navigator.clipboard.writeText(responseData?.data)}>Copy Result</button>
        </div>
      </div>
    </div>
  )
}