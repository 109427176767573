import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { CustomerSites } from '../pages/dashboard/customers/customers-tabs/sites/customer-sites'
import { CustomersContents } from '../pages/dashboard/customers/customers-tabs/content/customers-contents'
import { CustomersSubscriptions } from '../pages/dashboard/customers/customers-tabs/subscriptions/customers-subscriptions'
import { Sites } from '../pages/dashboard/customer/sites'
import { CustomersContentTabInfo } from '../pages/dashboard/customers/customers-tabs/sites/content-tabs/content-tab-info'
import { Integrations } from '../pages/dashboard/customers/customers-tabs/sites/integrations/integrations'
import { CustomerTabs } from '../pages/dashboard/customer/reports/reportstab'
import { CustomerContents } from '../pages/dashboard/customers/customers-tabs/sites/customer-content/customer-content'
import { ContentTabInfo } from '../pages/dashboard/content/content-tabs/content-tab-info'

export const SiteTabRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<CustomersSubscriptions />}></Route>
            <Route path="/subscriptions" element={<CustomersSubscriptions />}></Route>
            <Route path="/content/*" element={<ContentTabInfo />}></Route>
            <Route path="/integrations" element={<Integrations />}></Route>
            <Route path="/reports" element={<CustomerTabs />}></Route>
        </Routes>
    )
}
