import { DataTableCol } from "../../../components/data-table/types";

export const ContactUsDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'name',
        isLink: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Email',
        control: 'email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Phone Number',
        control: 'mobile_number',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Details',
        control: 'details',
        sortable: true,
        canShowColumn: true
    },
   
];