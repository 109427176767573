import React, { useEffect, useState } from 'react'
import { Search } from '../../../../components/search/search';
import { DataTable } from '../../../../components/data-table';
import { DynamicForm } from '../../../../components/form-builder/form';
import { SystemParamsDataGridCols } from './system-params-data-grid-cols';
import { useNavigate } from 'react-router-dom';
import { GET_SYSTEM_PARAMS, SYSTEM_PARAMS_CREATE, SYSTEM_PARAMS_DELETE, SYSTEM_PARAMS_UPDATE } from '../../../../app/services/system-params.service';
import toast from 'react-hot-toast';
import { Loader } from '../../../../components/loader';
export const SystemParams = () => {
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);
    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
    });

    const [formData, setFormData] = useState<any>([
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "name",
            formFloating: false,
            value: '',
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: false,
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Type",
            name: "type",
            option: [
                { label: "backend", value: 'BACKEND' },
                { label: "frontend", value: 'FRONTEND' },
            ],
            value: 'BACKEND',
            regex: "",
            required: true,
            placeholder: "Please select type",
            error: "Please select type",
            hideMandatoryStar: false
        },
        {
            title: "formArray",
            label: 'Meta Data',
            name: 'metadata',
            fields: [
                {
                    fields: [
                        {
                            title: "input",
                            type: "text",
                            label: "Key",
                            formFloating: false,
                            name: "key",
                            value: '',
                            required: true,
                            placeholder: "Please enter key",
                            error: "Please enter key",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            title: "input",
                            type: "text",
                            label: "Value",
                            formFloating: false,
                            name: "Value",
                            value: '',
                            required: true,
                            placeholder: "Please enter value",
                            error: "Please enter value",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            type: "button",
                            class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
                            buttons: [
                                {
                                    title: 'Add',
                                    icons: 'bi bi-plus-circle',
                                    action: 'addHeader',
                                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                                }
                            ]
                        },
                    ]
                }
            ]
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    useEffect(() => {
        getSystemParameters(pageCount);
    }, [])


    const getSystemParameters = (data: any) => {
        setLoading(true);
        GET_SYSTEM_PARAMS(data).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const onActions = (data: any) => {
        setSelectedData(data?.data)
        if (data?.type === "Edit") {
            setIsEdit(true);
            const categoryFormData = formData;
            setFormData(categoryFormData);
            categoryFormData?.forEach((element: any) => {
                if (element?.type === "button") {
                    for (let i = 0; i < element?.buttons?.length; i++) {
                        const ele = element?.buttons;
                        ele[0].title = "Update";
                        ele[0].action = "edit";
                    }
                }
                element.value = data?.data[element.name]
            });
            setFormData([...categoryFormData]);
            setShowCreatePopup(true);
        } else if (data?.type === "Delete") {
            setShowDeletePopup(true);
        } else if (data?.type === "link") {
        }
    }

    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getSystemParameters(pageCount);
    };

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getSystemParameters(pageCount);
    };
    const onDeleteUserConfirmation = () => {
        setLoading(true);
        SYSTEM_PARAMS_DELETE(selectedData?.uuid).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res.error, { position: 'top-right', })
                setLoading(false);
            } else {
                if (res) {
                    toast.success(res.message, { position: 'top-right', })
                    setLoading(false);
                    setShowDeletePopup(false);
                    getSystemParameters(pageCount)
                }
            }
        });
    }
    const onShowCreatePopup = () => {
        setShowCreatePopup(true);
        setIsEdit(false);
        const categoryFormData = formData;
        categoryFormData?.forEach((element: any) => {
            if (element?.type === "button") {
                for (let i = 0; i < element?.buttons?.length; i++) {
                    const ele = element?.buttons;
                    ele[0].title = "Create";
                    ele[0].action = "add";
                }
            }
            element.value = "";
        })
        setFormData([...formData])
    }
    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = event?.formData;
            if (formData?.metadata) {
                const metadata: any = {}
                formData?.metadata.forEach((element: any) => {
                    metadata[element.key] = element.value
                });
                formData.metadata = metadata;
            }
            delete formData['undefined'];
            setLoading(true);
            SYSTEM_PARAMS_CREATE(formData).then((res: any) => {
                if (res?.status === "fail") {
                    setLoading(false);
                    toast.error(res.error, { position: "top-right" });

                } else {
                    if (res) {
                        toast.success(res.message, { position: 'top-right' })
                        setLoading(false);
                        getSystemParameters(pageCount);
                        setShowCreatePopup(false);
                    }
                }
            });
        } else if (data?.type === "edit") {
            const formData = data?.formData;
            if (formData?.metadata) {
                const metadata: any = {}
                formData?.metadata.forEach((element: any) => {
                    metadata[element.key] = element.value
                });
                formData.metadata = metadata;
            }
            delete formData['undefined'];
            setLoading(true);
            SYSTEM_PARAMS_UPDATE(selectedData?.uuid, formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        setShowCreatePopup(false);
                        getSystemParameters(pageCount);
                        toast.success(res.message, { position: 'top-right' })
                        setLoading(false);
                    }
                }
            });
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false);
        } else if (data?.type === 'addHeader') {
            const form = formData;
            form[event.index].fields.push(
                {
                    fields: [
                        {
                            title: "input",
                            type: "text",
                            label: "Key",
                            formFloating: false,
                            name: "key",
                            value: '',
                            required: true,
                            placeholder: "Please enter key",
                            error: "Please enter key",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            title: "input",
                            type: "text",
                            label: "Value",
                            formFloating: false,
                            name: "Value",
                            value: '',
                            required: true,
                            placeholder: "Please enter value",
                            error: "Please enter value",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            type: "button",
                            class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
                            buttons: [
                                {
                                    title: 'Add',
                                    icons: 'bi bi-plus-circle',
                                    action: 'addHeader',
                                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                                }
                            ]
                        },
                    ]
                }
            )
            form[event.index].fields?.forEach((element: any, index: number) => {
                if ((form[event.index].fields.length - 1) !== index)
                    element.fields[2].buttons = [
                        {
                            title: 'Delete',
                            icons: 'bi bi-trash',
                            action: 'deleteHeader',
                            class: 'btn btn-danger px-4 btn-sm f16 me-1'
                        }
                    ]
            });
            setFormData([...form])
        } else if (data?.type === 'deleteHeader') {
            const form = formData;
            form[event.index].fields.splice(event.sunIndex, 1);
            form[event.index].fields?.forEach((element: any, index: number) => {
                if ((form[event.index].fields.length - 1) !== index)
                    element.fields[2].buttons = [
                        {
                            title: 'Delete',
                            icons: 'bi bi-trash',
                            action: 'deleteHeader',
                            class: 'btn btn-danger px-4 btn-sm f16 me-1'
                        }
                    ]
            });
            setFormData([...form]);
        }
    }
    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getSystemParameters(pageData);
    };

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row'>
                <div className='col-md-8 '>
                    <div className='w-100'>
                        <Search onSearch={(e: any) => onSearch(e)} />
                    </div>
                </div>
                <div className='col-md-4 text-end'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create System Param</button>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12 px-0'>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={SystemParamsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
                {showCreatePopup && (
                    <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <Loader LoadingReducer={loading} />
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} System Param</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                                </div>
                                <div className="modal-body px-4 text-start">
                                    <div className='row'>
                                        <DynamicForm
                                            userFormData={formData}
                                            columns={2}
                                            isEdit={isEdit}
                                            onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                                            customClass={"display-block"}
                                            isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showDeletePopup && (
                    <>
                        <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                            <Loader LoadingReducer={loading} />
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content text-start">
                                    <div className="modal-header border-bottom-primary mx-4 px-0">
                                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                    </div>
                                    <div className="modal-body pb-5">
                                        <div className="row px-2">
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.name || ""}</span>?</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='px-2 text-end'>
                                            <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                            <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
