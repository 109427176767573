import React, { useEffect, useState } from 'react'
import { CustomCard } from '../../../../components/custom-card';
import { DynamicForm } from '../../../../components/form-builder/form';
import { Search } from '../../../../components/search/search';
import { useParams } from 'react-router-dom';
import { GET_CATEGORIES } from '../../../../app/services/categories.service';
import { GET_CONTENT } from '../../../../app/services/content-service';
import { Loader } from '../../../../components/loader';

export const VideoContent = () => {
  const [showDetailsPopUp, setShowDetailsPopUp] = useState<boolean>(false);
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tableListData, setTableListData] = useState<any>([]);
  const [categoryData, setCategoryData] = useState<any>([]);
  const params = useParams();
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
    user_uuid: params?.customer_uuid,
    site_uuid: params?.site_uuid
  });

  const videoContentData = [
    {
      id: "1",
      title: "Career Guidance",
      video_url: "https://www.youtube.com/watch?v=PbqneCx87rw&list=PLvJNSf-7NfrOCzW7CVLZav9Uqk8AM01R5",
      video: "",
      status: "1"
    },
    {
      id: "1",
      title: "Tourism",
      video_url: "https://www.youtube.com/watch?v=orlUDdeoxZQ",
      video: "",
      status: "1"
    },
    {
      id: "1",
      title: "Gaming",
      video_url: "https://www.youtube.com/watch?v=4Nm1N1_OEIk",
      video: "",
      status: "1"
    },
  ]

  const [formData, setFormData] = useState<any>([
    {
      title: "input",
      type: "text",
      label: "Video Name",
      name: "name",
      formFloating: false,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please enter video name",
      error: "Please enter video name",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "checkbox",
      label: "Do you want to upload video file?",
      formFloating: false,
      name: "is_video_file",
      value: false,
      placeholder: "Please select upload video file",
      error: "Please select upload video file",
      isParentDropDown: true,
      column: 6,
      className: 'me-2 mt-3'
    },
    {
      title: "textarea",
      type: "text",
      label: "Description",
      name: "notes",
      formFloating: false,
      value: '',
      regex: "",
      required: false,
      placeholder: "Please enter description",
      error: "Please enter description",
      hideMandatoryStar: true,
    },
    {
      title: "input",
      type: "text",
      label: "Video url",
      name: "media_url",
      formFloating: false,
      value: '',
      regex: "",
      required: false,
      placeholder: "Please enter video url",
      error: "Please enter video url",
      hideMandatoryStar: true,
    },
    // {
    //   title: "file",
    //   type: "file",
    //   label: "Video Upload",
    //   name: "media_url",
    //   value: "",
    //   required: false,
    //   regex: "",
    //   maxLength: "",
    //   isMultipleImages: false,
    //   placeholder: "Upload video",
    //   error: "Please upload video",
    //   hideMandatoryStar: true
    // },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-end my-2",
      buttons: [
        {
          title: 'Upload',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16'
        }
      ]
    },
  ])

  const [searchFormData, setSearchFormData] = useState([
    {
      title: "input",

      type: "text",
      label: "Search",
      name: "search",
      formFloating: false,
      value: '',
      regex: "",
      placeholder: "Please enter search",
      error: "Please enter search",
      hideMandatoryStar: true,
    },
    {
      type: "button",
      class: "col-md-3 d-flex justify-content-start my-2 mt-3 pt-1",
      buttons: [
        {
          title: 'Search',
          action: 'add',
          isCheckFormValidation: false,
          class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-3'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-3'
        }
      ]
    },
  ])

  useEffect(() => {
    getVideoContentData(pageCount, categoryData?.uuid);
    getCategoriesData();
  }, [])

  const getCategoriesData = () => {
    GET_CATEGORIES({ limit: 1000, page: 0 }).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          const response = res?.data?.rows;
          const filterData = response?.filter((el: any) => el?.name === "VIDEO");
          getVideoContentData(pageCount, filterData[0]?.uuid)
          setCategoryData(filterData[0])
          setLoading(false);
        }
      }
    });
  }

  const getVideoContentData = (data: any, categoryData: any) => {
    const pageData = data
    pageData.category_uuid = categoryData
    setLoading(true);
    GET_CONTENT(pageData).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          setTableListData(res?.data?.rows);
          setLoading(false);
        }
      }
    });
  }

  const getYoutubeEmbedUrl = (url: any) => {
    const videoId = url.split('v=')[1]?.split('&')[0];
    return `https://www.youtube.com/embed/${videoId}`;
  };

  const onShowCreateVideo = () => {
    setShowCreatePopup(true)
  }

  const onVideoFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {

    } else if (data?.type === "cancel") {
      setShowCreatePopup(false)
    }
  }

  const onDeleteUserConfirmation = () => { }
  const onSearch = (value: any) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
  }

  const isSelectedDropdown = (data: any) => {
    if (data?.name === 'is_video_file' && data?.value) {
      const videoFormData = formData;
      formData.splice(3, 1);
      const nameFiledObj = {
        title: "file",
        type: "file",
        label: "Video Upload",
        name: "media_url",
        value: "",
        required: false,
        regex: "",
        maxLength: "",
        isMultipleImages: false,
        placeholder: "Upload video",
        error: "Please upload video",
        hideMandatoryStar: true
      };
      videoFormData.splice(3, 0, nameFiledObj);
      setFormData([...videoFormData]);
    } else if (data?.name === 'is_video_file' && !data?.value) {
      const videoFormData = formData;
      const finalFormData = videoFormData.filter((data: any) => !(data?.name === "media_url" && data?.type === 'file'));
      const isServiceAreaInput = videoFormData.find((data: any) => (data?.name === "media_url" && data?.type === 'text'));
      if (!isServiceAreaInput) {
        const nameFiledObj = {
          title: "input",
          type: "text",
          label: "Video url",
          name: "media_url",
          formFloating: false,
          value: '',
          regex: "",
          required: false,
          placeholder: "Please enter video url",
          error: "Please enter video url",
          hideMandatoryStar: true,
        };
        finalFormData.splice(3, 0, nameFiledObj);
      }
      setFormData([...finalFormData]);
    }
  }

  return (
    <div className='row'>
      <div className='col-md-12 mb-3'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='w-100 mt-4 pt-1 px-2'>
              <Search onSearch={(e) => onSearch(e)} />
            </div>
          </div>
          <div className='col-md-4 mt-4 py-2 text-end'>
            <button className='btn btn-primary border-0' onClick={onShowCreateVideo}>Upload</button>
          </div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='row'>
          <Loader LoadingReducer={loading} />
          {videoContentData?.length > 0 && videoContentData?.map((video: any, index: number) => (
            <div className='col-lg-4' key={index}>
              <div className="video-container rounded-4">
                <iframe
                  width="100%"
                  height="215"
                  src={getYoutubeEmbedUrl(video?.video_url)}
                  title={video?.title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className='my-2 text-center'>
                <strong>{video?.title}</strong>
              </div>
            </div>
          ))}
          {videoContentData?.length === 0 && (
            <div className='text-center'>No records found</div>
          )}
        </div>
        {showCreatePopup && (
          <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-bottom-primary mx-4 px-0">
                  <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Upload"} Video</strong></h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                </div>
                <div className="modal-body px-4 text-start">
                  <div className='row'>
                    <DynamicForm
                      userFormData={formData}
                      columns={2}
                      isEdit={isEdit}
                      onFormSubmitEvent={(e) => onVideoFormSubmitEvent(e)}
                      onParentDropdownEvent={(e) => isSelectedDropdown(e)}
                      customClass={"d-flex justify-content-start border-primary-medium"}
                      isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showDeletePopup && (
          <>
            <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-start">
                  <div className="modal-header border-bottom-primary mx-4 px-0">
                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                  </div>
                  <div className="modal-body pb-5">
                    <div className="row px-2">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.firstname || selectedData?.name || ""}</span> ?</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-2 text-end'>
                      <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                      <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}
