import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Categories } from '../pages/dashboard/settings/categories/categories'
import { PaymentTypes } from '../pages/dashboard/settings/payment-types/payment-types'
import { DeploymentVersions } from '../pages/dashboard/settings/deployment-versions/deployment-versions'
import { SystemParams } from '../pages/dashboard/settings/system-params/system-params'

export const SettingsTabRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Categories />}></Route>
            <Route path="/categories" element={<Categories />}></Route>
            <Route path="/payment-types" element={<PaymentTypes />}></Route>
            <Route path="/deployment-versions" element={<DeploymentVersions />}></Route>
            <Route path="/system-params" element={<SystemParams />}></Route>
        </Routes>
    )
}
