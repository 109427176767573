// dev
// export const BASE_URL = "http://54.208.45.33:8500/";
// export const S3Url = "https://digitag-uploads.s3.ap-south-1.amazonaws.com/";

// // sandbox
// export const BASE_URL = "https://sandbox-api.ampere.digital/";
// export const S3Url = "https://ampere-sandbox.s3.ap-southeast-1.amazonaws.com/";

// prod
export const BASE_URL = "https://api.ampere.digital/";
export const S3Url = "https://ampere-production.s3.ap-southeast-1.amazonaws.com/";

const appConfig = {
  BASE_URL: BASE_URL,
  S3Url: S3Url,
};
export default appConfig;
