import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { SettingsTabRoutes } from '../../../routes/settings-tab-routes';
import { ToolsRoutes } from '../../../routes/tools-routes';

export const ToolsPage = () => {
    const location = useLocation();
    const currentPath: any = location.pathname.split("/");

    const tabs = [
        {
            id: "1",
            name: "Encryptor",
            link: `/dashboard/tools/encryptor`,
        },
        {
            id: "2",
            name: "Decryptor",
            link: `/dashboard/tools/decryptor`,
        },        {
            id: "3",
            name: "API Tester",
            link: `/dashboard/tools/API-tester`,
        },
    ]

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Tools</h4>
                </div>
            </div>
            <div className='row my-3'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {tabs.map((data: any, index: number) => {
                        return <NavLink key={index} to={data?.link} className={`nav-link text-dark px-4 fs-13
                    ${currentPath.length === 3 && data?.name === "Encryptor"
                                ? "active"
                                : ""
                            }`}>{data?.name}</NavLink>
                    })}
                </ul>
                <div className="tab-content shadow" id="myTabContent">
                    <ToolsRoutes />
                </div>
            </div >
        </div>
    )
}