import { DataTableCol } from "../../../../../components/data-table/types";

export const SiteSubscriptionReportsDataGridCols: DataTableCol[] = [
    {
        title: "Payment Date & Time",
        control: "payment_date_time",
        isDateTimeFormat: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "User",
        control: "user",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Subscription Types",
        control: "subscription_type",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Amount",
        control: "amount",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Status",
        control: "payment_status",
        isSuccessStatus: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Start Date",
        control: "start_date",
        isDateFormat: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Expiry Date",
        control: "expiry_date",
        isDateFormat: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Subscription Mode",
        control: "subscription_mode",
        isSubscriptionMode: true,
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Country",
        control: "country",
        sortable: true,
        canShowColumn: true,
    },
    // {
    //     title: "Time",
    //     control: "Time",
    //     isTimeFormat: true,
    //     sortable: true,
    //     canShowColumn: true,
    // },
    {
        title: "Client IP",
        control: "client_ip",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Payment Partner Settlement",
        control: "pp_amount",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Ampere Settlement",
        control: "ampere_amount",
        sortable: true,
        canShowColumn: true,
    },
];
