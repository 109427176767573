import { DataTableCol } from "../../../../components/data-table/types";

export const CustomerReportsDataGridCols: DataTableCol[] = [
  {
    title: "Customer Name",
    control: "customer_name",
    isLink: true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Payment Partner",
    control: "payment_partner",
    isButton: true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Total Subscriptions",
    control: "total_subscriptions",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Subscription Types",
    control: "total_subscription_types_count",
    isButton: true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Total Subscription Amount",
    control: "total_amount",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Payment Partner Settlement",
    control: "pp_amount",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Ampere Settlement",
    control: "ampere_amount",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Balance Settlement",
    control: "balance_amount",
    sortable: false,
    canShowColumn: true,
  },
];
