import React, { useEffect, useState } from 'react'
import { BreadCrumb } from '../../../../../components/bread-crumb'
import { useNavigate, useParams } from 'react-router-dom';
import { GET_SITE_SUBSCRIPTION_REPORTS, GET_SITES_REPORTS } from '../../../../../app/services/reports.service';
import { DataTable } from '../../../../../components/data-table';
import { Loader } from '../../../../../components/loader';
import { SiteSubscriptionReportsDataGridCols } from './site-subscriptions-data-grid-cols';
import { DynamicForm } from '../../../../../components/form-builder/form';
import { GET_USERS } from '../../../../../app/services/users.service';
import { GET_SUBSCRIPTIONS } from '../../../../../app/services/subscriptions.service';
import * as XLSX from 'xlsx';

export const SiteSubscriptionsReports = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);
    const params = useParams();
    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
    });
    const navigate = useNavigate();

    const userRole = sessionStorage?.getItem("user_role");
    const userUuid = sessionStorage?.getItem("user_uuid");
    const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
        {
            label: "Site Reports",
            value: "Site Reports",
            routerLink: "/dashboard/reports/site-reports",
            isActive: false,
        },
        {
            label: "Site Report Name",
            value: "Site Subscriptions Report",
            routerLink: `/dashboard/reports/site-reports`,
            isActive: false,
        },
        {
            label: "Site Subscriptions Report",
            value: "Site Subscriptions Report",
            routerLink: `/dashboard/reports/site-reports/${params?.site_report_uuid}/subscriptions`,
            isActive: true,
        },
    ]);
    const [reportsSearchForm, setReportsSearchForm] = useState<any>([
        {
            title: "dropdown",
            type: "text",
            label: "Customers",
            name: "customer_uuid",
            option: [],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select customer",
            error: "Please select customer",
            hideMandatoryStar: true,
            isParentDropDown: false,
            isApiCall: false,
        },
        {
            title: "dropdown",
            type: "text",
            label: "Status",
            name: "status",
            option: [
                { label: "Success", value: "SUCCESS" },
                { label: "Pending", value: "PENDING" },
            ],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select status",
            error: "Please select status",
            hideMandatoryStar: true,
        },
        {
            title: "dropdown",
            type: "text",
            label: "Subscriptions",
            name: "subscription_uuid",
            option: [],
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select subscription",
            error: "Please select subscription",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "date",
            label: "From Date",
            name: "from_dt",
            formFloating: false,
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select from date",
            error: "Please select from date",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "date",
            label: "To Date",
            name: "to_dt",
            formFloating: false,
            value: "",
            regex: "",
            required: true,
            placeholder: "Please select to date",
            error: "Please select to date",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-4 d-flex justify-content-start mt-3 pt-3",
            buttons: [
                {
                    title: 'Search',
                    action: 'add',
                    class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 border-0 mt-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-1'
                }
            ]
        },
    ]);

    useEffect(() => {
        if (userRole === "CUSTOMER") {
            const updatedForm = reportsSearchForm;
            updatedForm.splice(0, 1); // Remove the first element
            setReportsSearchForm([...updatedForm]);
        }
    }, [userRole]);

    useEffect(() => {
        if (params?.site_report_uuid) {
            getSitesReports(params?.site_report_uuid);
        }
        getSiteSubscriptions(pageCount);
        getCustomers();
        getSubscriptions();
    }, [])

    const getCustomers = () => {
        setLoading(true);
        GET_USERS({ limit: 1000, page: 0 }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const customers = [];
                    for (let i = 0; i < response?.length; i++) {
                        const element = response[i];
                        customers.push({ label: element?.name, value: element?.uuid })
                    }
                    const data = reportsSearchForm
                    if (userRole === 'SUPERADMIN') {
                        data[0].option = customers
                    }
                    setReportsSearchForm([...data])
                    setLoading(false);
                }
            }
        });
    }

    const getSubscriptions = () => {
        setLoading(true);
        GET_SUBSCRIPTIONS({ limit: 1000, page: 0, }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows;
                    const subscriptionsData = [];
                    for (let i = 0; i < response?.length; i++) {
                        const element = response[i];
                        subscriptionsData.push({ label: element?.plan_name, value: element?.uuid })
                    }
                    const data = reportsSearchForm
                    if (userRole === "SUPERADMIN") {
                        data[2].option = subscriptionsData
                    } else if (userRole === "CUSTOMER") {
                        data[1].option = subscriptionsData
                    }
                    setReportsSearchForm([...data])
                    setLoading(false);
                }
            }
        });
    }

    const getSitesReports = (siteReportUuid: any) => {
        setLoading(true);
        GET_SITES_REPORTS({ uuid: siteReportUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows[0]
                    const breadCrumbData = breadcrumbInfo;
                    breadCrumbData[1].value = response?.site_name
                    setBreadcrumbInfo([...breadCrumbData])
                    setLoading(false);
                }
            }
        });
    }

    const getSiteSubscriptions = (pageData: any) => {
        setLoading(true);
        GET_SITE_SUBSCRIPTION_REPORTS(params?.site_report_uuid, pageData).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    res?.data?.rows?.map((element: any) => {
                        element.payment_date_time = element?.payment_date + " " + element?.time?.substring(0, 5)
                    })
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const onActions = (data: any) => { }

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getSiteSubscriptions(pageData);
    };


    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getSiteSubscriptions(pageData);
    };

    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const pageData = pageCount;
            pageData.page = 0
            const formDataObj = { ...pageData, ...data?.formData };
            delete formDataObj['undefined'];
            for (var propName in formDataObj) {
                if (!formDataObj[propName]) {
                    delete formDataObj[propName];
                }
            }
            const searchData = formDataObj
            searchData.page = 0
            setPageCount({ ...searchData })
            getSiteSubscriptions(formDataObj)
        } else if (data?.type === "cancel") {
            const formData = reportsSearchForm;
            formData?.forEach((element: any) => {
                element.value = ""
            })
            setReportsSearchForm([...formData]);
            const pageData = {
                limit: 10,
                page: 0,
            }
            getSiteSubscriptions(pageData);
        }
    }

    const onExportData = () => {
        setLoading(true);
        GET_SITE_SUBSCRIPTION_REPORTS(params?.site_report_uuid, { limit: 1000, page: 0 }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows
                    const userData: any = [];
                    response.forEach((element: any) => {
                        userData.push(
                            {
                                "Payment Date & Time": element?.payment_date + " " + element?.time?.substring(0, 5),
                                "User": element?.user,
                                "Subscription Type": element?.subscription_type,
                                "Total Subscription Amount": element?.amount,
                                "Status": element?.payment_status,
                                "Start Date": element?.start_date,
                                "Expiry Date": element?.expiry_date,
                                "Subscription Mode": element?.recurring,
                                "Country": element?.country,
                                "Client IP": element?.client_ip,
                                "Ampere Amount": element?.ampere_amount,
                                "Payment Partner Amount": element?.pp_amount,
                            }
                        );
                    })
                    const worksheet = XLSX.utils.json_to_sheet(userData);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
                    XLSX.writeFile(workbook, `site_subscriptions_reports.xlsx`);
                    setLoading(false);
                    setLoading(false);
                }
            }
        });
    }

    return (
        <div>
            <div className="sub-header-primary-color py-2">
                <div className="h-25 ms-2 text-white border-start border-3 border-success">
                    <div className="text-start ps-3">Site Reports</div>
                </div>
            </div>
            <div className='col-12 my-3 ms-2'>
                <BreadCrumb breadCrumbList={breadcrumbInfo} />
            </div>
            <div className="row">
                <DynamicForm
                    userFormData={reportsSearchForm}
                    columns={3}
                    cancelRoute={""}
                    isEdit={false}
                    custom_button_text={"Login"}
                    onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                    customClass={"display-block"}
                    loading={""}
                    isNotEmptyFormAfterSubmit={true}
                />
            </div>
            <div className='row pb-3'>
                <div className="co-md-12 card-header text-start">
                    <button className="float-end btn btn-primary py-1 border-0 me-2" onClick={onExportData}>
                        {" "}
                        Export <i className="bi bi-cloud-arrow-up"></i>
                    </button>
                </div>
            </div>
            <div className='col-md-12'>
                <div>
                    <Loader LoadingReducer={loading} />
                    <DataTable
                        tableData={tableListData}
                        TableCols={SiteSubscriptionReportsDataGridCols}
                        onActions={onActions}
                        onPageChange={onPageChange}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
            </div>
        </div>
    )
}
