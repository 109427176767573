import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { DashboardHome } from '../pages/dashboard/home'
import { Customers } from '../pages/dashboard/customers/customers'
import { Content } from '../pages/dashboard/content/content'
import { PaymentPartners } from '../pages/dashboard/payment-partners/payment-partners'
import { CustomerSubscriptionsData } from '../pages/dashboard/customer/customer-subscription';
import { CustomerTabs } from '../pages/dashboard/customer/reports/reportstab';
import { Sites } from '../pages/dashboard/customer/sites';
import { CustomersTabInfo } from '../pages/dashboard/customers/customers-tabs/customers-tab-info'
import { PaymentPartnerCreationForm } from '../pages/dashboard/payment-partners/payment-parnter-creation-form'
import { APIIntegrations } from '../pages/dashboard/api-integrations/integration'
import { CustomerSites } from '../pages/dashboard/customers/customers-tabs/sites/customer-sites'
import { SitesTabInfo } from '../pages/dashboard/customers/customers-tabs/sites/site-tabs-info'
import { CustomerSitesTabInfo } from '../pages/dashboard/customer/sites/sites-tabs'
import { SettingsTabsInfo } from '../pages/dashboard/settings/settings-tabs-info'
import { PaymentPartnersForm } from '../pages/dashboard/payment-partners/payment-partners-form'
import { ToolsPage } from '../pages/dashboard/tools'
import { SiteCreationForm } from '../pages/dashboard/customer/sites/site-creation-form'
import { ContactUsPage } from '../pages/dashboard/contact-us/contact-us'
import { ContactUsViewPage } from '../pages/dashboard/contact-us/contact-us-view'

export const DashboardRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<DashboardHome />}></Route>
            <Route path="/view" element={<DashboardHome />}></Route>
            <Route path="/customers" element={<Customers />}></Route>
            <Route path="/customers/:customer_uuid/sites" element={<Sites />}></Route>
            <Route path="/customers/:customer_uuid/sites/form" element={<SiteCreationForm />}></Route>
            <Route path="/customers/:customer_uuid/sites/form/:site_form_uuid" element={<SiteCreationForm />}></Route>
            <Route path="/customers/:customer_uuid/sites/:site_uuid/*" element={<SitesTabInfo />}></Route>
            <Route path="/content/*" element={<Content />}></Route>
            <Route path="/payment-partners" element={<PaymentPartners />}></Route>
            <Route path="/payment-partners/:uuid" element={<PaymentPartnersForm />}></Route>
            <Route path="/payment-partner-form" element={<PaymentPartnerCreationForm />}></Route>
            <Route path="/reports/*" element={<CustomerTabs />}></Route>
            <Route path="/subscription" element={<CustomerSubscriptionsData />} ></Route>
            <Route path="/sites" element={<Sites />}></Route>
            <Route path="/sites/form" element={<SiteCreationForm />}></Route>
            <Route path="/sites/form/:site_form_uuid" element={<SiteCreationForm />}></Route>
            <Route path="/sites/:customer_site_uuid/*" element={<CustomerSitesTabInfo />}></Route>
            <Route path='/sites/subscription' element={<CustomerSitesTabInfo />}></Route>
            <Route path="/api-integrations" element={<APIIntegrations />}></Route>
            <Route path="/settings/*" element={<SettingsTabsInfo />}></Route>
            <Route path="/tools/*" element={<ToolsPage />}></Route>
            <Route path="/contact-us" element={<ContactUsPage />}></Route>
            <Route path="/contact-us/:uuid" element={<ContactUsViewPage />}></Route>
        </Routes>
    )
}