import { useNavigate, useParams } from "react-router-dom";
import { DataTable } from "../../../../components/data-table";
import { SiteGridCols } from "./siteGridCols";
import { useEffect, useState } from "react";
import { DynamicForm } from "../../../../components/form-builder/form";
import { BreadCrumb } from "../../../../components/bread-crumb";
import { GET_SITES, SITE_CREATE, SITE_DELETE, SITE_UPDATE } from "../../../../app/services/sites.service";
import toast from 'react-hot-toast';
import { Loader } from '../../../../components/loader';
import { GET_USERS } from "../../../../app/services/users.service";
import Countries from '../../../../app/utils/countries.json'
import { GET_PAYMENT_PARTNERS } from "../../../../app/services/payment-partners.service";
export const Sites = () => {
  const navigate = useNavigate();
  const params = useParams();
  const userRole = sessionStorage?.getItem("user_role");
  const navigation = useNavigate();
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>([]);
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
    customer_uuid: params?.customer_uuid ? params?.customer_uuid : sessionStorage.getItem('user_uuid')
  });
  const [createSiteFormData, setCreateSiteFormData] = useState([
    // {
    //   title: "input",
    //   type: "text",
    //   label: "Service Id",
    //   name: "service_id",
    //   formFloating: false,
    //   value: "",
    //   regex: "",
    //   required: true,
    //   placeholder: "Please enter service id",
    //   error: "Please enter service id",
    //   hideMandatoryStar: false,
    // },
    {
      title: "input",
      type: "text",
      label: "Name",
      name: "code",
      formFloating: false,
      value: "",
      required: true,
      regex: /^([A-Z]_*)+$/,
      placeholder: "Please enter name",
      error: "Please enter name",
      hideMandatoryStar: false,
    },
    {
      title: "multiDropdown",
      type: "text",
      label: "Payment Partners",
      name: "payment_partners",
      formFloating: false,
      value: null,
      option: [],
      regex: "",
      required: false,
      placeholder: "Select payment partners",
      error: "Please select payment partners",
      hideMandatoryStar: false,
      isParentDropdown: true
    },
    {
      title: "dropdown",
      type: "text",
      label: "Country",
      name: "country",
      formFloating: false,
      value: null,
      option: Countries,
      regex: "",
      required: false,
      placeholder: "Please select country",
      error: "Please select country",
      hideMandatoryStar: false,
    },
    {
      title: "dropdown",
      type: "text",
      label: "Timezone",
      name: "timezone",
      formFloating: false,
      value: null,
      regex: "",
      option: [],
      required: false,
      placeholder: "Please select timezone",
      error: "Please select timezone",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "text",
      label: "Website URL",
      name: "site_url",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "Please enter website URL",
      error: "Please enter website URL",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "text",
      label: "Contact Email",
      name: "contact_email",
      formFloating: false,
      value: null,
      regex: "",
      required: false,
      placeholder: "Please enter contact email",
      error: "Please enter contact email",
      hideMandatoryStar: false,
    },
    {
      title: "input",
      type: "text",
      label: "Contact Mobile",
      name: "contact_mobile",
      formFloating: false,
      value: null,
      regex: "",
      required: false,
      placeholder: "Please enter contact mobile",
      error: "Please enter contact mobile",
      hideMandatoryStar: false,
    },
    {
      title: "file",
      type: "file",
      label: "Logo Image",
      name: "logo_url",
      value: null,
      required: false,
      regex: "",
      maxLength: "",
      isMultipleImages: false,
      placeholder: "Upload logo",
      error: "Please upload logo",
      hideMandatoryStar: true
    },
    {
      title: "file",
      type: "file",
      label: "favicon Image",
      name: "favicon_url",
      value: null,
      required: false,
      regex: "",
      maxLength: "",
      isMultipleImages: false,
      placeholder: "Upload favicon",
      error: "Please upload favicon",
      hideMandatoryStar: true
    },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-end my-2",
      buttons: [
        {
          title: "Create",
          action: "add",
          isCheckFormValidation: true,
          class: "btn btn-primary px-4 btn-sm f16 me-1 py-2 border-0",
        },
        {
          title: "Cancel",
          action: "cancel",
          class: "btn btn-outline-primary px-4 btn-sm f16",
        },
      ],
    },
  ]);

  const [searchFormData, setSearchFormData] = useState([
    {
      title: "input",
      type: "text",
      label: "Search",
      name: "search",
      formFloating: false,
      value: "",
      regex: "",
      placeholder: "Please enter search",
      error: "Please enter search",
      hideMandatoryStar: false,
    },
    {
      title: "dropdown",
      type: "text",
      label: "Country",
      name: "country",
      formFloating: false,
      value: "",
      option: Countries,
      regex: "",
      placeholder: "select country",
      error: "Please select country",
      hideMandatoryStar: false,
    },
    {
      type: "button",
      class: "col-md-3 d-flex justify-content-start my-2 mt-3 pt-1",
      buttons: [
        {
          title: "Search",
          action: "add",
          isCheckFormValidation: true,
          class: "btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-3",
        },
        {
          title: "Cancel",
          action: "cancel",
          class: "btn btn-outline-primary px-4 btn-sm f16 height_30 mt-3",
        },
      ],
    },
  ]);

  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
      label: "Customers",
      value: "Customers",
      routerLink: "/dashboard/customers",
      isActive: false,
    },
    {
      label: "Customer Name",
      value: "Customer",
      routerLink: `/dashboard/customers/${params?.customer_uuid}/sites`,
      isActive: false,
    },
    {
      label: "Customer Name",
      value: "Sites",
      routerLink: `/dashboard/customers/${params?.customer_uuid}/sites`,
      isActive: true,
    },
  ]);

  const [loading, setLoading] = useState<boolean>(false);
  // const [tableListData, setTableListData] = useState<any>({rows:dummyData, total:10});
  const [tableListData, setTableListData] = useState<any>();


  useEffect(() => {
    getSites(pageCount);
    getPaymentPartnersData();
    // const timeZoneData = TimeZones;
    // const result = Object.values(timeZoneData).map(country => ({
    //   name: country.name,
    //   timezones: Object.keys(country.timezones).map(tz => ({ label: tz, value: tz }))
    // }));
    // const timezonesOptions = result.flatMap(country => country.timezones);
    // const siteFormData = createSiteFormData;
    // siteFormData[3].option = timezonesOptions
    // setCreateSiteFormData([...siteFormData])
  }, [])

  const getSites = (data: any) => {
    setLoading(true);
    GET_SITES(data).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          setTableListData(res?.data);
          setLoading(false);
        }
      }
    });
  }
  useEffect(() => {
    if (userRole === "SUPERADMIN") {
      const customerId = params?.customer_uuid ? params?.customer_uuid : sessionStorage.getItem('user_uuid');
      getCustomerDataById(customerId);
    }
  }, [])

  const getPaymentPartnersData = () => {
    setLoading(true);
    GET_PAYMENT_PARTNERS({ limit: 1000, page: 0 }).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          const response = res?.data?.rows;
          response?.forEach((el: any) => (
            el.label = el?.code,
            el.value = el?.uuid
          ))
          const siteFormData = createSiteFormData
          siteFormData[1].option = response
          setCreateSiteFormData([...siteFormData])
          setLoading(false);
        }
      }
    });
  }


  const createSites = () => {
    // navigate(`/dashboard/customers/${params?.customer_uuid}/sites/form`)
    if (userRole === "SUPERADMIN") {
      navigation(`/dashboard/customers/${params?.customer_uuid}/sites/form`)
    } else if (userRole === "CUSTOMER") {
      navigation(`/dashboard/sites/form`)
    }
  };

  const getCustomerDataById = (userUuid: any) => {
    setLoading(true);
    GET_USERS({ uuid: userUuid }).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          const data = breadcrumbInfo;
          data[1].value = res?.data?.rows[0]?.name;
          setBreadcrumbInfo([...data]);
          setLoading(false);
        }
      }
    });
  }

  const onRowClickEvent = (e: any) => {
    if (e.type === "plan") {
      navigate(`/dashboard/sites/subscription`);
    }
  };

  const onActions = (data: any) => {
    setSelectedData(data?.data)
    if (data?.type === "Delete") {
      setShowDeletePopup(true);
    }
    else if (data?.type === "subscription_plan") {
      navigate(`/dashboard/sites/subscription`);
    } else if (data?.type === "link") {
      if (userRole === "SUPERADMIN") {
        navigate(`/dashboard/customers/${params?.customer_uuid}/sites/${data?.data?.uuid}`)
      } else {
        navigate(`/dashboard/sites/${data?.data?.uuid}`);
      }
    } else if (data?.type === "Edit") {
      setIsEdit(true);
      // setShowCreatePopup(true);
      if (userRole === "SUPERADMIN") {
        navigation(`/dashboard/customers/${params?.customer_uuid}/sites/form/${data?.data?.uuid}`)
      } else if (userRole === "CUSTOMER") {
        navigation(`/dashboard/sites/form/${data?.data?.uuid}`)
      }
      const formDataObj = createSiteFormData;
      formDataObj?.forEach((element: any) => {
        if (element?.type === "button") {
          for (let i = 0; i < element?.buttons?.length; i++) {
            const ele = element?.buttons;
            ele[0].title = "Update";
            ele[0].action = "edit";
          }
        }
        element.value = data?.data[element.name]
      });
      setCreateSiteFormData([...formDataObj]);
    }
  };

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getSites(pageData);
  };

  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getSites(pageData);
  };
  const onDeleteUserConfirmation = () => {
    setLoading(true);
    SITE_DELETE(selectedData?.uuid).then((res: any) => {
      if (res?.status === "fail") {
        toast.error(res.error, { position: 'top-right', })
        setLoading(false);
      } else {
        if (res) {
          toast.success("Site Deleted Successfully ", { position: 'top-right', })
          setLoading(false);
          setShowDeletePopup(false);
          getSites(pageCount)
        }
      }
    });
  };
  const onSearchFormSubmit = (event: any) => {
    const data = event;
    if (data?.type === "add") {
      const pageData = pageCount;

      const formDataObj = { ...pageData, ...data?.formData };
      delete formDataObj['undefined'];
      for (var propName in formDataObj) {
        if (!formDataObj[propName]) {
          delete formDataObj[propName];
        }
      }
      const searchData = formDataObj
      searchData.page = 0
      setPageCount({ ...searchData });
      getSites(searchData)
    } else if (data?.type === "cancel") {
      const formData = searchFormData;
      formData?.forEach((element: any) => {
        element.value = ""
      })
      const pageData = {
        limit: 10,
        page: 0,
        customer_uuid: params?.customer_uuid ? params?.customer_uuid : sessionStorage.getItem('user_uuid')
      }

      setSearchFormData([...formData]);
      setPageCount({ ...pageData });
      getSites(pageData);
    }
  }

  const onFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
      const formData = event?.formData;
      formData.customer_uuid = params?.customer_uuid ? params?.customer_uuid : sessionStorage.getItem('user_uuid');
      formData.license_start_dt = null;
      formData.license_expiry_dt = null;
      formData.default_language = 'ENGLISH';
      formData.metadata = {};
      formData.payment_partners = formData.payment_partners ? formData.payment_partners : [];
      delete formData['undefined'];
      setLoading(true);
      SITE_CREATE(formData).then((res: any) => {
        if (res?.status === "fail") {
          setLoading(false);
          toast.error(res.error, { position: "top-right" });

        } else {
          if (res) {
            toast.success(res.message, { position: 'top-right' })
            setLoading(false);
            getSites(pageCount);
            setShowCreatePopup(false);
          }
        }
      });
    } else if (data?.type === "edit") {
      const formData = data?.formData
      setLoading(true);
      SITE_UPDATE(selectedData?.uuid, formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error, { position: "top-right" });
          setLoading(false);
        } else {
          if (res) {
            setShowCreatePopup(false);
            toast.success(res.message, { position: 'top-right' })
            setLoading(false);
            getSites(pageCount);
          }
        }
      });
    }
    else if (data?.type === "cancel") {
      setShowCreatePopup(false);
    }
  }

  return (
    <div className="m-2">
      <div className="container-fluid content-bg-color box-shadow">
        {userRole !== "CUSTOMER" && (
          <div className="row">
            <div className='col-md-12 px-4'>
              <BreadCrumb breadCrumbList={breadcrumbInfo}></BreadCrumb>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-between alignItems">
          <h4 className="mb-0">Sites</h4>
          <div>
            <button
              className="btn btn-primary border-0"
              onClick={() => createSites()}
            >
              Create Site
            </button>
          </div>
        </div>
        <hr className="my-2" />
        <div className="row mt-2">
          <DynamicForm
            userFormData={searchFormData}
            columns={4}
            isEdit={false}
            onFormSubmitEvent={(e) => onSearchFormSubmit(e)}
            customClass={"display-block"}
            isNotEmptyFormAfterSubmit={true}
            cancelRoute={undefined}
          />
        </div>
        {/* <div className="row">
          <div className="col-3">
            <input
              className="form-control"
              type="text"
              placeholder="Enter Website Url"
            />
          </div>
          <div className="col-3">
            <input
              className="form-control"
              type="text"
              placeholder="Enter Website Name"
            />
          </div>
        </div> */}
        <div className="text-end">
          <div className="my-3">
            <Loader LoadingReducer={loading} />
            <DataTable
              tableData={tableListData}
              TableCols={SiteGridCols}
              onActions={onActions}
              onPageChange={onPageChange}
              onSelectPageChange={(e) => onSelectPage(e)}
              pageCount={pageCount}
            />
          </div>
        </div>
        {showCreatePopup && (
          <div
            className={`modal modal - custom fade ${showCreatePopup ? "show" : "false"
              }  ${showCreatePopup ? "modal-show" : "modal-hide"}`}
            tab-index="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-header border-bottom-primary mx-4 px-0">
                  <h1
                    className="modal-title fs-5 fw-light"
                    id="exampleModalLabel"
                  >
                    <strong className="text-primary-medium fw-bold">
                      {isEdit ? "Edit" : "Create"} Site
                    </strong>
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => setShowCreatePopup(false)}
                  ></button>
                </div>
                <div className="modal-body px-4 text-start">
                  <div className="row">
                    <DynamicForm
                      userFormData={createSiteFormData}
                      columns={2}
                      cancelRoute={""}
                      isEdit={false}
                      custom_button_text={"Login"}
                      onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                      // onParentDropdownEvent={(e) => onMultiSelectDropdown(e)}
                      customClass={"display-block"}
                      loading={""}
                      isNotEmptyFormAfterSubmit={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showDeletePopup && (
          <>
            <Loader LoadingReducer={loading} />
            <div
              className={`modal modal - custom fade ${showDeletePopup ? "show" : "false"
                }  ${showDeletePopup ? "modal-show" : "modal-hide"}`}
              tab-index="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-start">
                  <div className="modal-header border-bottom-primary mx-4 px-0">
                    <h1
                      className="modal-title fs-5 fw-light"
                      id="exampleModalLabel"
                    >
                      <strong className="text-primary-medium fw-bold">
                        Delete
                      </strong>
                    </h1>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setShowDeletePopup(false)}
                    ></button>
                  </div>
                  <div className="modal-body pb-5">
                    <div className="row px-2">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <p className="f16 p-2">
                            Are you sure you want to delete{" "}
                            <span className="fw-bold ">
                              {selectedData?.code || ""}
                            </span>{" "}
                            ?
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="px-2 text-end">
                      <button
                        type="button"
                        className="btn btn-primary px-4 btn-sm f16 me-3"
                        onClick={onDeleteUserConfirmation}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium"
                        onClick={() => setShowDeletePopup(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
