import React, { useEffect, useState } from 'react'
import { DataTable } from '../../../../../../components/data-table'
import { IntegrationsDataGridCols } from './integrations-data-grid-cols'
import { DynamicForm } from '../../../../../../components/form-builder/form';

export const Integrations = () => {
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
    });

    const integrationsData = [
        {
            gateway_name: "MyAPIGateway",
            api_key: "AKIAIOSFODNN7EXAMPLE",
            secret_key: "wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY"
        },
        {
            gateway_name: "MyAzureAPIGateway",
            api_key: "ZXCVBNMASDFGHJKLQWERTYUIOP",
            secret_key: "123456789abcdef123456789abcdef12"
        },
    ]

    const [formData, setFormData] = useState<any>([
        {
            title: "input",
            
            type: "text",
            label: "Gateway Name",
            name: "gateway_name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter gateway name",
            error: "Please enter gateway name",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            
            type: "text",
            label: "API Key",
            name: "api_key",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter api key",
            error: "Please enter api key",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            
            type: "text",
            label: "Secret Key",
            name: "secret_key",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter secret key",
            error: "Please enter secret key",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])

    const tableData = {
        total_count: 10,
        rows: integrationsData
    }

    const [searchFormData, setSearchFormData] = useState([
        {
            title: "input",
            
            type: "text",
            label: "Search",
            name: "search",
            formFloating: false,
            value: '',
            regex: "",
            placeholder: "Please enter search",
            error: "Please enter search",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            class: "col-md-3 d-flex justify-content-start my-2 mt-3 pt-1",
            buttons: [
                {
                    title: 'Search',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-3'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-3'
                }
            ]
        },
    ])
    const onActions = (data: any) => {
        if (data?.type === "Delete") {
            setShowDeletePopup(true);
        }
    }
    const onPageChange = (data: any) => { }
    const onSelectPage = (data: any) => { }

    const onSearchFormSubmit = (event: any) => { }

    const onDeleteUserConfirmation = (event: any) => { }

    const onIntegrationFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            setShowCreatePopup(false)
        } else if (data?.type === "cancel") {
            setShowCreatePopup(false)
        }
    }

    const onShowCreatePopup = () => {
        setShowCreatePopup(true)
    }

    return (
        <div className='col-md-12'>
            <div className='row mb-3'>
                <div className='col-md-8'>
                    <div className='mt-2'>
                        <DynamicForm
                            userFormData={searchFormData}
                            columns={4}
                            isEdit={false}
                            onFormSubmitEvent={(e) => onSearchFormSubmit(e)}
                            customClass={"display-block"}
                            isNotEmptyFormAfterSubmit={true} cancelRoute={undefined}
                        />
                    </div>
                </div>
                <div className='col-md-4 mt-4 pt-3 text-end'>
                    <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create</button>
                </div>
            </div>
            <div>
                <DataTable
                    tableData={tableData}
                    TableCols={IntegrationsDataGridCols}
                    onActions={(e: any) => onActions(e)}
                    onPageChange={(e: any) => onPageChange(e)}
                    onSelectPageChange={(e) => onSelectPage(e)}
                    pageCount={pageCount}
                />
            </div>
            {showCreatePopup && (
                <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header border-bottom-primary mx-4 px-0">
                                <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Integration</strong></h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                            </div>
                            <div className="modal-body px-4 text-start">
                                <div className='row'>
                                    <DynamicForm
                                        userFormData={formData}
                                        columns={2}
                                        isEdit={isEdit}
                                        onFormSubmitEvent={(e) => onIntegrationFormSubmitEvent(e)}
                                        customClass={"display-block"}
                                        isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showDeletePopup && (
                <>
                    <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content text-start">
                                <div className="modal-header border-bottom-primary mx-4 px-0">
                                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                                </div>
                                <div className="modal-body pb-5">
                                    <div className="row px-2">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.firstname || selectedData?.name || ""}</span> ?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='px-2 text-end'>
                                        <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                                        <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
