import React from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import { SettingsTabRoutes } from '../../../routes/settings-tab-routes';

export const SettingsTabsInfo = () => {
    const location = useLocation();
    const currentPath:any = location.pathname.split("/");

    const tabs = [
        {
            id: "1",
            name: "Categories",
            link: `/dashboard/settings/categories`,
        },
        {
            id: "2",
            name: "Payment Types",
            link: `/dashboard/settings/payment-types`,
        },
        {
            id: "3",
            name: "Deployment Versions",
            link: `/dashboard/settings/deployment-versions`,
        },
        {
            id: "4",
            name: "System Params",
            link: `/dashboard/settings/system-params`,
        },
    ]

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Settings</h4>
                </div>
                <div className='col-md-3 text-end my-2 px-0'>
                    {/* <button className='btn btn-primary border-0' onClick={onShowCreateContentPopup}>Create Content</button> */}
                </div>
            </div>
            <div className='row my-3'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    {tabs.map((data: any, index: number) => {
                        return <NavLink key={index} to={data?.link} className={`nav-link text-dark px-4 fs-13
                    ${currentPath.length === 3 && data?.name === "Categories"
                                ? "active"
                                : ""
                            }`}>{data?.name}</NavLink>
                    })}
                </ul>
                <div className="tab-content shadow" id="myTabContent">
                    <SettingsTabRoutes />
                </div>
            </div >
        </div>
    )
}