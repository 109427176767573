import API from "../utils/axios";
let common = "/v1/api";

export const GET_DASHBOARD_STATISTICS = (data: any): Promise<any> => {
    return API.get(`${common}/dashboard/statistics`, { params: data });
};



export const GET_DASHBOARD_CHARTS = (data: any): Promise<any> => {
    return API.get(`${common}/dashboard/charts`, { params: data });
};