import API from "../utils/axios";
let common = "/v1/api";

export const GET_USERS = (data: any): Promise<any> => {
  return API.get(`${common}/users`, { params: data });
};

export const USER_CREATE = (data: any): Promise<any> => {
  return API.post(`${common}/users`, data);
};

export const USER_UPDATE = (id: any, data: any): Promise<any> => {
  return API.put(`${common}/users?user_uuid=${id}`,data);
};

export const USER_DELETE = (data: any): Promise<any> => {
  return API.delete(`${common}/users`,{params:data});
};
