import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { DataTable } from '../../../../components/data-table';
import { DynamicForm } from '../../../../components/form-builder/form';
import { Search } from '../../../../components/search/search';
import { PaymentTypeDataGridCols } from './payment-type-data-grid-cols';
import { CREATE_PAYMENT_TYPE, DELETE_PAYMENT_TYPE, GET_PAYMENT_TYPES, UPDATE_PAYMENT_TYPE } from '../../../../app/services/payment-type.service';
import toast from 'react-hot-toast';
import { Loader } from '../../../../components/loader';

export const PaymentTypes = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tableListData, setTableListData] = useState<any>([]);
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>([]);

  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 0,
  });

  const [formData, setFormData] = useState<any>([
    {
      title: "input",
      
      type: "text",
      label: "Name",
      name: "name",
      formFloating: false,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please enter name",
      error: "Please enter name",
      hideMandatoryStar: false,
    },
    {
      title: "textarea",
      
      type: "text",
      label: "Notes",
      name: "notes",
      formFloating: false,
      value: '',
      required: false,
      placeholder: "Please enter notes",
      error: "Please enter notes",
      hideMandatoryStar: false,
    },
    {
      type: "button",
      class: "col-md-12 d-flex justify-content-end my-2",
      buttons: [
        {
          title: 'Create',
          action: 'add',
          isCheckFormValidation: true,
          class: 'btn btn-primary px-4 btn-sm f16 me-1'
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16'
        }
      ]
    },
  ])

  useEffect(() => {
    getCategories(pageCount);
  }, [])


  const getCategories = (data: any) => {
    setLoading(true);
    GET_PAYMENT_TYPES(data).then((res: any) => {
      if (res?.status === "fail") {
        setLoading(false);
      } else {
        if (res) {
          setTableListData(res?.data);
          setLoading(false);
        }
      }
    });
  }


  const onActions = (data: any) => {
    setSelectedData(data?.data)
    if (data?.type === "Edit") {
      setIsEdit(true);
      const categoryFormData = formData;
      categoryFormData?.forEach((element: any) => {
        if (element?.type === "button") {
          for (let i = 0; i < element?.buttons?.length; i++) {
            const ele = element?.buttons;
            ele[0].title = "Update";
            ele[0].action = "edit";
          }
        }
        element.value = data?.data[element.name]
      });
      setFormData([...categoryFormData]);
      setShowCreatePopup(true);
    } else if (data?.type === "Delete") {
      setShowDeletePopup(true);
    }
  }

  const onPageChange = (data: any) => {
    const pageData = pageCount;
    pageData.limit = data;
    setPageCount(pageData);
    getCategories(pageData);
  };
  const onSelectPage = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getCategories(pageData);
  };

  const onDeleteUserConfirmation = () => {
    setLoading(true);
    DELETE_PAYMENT_TYPE(selectedData?.uuid).then((res: any) => {
      if (res?.status === "fail") {
        toast.error(res.error, { position: 'top-right', })
        setLoading(false);
      } else {
        if (res) {
          toast.success(res.message, { position: 'top-right', })
          setLoading(false);
          setShowDeletePopup(false);
          getCategories(pageCount)
        }
      }
    });
  }
  const onShowCreatePopup = () => {
    setShowCreatePopup(true);
    setIsEdit(false);
    const categoryFormData = formData;
    categoryFormData?.forEach((element: any) => {
      if (element?.type === "button") {
        for (let i = 0; i < element?.buttons?.length; i++) {
          const ele = element?.buttons;
          ele[0].title = "Create";
          ele[0].action = "add";
        }
      }
      element.value = ""
    })
    setFormData([...formData])
  }
  const onFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
      const formData = data?.formData;
      formData.metadata = {}
      setLoading(true);
      CREATE_PAYMENT_TYPE(formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error, { position: "top-right" });
          setLoading(false);
        } else {
          if (res) {
            setShowCreatePopup(false);
            toast.success(res.message, { duration: 2000, position: 'top-right', })
            setLoading(false);
            getCategories(pageCount);
          }
        }
      });
    } else if (data?.type === "edit") {
      const formData = data?.formData
      setLoading(true);
      UPDATE_PAYMENT_TYPE(selectedData?.uuid, formData).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.error, { position: "top-right" });
          setLoading(false);
        } else {
          if (res) {
            setShowCreatePopup(false);
            toast.success(res.message, { position: 'top-right' })
            setLoading(false);
            getCategories(pageCount);
          }
        }
      });
    } else if (data?.type === "cancel") {
      setShowCreatePopup(false);
    }
  }

  const onSearch = (value: any) => {
    const pageData = pageCount;
    pageData.search = value;
    setPageCount(pageData);
    getCategories(pageData);
  }

  return (
    <div className='container-fluid content-bg-color'>
      <div className='row'>
        <div className='col-md-8 '>
          <div className='w-100'>
            <Search onSearch={(e: any) => onSearch(e)} />
          </div>
        </div>
        <div className='col-md-4 text-end'>
          <button className='btn btn-primary border-0' onClick={onShowCreatePopup}>Create Payment Type</button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12 px-0'>
          <Loader LoadingReducer={loading} />
          <DataTable
            tableData={tableListData}
            TableCols={PaymentTypeDataGridCols}
            onActions={(e: any) => onActions(e)}
            onPageChange={(e: any) => onPageChange(e)}
            onSelectPageChange={(e) => onSelectPage(e)}
            pageCount={pageCount}
          />
        </div>
        {showCreatePopup && (
          <div className={`modal modal-custom fade ${showCreatePopup ? "show" : "false"}  ${showCreatePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
            <Loader LoadingReducer={loading} />
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-bottom-primary mx-4 px-0">
                  <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{isEdit ? "Edit" : "Create"} Payment Type</strong></h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowCreatePopup(false)}></button>
                </div>
                <div className="modal-body px-4 text-start">
                  <div className='row'>
                    <DynamicForm
                      userFormData={formData}
                      columns={2}
                      isEdit={isEdit}
                      onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                      customClass={"display-block"}
                      isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showDeletePopup && (
          <>
            <div className={`modal modal-custom fade ${showDeletePopup ? "show" : "false"}  ${showDeletePopup ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
              <Loader LoadingReducer={loading} />
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-start">
                  <div className="modal-header border-bottom-primary mx-4 px-0">
                    <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">Delete</strong></h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowDeletePopup(false)}></button>
                  </div>
                  <div className="modal-body pb-5">
                    <div className="row px-2">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <p className="f16 p-2">Are you sure you want to delete <span className='fw-bold '>{selectedData?.firstname || selectedData?.name || ""}</span> ?</p>
                        </div>
                      </div>
                    </div>
                    <div className='px-2 text-end'>
                      <button type="button" className="btn btn-primary px-4 btn-sm f16 me-3" onClick={onDeleteUserConfirmation}>Yes</button>
                      <button type="button" className="btn btn-outline-primary px-4 btn-sm f16 text-primary-medium border-primary-medium" onClick={() => setShowDeletePopup(false)}>No</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}