import API from "../utils/axios";
import CountryCodes from '../utils/mobile-number-country-code.json';
let common = "/v1/api";
let s3_Url: any;

export const LOGIN = (data: any): Promise<any> => {
  return API.post(`/v1/auth/login/user`, data);
};

export const GET_PRESIGNED_URL = (data: any): Promise<any> => {
  return API.post(
    `${common}/s3/presigned/upload/urls?code=STORAGE_UPLOADS`,
    data
  );
};

export const GET_APP_INFO = (): Promise<any> => {
  s3_Url = API.get(`/app/info`);
  return s3_Url;
};

export const ENCRYPT = (data: any): Promise<any> => {
  return API.post(`/app/tool/encrypt`, data);
};

export const DECRYPT = (data: any): Promise<any> => {
  return API.post(`/app/tool/decrypt`, data);
};

export const REGISTER = (data: any): Promise<any> => {
  return API.post(`/v1/auth/register/user`, data);
};

export const GET_USER_COUNTRY_MOBILE_CODE = () => {
  navigator.geolocation.getCurrentPosition(function (position) {
    var lat = position.coords.latitude;
    var lon = position.coords.longitude;

    // Use a geolocation API to get country code from lat/lon
    var geolocationAPI = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`;

    fetch(geolocationAPI)
      .then((response) => response.json())
      .then((data) => {
        var countryCode = data.countryCode;
        const locationCountry = CountryCodes.find(
          (country: any) => country?.code === countryCode
        );
        // const loginForm = loginFormData;
        // loginForm[0].value = locationCountry?.dial_code;
        // setLoginFormData([...loginForm]);
      });
  });
};


