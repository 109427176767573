import React, { useState } from 'react'
import { DynamicForm } from '../../../../components/form-builder/form';
import { ENCRYPT } from '../../../../app/services/common.service';
import { Loader } from '../../../../components/loader';

export const ToolsEncryptorPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [responseData, setResponseData] = useState<any>();

    const [form, setForm] = useState<any>([
        {
            title: "input",
            type: "text",
            label: "Secret Key",
            name: "secret_key",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter secret key",
            error: "Please enter secret key",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "vector Key",
            name: "vector_key",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter vector key",
            error: "Please enter vector key",
            hideMandatoryStar: false,
        },
        {
            title: "textarea",
            type: "textarea",
            label: "Data",
            name: "data",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter data",
            error: "Please enter data",
            hideMandatoryStar: false,
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-center my-2",
            buttons: [
                {
                    title: 'Submit',
                    action: 'Submit',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                },
            ]
        },
    ]);

    const onFormSubmit = (event: any) => {
        const data = event;
        if (data?.type === "Submit") {
            const formDataObj = data?.formData;
            delete formDataObj['undefined'];
            setLoading(true);
            ENCRYPT(formDataObj).then((res: any) => {
                if (res?.status === "fail") {
                    setLoading(false);
                } else {
                    if (res) {
                        setResponseData(res?.data);
                        setLoading(false);
                    }
                }
            });
        }
    }
    return (
        <div className='container-fluid content-bg-color'>
            <Loader LoadingReducer={loading} />

            <div className='row my-3'>
                <DynamicForm
                    userFormData={form}
                    columns={1}
                    isEdit={false}
                    onFormSubmitEvent={(e) => onFormSubmit(e)}
                    customClass={"display-block"}
                    isNotEmptyFormAfterSubmit={true} cancelRoute={undefined}
                />
            </div>
            <div>
                <h6>Result:</h6>
                <div>
                    <textarea name="" id="" className='form-control border-primary-medium' value={responseData?.data} disabled></textarea>
                </div>
                <div className='mt-3'>
                    <button className='btn btn-primary px-4 btn-sm f16 me-1' onClick={() => navigator.clipboard.writeText(responseData?.data)}>Copy Result</button>
                </div>
            </div>
        </div>
    )
}