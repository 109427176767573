import React, { useRef, useState } from "react"
import Logo from './../../assets/images/blazon-logo.png';
import GAMEBANNER from './../../assets/images/game_banner.png';
import CCPAYMENT from './../../assets/images/cc_avenu_sm.png';
import PAYPALPAYMENT from './../../assets/images/pay-pal.png';
import PAYTABPAYMENT from './../../assets/images/pay-tabs.png';
import MOLPAYPAYMENT from './../../assets/images/mol-pay.png';
import IPAYPAYMENT from './../../assets/images/ipay88.png';
import DANAPAYPAYMENT from './../../assets/images/dana.png';
import PROGRAMLAGUAGES from './../../assets/images/program_laguages.png';
import PROGRAMLOGIC from './../../assets/images/program_lg.png';
import APIINTEGRATIONS from './../../assets/images/api-integrations.png';
import INDUSTRIES from './../../assets/images/industries.png';
import SECURITYIMG from './../../assets/images/api-integrationsone.png';
import APITWOIMG from './../../assets/images/api-integrationstwo.png';
import SERVICESIMG from './../../assets/images/service-img.png';
import { DynamicForm } from "../../components/form-builder/form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CountryCodes from '../../app/utils/mobile-number-country-code.json';
import { LOGIN, REGISTER } from "../../app/services/common.service";
import { Loader } from "../../components/loader";
import '../../assets/animate/animate.min.css'
// import './lib/owlcarousel/assets/owl.carousel.min.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/style.css'
import HeaderHeroImage from './../../assets/images/ampere-site-images/header-hero-image.png';
import { Home } from "../../components/website-components/home";
import Thune from './../../assets/images/ampere-site-images/sp-thunes-sm.png';
import DLocal from './../../assets/images/ampere-site-images/sp-dlocal-sm.png';
import CodaShop from './../../assets/images/ampere-site-images/sp-codashop-sm.png';
import Boku from './../../assets/images/ampere-site-images/sp-boku-sm.png';
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";



export const WebSiteView = () => {
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [showSignUpPopup, setShowSignUpPopup] = useState<boolean>(false);
    const navigation = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const formData = [
        {
            title: "dropdown",
            type: "text",
            label: "",
            name: "mobile_country_code",
            formFloating: true,
            value: '',
            option: CountryCodes,
            regex: "",
            required: true,
            placeholder: "Code",
            error: "Please select code",
            hideMandatoryStar: true,
            column: 3,
            paddingZero: true,
            customParentclassName: "pe-0 form-floating",
            customclassName: "rounded-end-0"
        },
        {
            title: "input",
            type: "text",
            formFloating: true,
            label: "Mobile Number",
            name: "mobile_number",
            value: "",
            required: true,
            regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
            maxLength: "15",
            placeholder: "",
            error: "Please enter mobile number",
            submitButtonName: "Login",
            hideMandatoryStar: true,
            column: 9,
            paddingZero: true,
            customParentclassName: "ps-0 form-floating",
            customclassName: "rounded-start-0"
        },
        {
            title: "input",
            type: "password",
            formFloating: true,
            label: "Password",
            name: "password",
            value: "",
            required: true,
            regex: "",
            maxLength: "",
            placeholder: "",
            error: "Please enter valid password",
            customParentclassName: "form-floating",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            className: "col-md-12 ms-auto d-flex justify-content-center my-2",
            buttons: [
                {
                    title: 'Login',
                    action: 'add',
                    isCheckFormValidation: true,
                    className: 'primary-button btn d-block d-lg-inline-block mb-0 mb-lg-0 w-xs-100 rounded-1 btn border-0 btn-primary px-5 btn-sm f16 me-1 text-uppercase fw-bold py-1'
                },
            ]
        }
    ];
    const signUpFormData = [
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            formFloating: false,
            label: "Email",
            name: "email",
            value: "",
            required: true,
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{3}$/,
            maxLength: "",
            placeholder: "Please enter email",
            error: "Please enter email",
            submitButtonName: "Login",
            hideMandatoryStar: false,
        },
        {
            title: "dropdown",
            type: "text",
            label: "code",
            name: "mobile_country_code",
            formFloating: true,
            value: '',
            option: CountryCodes,
            regex: "",
            required: true,
            placeholder: "Code",
            error: "Please select code",
            hideMandatoryStar: false,
            column: 2,
            paddingZero: true,
            customParentclassName: "pe-0",
            customclassName: "rounded-end-0"
        },
        {
            title: "input",
            type: "text",
            label: "Phone Number",
            formFloating: false,
            name: "mobile_number",
            value: '',
            column: 4,
            regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
            maxLength: "15",
            required: true,
            placeholder: "Please enter phone number",
            error: "Please enter phone number",
            hideMandatoryStar: false,
            customParentclassName: "ps-0",
            customclassName: "rounded-start-0"
        },
        // {
        //     title: "input",
        //     type: "text",
        //     label: "Phone Number",
        //     formFloating: false,
        //     name: "mobile_number",
        //     value: '',
        //     maxLength: 10,
        //     regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
        //     required: true,
        //     placeholder: "Please enter phone number",
        //     error: "Please enter phone number",
        //     hideMandatoryStar: false,
        // },
        {
            title: "input",
            type: "password",
            formFloating: false,
            label: "Password",
            name: "password",
            value: "",
            required: true,
            regex: "",
            maxLength: "",
            placeholder: "Please enter  password",
            error: "Please enter valid password",
            hideMandatoryStar: false,
        },
        {
            title: "textarea",
            type: "text",
            label: "Address",
            name: "message",
            formFloating: false,
            value: '',
            regex: "",
            placeholder: "Please enter address",
            error: "Please enter address",
            hideMandatoryStar: true,
            column: '12'
        },
        // {
        //     title: "input",
        //     type: "password",
        //     formFloating: false,
        //     label: "Confirm Password",
        //     name: "confirm_password",
        //     value: "",
        //     required: true,
        //     regex: "",
        //     maxLength: "",
        //     placeholder: "Please enter confirm password",
        //     error: "Please enter valid password",
        //     hideMandatoryStar: true,
        // },
        {
            type: "button",
            className: "col-md-12 ms-auto d-flex justify-content-center my-2",
            buttons: [
                {
                    title: 'Sign Up',
                    action: 'add',
                    isCheckFormValidation: true,
                    className: 'sign_btn btn-sm py-1 text-white text-uppercase fw-bold ms-2 me-4 px-5 f14'
                },
            ]
        }
    ];
    const contactFormData = [
        {
            title: "input",
            type: "text",
            label: "Name",
            name: "name",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter name",
            error: "Please enter name",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "text",
            formFloating: false,
            label: "Email",
            name: "user_email",
            value: "",
            required: true,
            regex: /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{3}$/,
            maxLength: "",
            placeholder: "Please enter email",
            error: "Please enter email",
            submitButtonName: "Login",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "text",
            label: "Phone Number",
            formFloating: false,
            name: "mobile_number",
            value: '',
            maxLength: 10,
            regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
            required: true,
            placeholder: "Please enter phone number",
            error: "Please enter phone number",
            hideMandatoryStar: true,
        },
        {
            title: "textarea",
            type: "text",
            label: "Comment or Message",
            name: "message",
            formFloating: false,
            value: '',
            regex: "",
            required: true,
            placeholder: "Please enter Comment or Message",
            error: "Please enter Comment or Message",
            hideMandatoryStar: true,
        },
        {
            type: "button",
            className: "col-md-12 ms-auto d-flex justify-content-center my-2",
            buttons: [
                {
                    title: 'Submit',
                    action: 'add',
                    isCheckFormValidation: true,
                    className: 'sign_btn btn-sm py-1 text-white text-uppercase fw-bold  mt-3 me-4 px-5 f14'
                },
            ]
        }
    ];

    const progressData = [
        { value: 85, color: "#C2C8FD", label: "Transaction", description: "Transaction Success Ratio" },
        { value: 90, color: "#8A9BFF", label: "Merchant", description: "Merchant Satisfaction" },
        { value: 95, color: "#5A6AFF", label: "Integration", description: "Integration Support" },
        { value: 96, color: "#0044FF", label: "Payment Channel", description: "Payment Channel Coverage" },
    ];

    const [activeSection, setActiveSection] = useState('home');

    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const featureRef = useRef(null);
    const pricingRef = useRef(null);
    const reviewRef = useRef(null);
    const contactRef = useRef(null);

    const scrollToSection = (ref: any, section: any) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth'
        });
        setActiveSection(section); // Update active section
    };
    const onFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = data?.formData
            formData.type = "mobilepass"
            formData.email = ""
            delete formData['undefined']
            setLoading(true);
            LOGIN(formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { duration: 2000, position: 'top-right', });
                    setLoading(false);
                } else {
                    if (res) {
                        if (res?.data?.user_role === "SUPERADMIN") {
                            sessionStorage.setItem('user_uuid', res?.data?.user_uuid)
                            sessionStorage.setItem('user_role', res?.data?.user_role);
                            sessionStorage.setItem("jwt_token", res?.data?.jwt_token);
                            sessionStorage.setItem('user_name', res?.data?.user_name ? res?.data?.user_name : 'Super Admin');
                            toast.success(res.message, { duration: 2000, position: 'top-right', })
                            navigation("/dashboard/view");
                            sessionStorage.setItem("jwt_token", res?.data?.jwt_token);
                            setLoading(false);
                        } else if (res?.data?.user_role === "CUSTOMER") {
                            sessionStorage.setItem('user_uuid', res?.data?.user_uuid)
                            sessionStorage.setItem('user_role', res?.data?.user_role);
                            sessionStorage.setItem("jwt_token", res?.data?.jwt_token);
                            sessionStorage.setItem('user_name', res?.data?.user_name ? res?.data?.user_name : 'Super Admin');
                            toast.success(res.message, { duration: 2000, position: 'top-right', })
                            navigation("/dashboard/view");
                            sessionStorage.setItem("jwt_token", res?.data?.jwt_token);
                            setLoading(false);
                        } else {
                            toast.error("Invalid Credentials", { duration: 2000, position: 'top-right', })
                            navigation("/")
                            setLoading(false);
                        }
                    }
                }
            });
        }
    }
    const onSignUpFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            // if (data?.formData?.user_email === "customer@mail.com" && data?.formData?.password === "123456") {
            //     sessionStorage.setItem('user_role', 'CUSTOMER');
            //     sessionStorage.setItem('user_name', 'Customer');
            //     toast.success("Sign Up successfully", { position: "top-right" })
            // }
            const formData = event?.formData;
            formData.user_role = "CUSTOMER";
            formData.dob = formData.dob ? formData.dob : null;
            formData.address = formData.address ? formData.address : '';
            formData.profile_img = '';
            formData.metadata = {};
            formData.type = "mobilepass";
            delete formData['undefined'];
            setLoading(true);
            REGISTER(formData).then((res: any) => {
                if (res?.status === "fail") {
                    setLoading(false);
                    toast.error(res.error, { position: "top-right" });
                } else {
                    if (res) {
                        toast.success(res.message, { position: 'top-right' })
                        setLoading(false);
                        setShowSignUpPopup(false);
                    }
                }
            });
        }
    }
    const onSubmitContactForm = (event: any) => {

    }
    const handleSignUp = () => {
        setShowSignUpPopup(true);
    }
    const handleLogin = () => {
        setShowCreatePopup(true);
    }

    return (
        <div>
            <div className="container-fluid bg-white p-0">
                {/*  <!-- Spinner Start --> */}
                {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                    <div className="spinner-grow text-primary" style={{ width: "3rem", height: "3rem" }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> */}
                {/*  <!-- Spinner End --> */}


                {/*  <!-- Navbar & Hero Start --> */}
                <div className="container-fluid position-relative p-0" id="home">
                    <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0 sticky-top shadow-sm">
                        <a href="" className="navbar-brand ampere-web p-0">
                            <h1 className="m-0">Ampere</h1>
                            {/*  <!-- <img src="img/logo.png" alt="Logo"> --> */}
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                            <span className="fa fa-bars"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            {/* <div className="navbar-nav mx-auto py-0">
                                <a className="nav-item nav-link active" onClick={() => scrollToSection(homeRef)}>Home</a>
                                <a className="nav-item nav-link " onClick={() => scrollToSection(aboutRef)}>About</a>
                                <a className="nav-item nav-link" onClick={() => scrollToSection(featureRef)}>Feature</a>
                                <a className="nav-item nav-link" onClick={() => scrollToSection(pricingRef)}>Pricing</a>
                                <a className="nav-item nav-link" onClick={() => scrollToSection(reviewRef)}>Review</a>
                                <a className="nav-item nav-link" onClick={() => scrollToSection(contactRef)}>Contact</a>
                            </div> */}
                            <div className="navbar-nav mx-auto py-0">
                                <a
                                    className={`nav-item nav-link ${activeSection === 'home' ? 'active' : ''}`}
                                    onClick={() => scrollToSection(homeRef, 'home')}
                                >
                                    Home
                                </a>
                                <a
                                    className={`nav-item nav-link ${activeSection === 'about' ? 'active' : ''}`}
                                    onClick={() => scrollToSection(aboutRef, 'about')}
                                >
                                    Company
                                </a>
                                <a
                                    className={`nav-item nav-link ${activeSection === 'feature' ? 'active' : ''}`}
                                    onClick={() => scrollToSection(featureRef, 'feature')}
                                >
                                    Products
                                </a>
                                <a
                                    className={`nav-item nav-link ${activeSection === 'pricing' ? 'active' : ''}`}
                                    onClick={() => scrollToSection(pricingRef, 'pricing')}
                                >
                                    Solutions
                                </a>
                                {/* <a
                                    className={`nav-item nav-link ${activeSection === 'review' ? 'active' : ''}`}
                                    onClick={() => scrollToSection(reviewRef, 'review')}
                                >
                                    Review
                                </a> */}
                                <a
                                    className={`nav-item nav-link ${activeSection === 'contact' ? 'active' : ''}`}
                                    onClick={() => scrollToSection(contactRef, 'contact')}
                                >
                                    Contact
                                </a>
                            </div>
                            <button className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 d-none d-lg-block">LOGIN</button>
                            <button className="btn btn-primary-gradient rounded-pill py-2 px-4 ms-3 d-none d-lg-block">SIGN UP</button>
                        </div>
                    </nav>

                    <div className="container-fluid bg-primary hero-header" ref={homeRef}>
                        {/* <div className="container px-lg-5">
                            <div className="row g-5">
                                <div className="col-lg-8 text-center text-lg-start">
                                    <h1 className="text-white mb-4 animated slideInDown">The Revolutionary App That Helps To Control Your Own Fitness</h1>
                                    <p className="text-white pb-3 animated slideInDown">Tempor rebum no at dolore lorem clita rebum rebum ipsum rebum stet dolor sed justo kasd. Ut dolor sed magna dolor sea diam. Sit diam sit justo amet ipsum vero ipsum clita lorem</p>
                                    <a href="" className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Read More</a>
                                    <a href="" className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="owl-carousel screenshot-carousel">
                                        <img className="img-fluid" src="img/screenshot-1.png" alt="" />
                                        <img className="img-fluid" src="img/screenshot-2.png" alt="" />
                                        <img className="img-fluid" src="img/screenshot-3.png" alt="" />
                                        <img className="img-fluid" src="img/screenshot-4.png" alt="" />
                                        <img className="img-fluid" src="img/screenshot-5.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* simpaise home content starts*/}
                        <div className="container px-lg-5">
                            <div className="row g-5">
                                <div className="col-lg-8 text-center text-lg-start">
                                    <h1 className="text-white mb-4 animated slideInDown">Empowering digital payments from anywhere, anytime!</h1>
                                    <p className="text-white pb-3 animated slideInDown">Enable your business to make, accept & transfer payments with endless possibilities.</p>
                                    <button className="btn btn-primary-gradient py-sm-3 px-4 px-sm-5 rounded-pill me-3 animated slideInLeft">Watch Video</button>
                                    {/* <button className="btn btn-secondary-gradient py-sm-3 px-4 px-sm-5 rounded-pill animated slideInRight">Contact Us</button> */}
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp" data-wow-delay="0.3s">
                                    {/* <div className="owl-carousel screenshot-carousel">
                                        <img className="img-fluid" src="img/screenshot-1.png" alt="" />
                                        <img className="img-fluid" src="img/screenshot-2.png" alt="" />
                                        <img className="img-fluid" src="img/screenshot-3.png" alt="" />
                                        <img className="img-fluid" src="img/screenshot-4.png" alt="" />
                                        <img className="img-fluid" src="img/screenshot-5.png" alt="" />
                                    </div> */}
                                    <div>
                                        <img className="img-fluid w-100 h-100" style={{ height: "auto" }} src={HeaderHeroImage} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="container px-lg-5">
                            <Home />
                        </div> */}
                        {/* simpaise home content starts*/}
                    </div>
                </div>
                {/*  <!-- Navbar & Hero End --> */}


                {/*  <!-- About Start --> */}
                <div className="container-fluid py-5" id="about" ref={aboutRef}>

                    {/* out clientele starts */}
                    <div className="container py-5 px-lg-5">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                            <h5 className="text-primary-gradient fw-medium text-center">Our Clientele</h5>
                                <div className="row mt-5 pt-5">
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="feature-item bg-light rounded p-5">
                                            <div className="d-flex align-items-center justify-content-center mb-4">
                                                <img src={Thune} />
                                            </div>
                                            <h5 className="mb-3 text-center">thunes.com</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="feature-item bg-light rounded p-5">
                                            <div className="d-flex align-items-center justify-content-center mb-4">
                                                <img src={DLocal} />
                                            </div>
                                            <h5 className="mb-3 text-center">dlocal.com</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="feature-item bg-light rounded p-5">
                                            <div className="d-flex align-items-center justify-content-center mb-4">
                                                <img src={CodaShop} />
                                            </div>
                                            <h5 className="mb-3 text-center">codashop.com</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="feature-item bg-light rounded p-5">
                                            <div className="d-flex align-items-center justify-content-center mb-4">
                                                <img src={Boku} />
                                            </div>
                                            <h5 className="mb-3 text-center">boku.com</h5>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-3 wow fadeIn" data-wow-delay="0.5s">
                                        <h4>Thunes</h4>
                                        <p></p>
                                    </div> */}
                                    {/* <div className="col-sm-3 wow fadeIn" data-wow-delay="0.5s">
                                        <h4>Thunes</h4>
                                        <p>thunes.com</p>
                                    </div>
                                    <div className="col-sm-3 wow fadeIn" data-wow-delay="0.5s">
                                        <h4>Thunes</h4>
                                        <p>thunes.com</p>
                                    </div>
                                    <div className="col-sm-3 wow fadeIn" data-wow-delay="0.5s">
                                        <h4>Thunes</h4>
                                        <p>thunes.com</p>
                                    </div> */}
                                </div>
                                {/* <p className="mb-4">Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit clita duo justo eirmod magna dolore erat amet</p>
                                <div className="row g-4 mb-4">
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                        <div className="d-flex">
                                            <i className="fa fa-cogs fa-2x text-primary-gradient flex-shrink-0 mt-1"></i>
                                            <div className="ms-3">
                                                <h2 className="mb-0" data-toggle="counter-up">1234</h2>
                                                <p className="text-primary-gradient mb-0">Active Install</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                                        <div className="d-flex">
                                            <i className="fa fa-comments fa-2x text-secondary-gradient flex-shrink-0 mt-1"></i>
                                            <div className="ms-3">
                                                <h2 className="mb-0" data-toggle="counter-up">1234</h2>
                                                <p className="text-secondary-gradient mb-0">Clients Reviews</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="col-lg-6">
                                <img className="img-fluid wow fadeInUp" data-wow-delay="0.5s" src="img/about.png" />
                            </div> */}
                        </div>
                    </div>
                    {/* out clientele ends */}

                    {/* performance overview starts */}
                    <div className="container py-5 px-lg-5">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                            <h5 className="text-primary-gradient fw-medium text-center">Performance Overview</h5>
                                <div className="row mt-5 pt-5">
                                    {progressData.map((item: any, index: number) => (
                                        <div key={index} className="col-lg-3 col-md-4 progress_item">
                                            <div className="circle">
                                                <CircularProgressbar
                                                    value={item.value}
                                                    text={`${item.value}%`}
                                                    styles={buildStyles({
                                                        pathColor: item.color,
                                                        textColor: '#000',
                                                        trailColor: '#f4f4f4',
                                                        backgroundColor: '#3e98c7',
                                                        textSize: '16px',
                                                    })}
                                                    className="text-center d-flex justify-content-center mx-auto"
                                                />
                                            </div>
                                            <h4 className="text-center mt-4 mb-2 text-secondary-gradient">{item.label}</h4>
                                            <p className="text-center fs-6">{item.description}</p>
                                        </div>
                                    ))}
                                    {/* <div className="col-lg-3 col-md-4 progress_item elementor-repeater-item-8924d7d">
                                        <div className="circle" data-value="0.85" data-fill="{&quot;color&quot;: &quot;#C2C8FD&quot;}" data-trackcolor=""><canvas width="200" height="200"></canvas>
                                            <div className="number"><span className="counter">85</span>%</div>
                                        </div>
                                        <h4> Transaction </h4>
                                        <p>Transaction Success Ratio</p>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="feature-item bg-light rounded p-4">
                                            <div className="d-flex align-items-center justify-content-center mb-4">
                                                <img src={Thune} />
                                            </div>
                                            <h5 className="mb-3 text-center">thunes.com</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="feature-item bg-light rounded p-4">
                                            <div className="d-flex align-items-center justify-content-center mb-4">
                                                <img src={DLocal} />
                                            </div>
                                            <h5 className="mb-3 text-center">dlocal.com</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="feature-item bg-light rounded p-4">
                                            <div className="d-flex align-items-center justify-content-center mb-4">
                                                <img src={CodaShop} />
                                            </div>
                                            <h5 className="mb-3 text-center">codashop.com</h5>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                        <div className="feature-item bg-light rounded p-4">
                                            <div className="d-flex align-items-center justify-content-center mb-4">
                                                <img src={Boku} />
                                            </div>
                                            <h5 className="mb-3 text-center">boku.com</h5>
                                        </div>
                                    </div> */}

                                </div>
                                {/* <p className="mb-4">Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit clita duo justo eirmod magna dolore erat amet</p>
                                <div className="row g-4 mb-4">
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                        <div className="d-flex">
                                            <i className="fa fa-cogs fa-2x text-primary-gradient flex-shrink-0 mt-1"></i>
                                            <div className="ms-3">
                                                <h2 className="mb-0" data-toggle="counter-up">1234</h2>
                                                <p className="text-primary-gradient mb-0">Active Install</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                                        <div className="d-flex">
                                            <i className="fa fa-comments fa-2x text-secondary-gradient flex-shrink-0 mt-1"></i>
                                            <div className="ms-3">
                                                <h2 className="mb-0" data-toggle="counter-up">1234</h2>
                                                <p className="text-secondary-gradient mb-0">Clients Reviews</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="col-lg-6">
                                <img className="img-fluid wow fadeInUp" data-wow-delay="0.5s" src="img/about.png" />
                            </div> */}
                        </div>
                    </div>
                    {/* performance overview ends */}

                    {/* why ampere starts */}
                    <div className="container-fluid py-5" id="feature" ref={featureRef}>
                        <div className="container py-5 px-lg-5">
                            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                                <h5 className="text-primary-gradient fw-medium">Why Ampere?</h5>
                                <h1 className="mb-5">Because our Success lies in Yours. Grow your business with our exclusive features.</h1>
                            </div>
                            <div className="row g-4">
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="feature-item why-ampere bg-light rounded p-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                                            <i className="fa fa-eye text-white fs-4"></i>
                                        </div>
                                        <h5 className="mb-3">Reliability</h5>
                                        <p className="m-0">Accurate and immediate transaction facilities.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="feature-item why-ampere bg-light rounded p-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                            <i className="fa fa-layer-group text-white fs-4"></i>
                                        </div>
                                        <h5 className="mb-3">Transparency</h5>
                                        <p className="m-0">Real-time reporting with Centralized Dashboard.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="feature-item why-ampere bg-light rounded p-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                            <i className="fa fa-edit text-white fs-4"></i>
                                        </div>
                                        <h5 className="mb-3">Reachability</h5>
                                        <p className="m-0">More than 200,000 touchpoints nationwide with a network to all major banks / wallets & telcos.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                    <div className="feature-item why-ampere bg-light rounded p-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                            <i className="fa fa-shield-alt text-white fs-4"></i>
                                        </div>
                                        <h5 className="mb-3">Compatibility</h5>
                                        <p className="m-0">Easy to integrate direct API’s & readily available Plugins for multiple platforms like Magento / Shopify / WordPress etc.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                    <div className="feature-item why-ampere bg-light rounded p-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                            <i className="fa fa-cloud text-white fs-4"></i>
                                        </div>
                                        <h5 className="mb-3">Integration Support</h5>
                                        <p className="m-0">Efficient & effective integration assistance.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                    <div className="feature-item why-ampere bg-light rounded p-4">
                                        <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                            <i className="fa fa-mobile-alt text-white fs-4"></i>
                                        </div>
                                        <h5 className="mb-3">Cost Effective</h5>
                                        <p className="m-0">No One-time Setup & Annual Membership Fee.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* why ampere ends */}
                </div>
                {/*  <!-- About End --> */}


                {/*  <!-- Features Start --> */}
                {/* <div className="container-fluid py-5" id="feature" ref={featureRef}>
                    <div className="container py-5 px-lg-5">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h5 className="text-primary-gradient fw-medium">Why Ampere?</h5>
                            <h1 className="mb-5">Because our Success lies in Yours. Grow your business with our exclusive features.</h1>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-eye text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">High Resolution</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                        <i className="fa fa-layer-group text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Retina Ready</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                        <i className="fa fa-edit text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Editable Data</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                        <i className="fa fa-shield-alt text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Fully Secured</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                        <i className="fa fa-cloud text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Cloud Storage</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                        <i className="fa fa-mobile-alt text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Fully Responsive</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/*  <!-- Features End --> */}


                {/*  <!-- Screenshot Start --> */}
                {/* <div className="container-fluid py-5">
                    <div className="container py-5 px-lg-5">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-12 wow fadeInUp text-center" data-wow-delay="0.1s">
                                <h5 className="text-primary-gradient fw-medium">Screenshot</h5>
                                <h1 className="mb-4">Why Ampere?</h1>
                                <p className="mb-4">Because our Success lies in Yours. Grow your business with our exclusive features.</p>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-eye text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">High Resolution</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                        <i className="fa fa-layer-group text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Retina Ready</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                        <i className="fa fa-edit text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Editable Data</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                        <i className="fa fa-shield-alt text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Fully Secured</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                        <i className="fa fa-cloud text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Cloud Storage</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="feature-item bg-light rounded p-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle mb-4" style={{ width: "60px", height: "60px" }}>
                                        <i className="fa fa-mobile-alt text-white fs-4"></i>
                                    </div>
                                    <h5 className="mb-3">Fully Responsive</h5>
                                    <p className="m-0">Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem sed diam stet diam sed stet lorem.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 d-flex justify-content-center justify-content-lg-end wow fadeInUp" data-wow-delay="0.3s">
                                <div className="owl-carousel screenshot-carousel">
                                    <img className="img-fluid" src="img/screenshot-1.png" alt="" />
                                    <img className="img-fluid" src="img/screenshot-2.png" alt="" />
                                    <img className="img-fluid" src="img/screenshot-3.png" alt="" />
                                    <img className="img-fluid" src="img/screenshot-4.png" alt="" />
                                    <img className="img-fluid" src="img/screenshot-5.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/*  <!-- Screenshot End --> */}


                {/*  <!-- Process Start --> */}
                <div className="container-fluid py-5">
                    <div className="container py-5 px-lg-5">
                        <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
                            <h5 className="text-primary-gradient fw-medium">How It Works</h5>
                            <h1 className="mb-5">3 Easy Steps</h1>
                        </div>
                        <div className="row gy-5 gx-4 justify-content-center">
                            <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: "100px", height: "100px" }}>
                                        <i className="fa fa-cog fa-3x text-white"></i>
                                    </div>
                                    <h5 className="mt-4 mb-3">Install the App</h5>
                                    <p className="mb-0">Tempor erat elitr rebum clita dolor diam ipsum sit diam amet diam eos erat ipsum et lorem et sit sed stet</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.3s">
                                <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-secondary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: "100px", height: "100px" }}>
                                        <i className="fa fa-address-card fa-3x text-white"></i>
                                    </div>
                                    <h5 className="mt-4 mb-3">Setup Your Profile</h5>
                                    <p className="mb-0">Tempor erat elitr rebum clita dolor diam ipsum sit diam amet diam eos erat ipsum et lorem et sit sed stet</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp" data-wow-delay="0.5s">
                                <div className="position-relative bg-light rounded pt-5 pb-4 px-4">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-primary-gradient rounded-circle position-absolute top-0 start-50 translate-middle shadow" style={{ width: "100px", height: "100px" }}>
                                        <i className="fa fa-check fa-3x text-white"></i>
                                    </div>
                                    <h5 className="mt-4 mb-3">Enjoy The Features</h5>
                                    <p className="mb-0">Tempor erat elitr rebum clita dolor diam ipsum sit diam amet diam eos erat ipsum et lorem et sit sed stet</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  <!-- Process Start --> */}


                {/*  <!-- Download Start --> */}
                <div className="container-fluid py-5">
                    <div className="container py-5 px-lg-5">
                        <div className="row g-5 align-items-center">
                            <div className="col-lg-6">
                                <img className="img-fluid wow fadeInUp" data-wow-delay="0.1s" src="img/about.png" />
                            </div>
                            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                                <h5 className="text-primary-gradient fw-medium">Download</h5>
                                <h1 className="mb-4">Download The Latest Version Of Our App</h1>
                                <p className="mb-4">Diam dolor diam ipsum et tempor sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem et sit, sed stet no labore lorem sit clita duo justo eirmod magna dolore erat amet</p>
                                <div className="row g-4">
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                                        <a href="" className="d-flex bg-primary-gradient rounded py-3 px-4">
                                            <i className="fab fa-apple fa-3x text-white flex-shrink-0"></i>
                                            <div className="ms-3">
                                                <p className="text-white mb-0">Available On</p>
                                                <h5 className="text-white mb-0">App Store</h5>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                                        <a href="" className="d-flex bg-secondary-gradient rounded py-3 px-4">
                                            <i className="fab fa-android fa-3x text-white flex-shrink-0"></i>
                                            <div className="ms-3">
                                                <p className="text-white mb-0">Available On</p>
                                                <h5 className="text-white mb-0">Play Store</h5>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  <!-- Download End --> */}


                {/*  <!-- Pricing Start --> */}
                <div className="container-fluid py-5" id="pricing" ref={pricingRef}>
                    <div className="container py-5 px-lg-5">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h5 className="text-primary-gradient fw-medium">Pricing Plan</h5>
                            <h1 className="mb-5">Choose Your Plan</h1>
                        </div>
                        <div className="tab-className text-center pricing wow fadeInUp" data-wow-delay="0.1s">
                            <ul className="nav nav-pills d-inline-flex justify-content-center bg-primary-gradient rounded-pill mb-5">
                                <li className="nav-item">
                                    <button className="nav-link active" data-bs-toggle="pill">Monthly</button>
                                </li>
                                <li className="nav-item">
                                    <button className="nav-link" data-bs-toggle="pill">Yearly</button>
                                </li>
                            </ul>
                            <div className="tab-content ampere-web-tab text-start">
                                <div id="tab-1" className="tab-pane fade show p-0 active">
                                    <div className="row g-4">
                                        <div className="col-lg-4">
                                            <div className="bg-light rounded">
                                                <div className="border-bottom p-4 mb-4">
                                                    <h4 className="text-primary-gradient mb-1">Starter Plan</h4>
                                                    <span>Powerful & Awesome Features</span>
                                                </div>
                                                <div className="p-4 pt-0">
                                                    <h1 className="mb-3">
                                                        <small className="align-top" style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>14.99<small
                                                            className="align-bottom" style={{ fontSize: "16px", lineHeight: " 40px" }}>/ Month</small>
                                                    </h1>
                                                    <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="bg-light rounded border">
                                                <div className="border-bottom p-4 mb-4">
                                                    <h4 className="text-primary-gradient mb-1">Advance Plan</h4>
                                                    <span>Powerful & Awesome Features</span>
                                                </div>
                                                <div className="p-4 pt-0">
                                                    <h1 className="mb-3">
                                                        <small className="align-top" style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>24.99<small
                                                            className="align-bottom" style={{ fontSize: "16px", lineHeight: " 40px" }}>/ Month</small>
                                                    </h1>
                                                    <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <a href="" className="btn btn-secondary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="bg-light rounded">
                                                <div className="border-bottom p-4 mb-4">
                                                    <h4 className="text-primary-gradient mb-1">Premium Plan</h4>
                                                    <span>Powerful & Awesome Features</span>
                                                </div>
                                                <div className="p-4 pt-0">
                                                    <h1 className="mb-3">
                                                        <small className="align-top" style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>34.99<small
                                                            className="align-bottom" style={{ fontSize: "16px", lineHeight: " 40px" }}>/ Month</small>
                                                    </h1>
                                                    <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tab-2" className="tab-pane fade p-0">
                                    <div className="row g-4">
                                        <div className="col-lg-4">
                                            <div className="bg-light rounded">
                                                <div className="border-bottom p-4 mb-4">
                                                    <h4 className="text-primary-gradient mb-1">Starter Plan</h4>
                                                    <span>Powerful & Awesome Features</span>
                                                </div>
                                                <div className="p-4 pt-0">
                                                    <h1 className="mb-3">
                                                        <small className="align-top" style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>114.99<small
                                                            className="align-bottom" style={{ fontSize: "16px", lineHeight: " 40px" }}>/ Yearly</small>
                                                    </h1>
                                                    <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="bg-light rounded border">
                                                <div className="border-bottom p-4 mb-4">
                                                    <h4 className="text-primary-gradient mb-1">Advance Plan</h4>
                                                    <span>Powerful & Awesome Features</span>
                                                </div>
                                                <div className="p-4 pt-0">
                                                    <h1 className="mb-3">
                                                        <small className="align-top" style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>124.99<small
                                                            className="align-bottom" style={{ fontSize: "16px", lineHeight: " 40px" }}>/ Yearly</small>
                                                    </h1>
                                                    <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="bg-light rounded">
                                                <div className="border-bottom p-4 mb-4">
                                                    <h4 className="text-primary-gradient mb-1">Premium Plan</h4>
                                                    <span>Powerful & Awesome Features</span>
                                                </div>
                                                <div className="p-4 pt-0">
                                                    <h1 className="mb-3">
                                                        <small className="align-top" style={{ fontSize: "22px", lineHeight: "45px" }}>$</small>134.99<small
                                                            className="align-bottom" style={{ fontSize: "16px", lineHeight: " 40px" }}>/ Yearly</small>
                                                    </h1>
                                                    <div className="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <div className="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i className="fa fa-check text-primary-gradient pt-1"></i></div>
                                                    <a href="" className="btn btn-primary-gradient rounded-pill py-2 px-4 mt-4">Get Started</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  <!-- Pricing End --> */}


                {/*  <!-- Testimonial Start --> */}
                <div className="container-fluid py-5" id="review" ref={reviewRef}>
                    <div className="container py-5 px-lg-5">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h5 className="text-primary-gradient fw-medium">Testimonial</h5>
                            <h1 className="mb-5">What Say Our Clients!</h1>
                        </div>
                        <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
                            <div className="testimonial-item rounded p-4">
                                <div className="d-flex align-items-center mb-4">
                                    <img className="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/testimonial-1.jpg" style={{ width: "85px", height: "85px" }} />
                                    <div className="ms-4">
                                        <h5 className="mb-1">Client Name</h5>
                                        <p className="mb-1">Profession</p>
                                        <div>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                        </div>
                                    </div>
                                </div>
                                <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                            </div>
                            <div className="testimonial-item rounded p-4">
                                <div className="d-flex align-items-center mb-4">
                                    <img className="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/testimonial-2.jpg" style={{ width: "85px", height: "85px" }} />
                                    <div className="ms-4">
                                        <h5 className="mb-1">Client Name</h5>
                                        <p className="mb-1">Profession</p>
                                        <div>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                        </div>
                                    </div>
                                </div>
                                <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                            </div>
                            <div className="testimonial-item rounded p-4">
                                <div className="d-flex align-items-center mb-4">
                                    <img className="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/testimonial-3.jpg" style={{ width: "85px", height: "85px" }} />
                                    <div className="ms-4">
                                        <h5 className="mb-1">Client Name</h5>
                                        <p className="mb-1">Profession</p>
                                        <div>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                        </div>
                                    </div>
                                </div>
                                <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                            </div>
                            <div className="testimonial-item rounded p-4">
                                <div className="d-flex align-items-center mb-4">
                                    <img className="img-fluid bg-white rounded flex-shrink-0 p-1" src="img/testimonial-4.jpg" style={{ width: "85px", height: "85px" }} />
                                    <div className="ms-4">
                                        <h5 className="mb-1">Client Name</h5>
                                        <p className="mb-1">Profession</p>
                                        <div>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                            <small className="fa fa-star text-warning"></small>
                                        </div>
                                    </div>
                                </div>
                                <p className="mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  <!-- Testimonial End --> */}


                {/*  <!-- Contact Start --> */}
                <div className="container-fluid py-5" id="contact" ref={contactRef}>
                    <div className="container py-5 px-lg-5">
                        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                            <h5 className="text-primary-gradient fw-medium">Contact Us</h5>
                            <h1 className="mb-5">Get In Touch!</h1>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="wow fadeInUp" data-wow-delay="0.3s">
                                    <p className="text-center mb-4">The contact form is currently inactive. Get a functional and working contact form with Ajax & PHP in a few minutes. Just copy and paste the files, add a little code and you're done. <a href="https://htmlcodex.com/contact-form">Download Now</a>.</p>
                                    <form>
                                        <div className="row g-3">
                                            <div className="col-md-6">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                                    <label htmlFor="name">Your Name</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-floating">
                                                    <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                                    <label htmlFor="email">Your Email</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating">
                                                    <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                                    <label htmlFor="subject">Subject</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating">
                                                    <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: "150px" }}></textarea>
                                                    <label htmlFor="message">Message</label>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <button className="btn btn-primary-gradient rounded-pill py-3 px-5" type="submit">Send Message</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  <!-- Contact End --> */}


                {/*  <!-- Footer Start --> */}
                <div className="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
                    <div className="container py-5 px-lg-5">
                        <div className="row g-5 mt-1">
                            <div className="col-md-6 col-lg-3">
                                <h4 className="text-white mb-4">Address</h4>
                                <p><i className="fa fa-map-marker-alt me-3"></i>123 Street, New York, USA</p>
                                <p><i className="fa fa-phone-alt me-3"></i>+012 345 67890</p>
                                <p><i className="fa fa-envelope me-3"></i>info@example.com</p>
                                <div className="d-flex pt-2">
                                    <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-instagram"></i></a>
                                    <a className="btn btn-outline-light btn-social" href=""><i className="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <h4 className="text-white mb-4">Quick Link</h4>
                                <a className="btn btn-link" href="">About Us</a>
                                <a className="btn btn-link" href="">Contact Us</a>
                                <a className="btn btn-link" href="">Privacy Policy</a>
                                <a className="btn btn-link" href="">Terms & Condition</a>
                                <a className="btn btn-link" href="">Career</a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <h4 className="text-white mb-4">Popular Link</h4>
                                <a className="btn btn-link" href="">About Us</a>
                                <a className="btn btn-link" href="">Contact Us</a>
                                <a className="btn btn-link" href="">Privacy Policy</a>
                                <a className="btn btn-link" href="">Terms & Condition</a>
                                <a className="btn btn-link" href="">Career</a>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <h4 className="text-white mb-4">Newsletter</h4>
                                <p>Lorem ipsum dolor sit amet elit. Phasellus nec pretium mi. Curabitur facilisis ornare velit non vulpu</p>
                                <div className="position-relative w-100 mt-3">
                                    <input className="form-control border-0 rounded-pill w-100 ps-4 pe-5" type="text" placeholder="Your Email" style={{ height: "48px" }} />
                                    <button type="button" className="btn shadow-none position-absolute top-0 end-0 mt-1 me-2"><i className="fa fa-paper-plane text-primary-gradient fs-4"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container px-lg-5">
                        <div className="copyright">
                            <div className="row">
                                <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                    &copy; <a className="border-bottom" href="#">Your Site Name</a>, All Right Reserved.

                                    Designed By <a className="border-bottom" href="https://htmlcodex.com">HTML Codex</a>
                                    {/* </br> */}
                                    Distributed By <a className="border-bottom" href="https://themewagon.com" target="_blank">ThemeWagon</a>
                                </div>
                                <div className="col-md-6 text-center text-md-end">
                                    <div className="footer-menu">
                                        <a href="">Home</a>
                                        <a href="">Cookies</a>
                                        <a href="">Help</a>
                                        <a href="">FQAs</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  <!-- Footer End --> */}


                {/*  <!-- Back to Top --> */}
                <a className="btn btn-lg btn-lg-square back-to-top pt-2" onClick={() => scrollToSection(homeRef, 'home')}><i className="bi bi-arrow-up text-white"></i></a>
            </div >
        </div>
    )
}