import { DataTableCol } from "../../../../components/data-table/types";

export const SubscriptionTypesReportsDataGridCols: DataTableCol[] = [
    {
        title: "Plan Name",
        control: "subscription_plan_name",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Subscription Period",
        control: "subscription_days",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Amount",
        control: "subscription_amount",
        sortable: true,
        canShowColumn: true,
    },
    {
        title: "Total Subscriptions",
        control: "total_subscriptions",
        sortable: true,
        canShowColumn: true,
    },
];
