import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { VideoContent } from '../pages/dashboard/content/content-tabs/video-content'
import { GameContent } from '../pages/dashboard/content/content-tabs/game-content'
import { BlogContent } from '../pages/dashboard/content/content-tabs/blog-content'

export const ContentTabRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<GameContent />}></Route>
            <Route path="/game" element={<GameContent />}></Route>
            <Route path="/video" element={<VideoContent />}></Route>
            <Route path="/blog" element={<BlogContent />}></Route>
        </Routes>
    )
}
