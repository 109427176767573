import React, { useEffect, useState } from "react";
import { DataTableCol } from "./types";
import Pagination from "@mui/material/Pagination";
import { Tooltip } from "@mui/material";
import moment from "moment";
import toast from "react-hot-toast";
// import faCopy from './'
interface Props {
  tableData: any;
  TableCols: DataTableCol[];
  alertTableStatus?: any;
  showViewModal?: any;
  paginationHide?: any;
  alterShowViewData?: (data: any) => void;
  onActions: (data: string) => void;
  onPageChange: (data: any) => void;
  pageCount: any;
  onSelectPageChange?: (data: any) => void;
}

export const DataTable: React.FC<Props> = (props: Props) => {
  const [canShowDefaultArrow, setCanShowDefaultArrow] = useState(true);
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");
  const [itemOffset, setItemOffset] = useState(props?.pageCount?.page);
  const [showViewModal, setShowViewModal] = useState<boolean>(false);
  const [showViewData, setShowViewData] = useState<any>();
  const [itemsPerPage, setItemsPerPage] = useState(props?.pageCount?.limit);
  const [isVisibleIndex, setIsVisibleIndex] = useState<number>(-1);

  // const [pageCount, setPageCount] = useState(props.tableData?.total_count);
  const items: any[] = props?.tableData?.rows || [];
  // const endOffset = itemOffset + itemsPerPage;
  // const currentItems = items?.slice(itemOffset, endOffset);
  const pageCount = Math?.ceil(props?.tableData?.total / itemsPerPage);
  const itemPerPage = [10, 25, 50, 100, 500];

  useEffect(() => {
    if (props?.showViewModal) {
      setShowViewModal(true);
    }
  }, [props?.showViewModal]);

  const onChangeItemPerPage = (value: any) => {
    props.onPageChange(value);
    setItemsPerPage(value);
  };

  const onSortingField = (data: any) => {
    setCanShowDefaultArrow(false);
    const order =
      data === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(data);
    setSortingOrder(order);
    if (data) {
      const reversed = order === "asc" ? 1 : -1;
      const preparedData = props?.tableData?.data?.sort(
        (a: any, b: any) =>
          reversed * a[data]?.toString().localeCompare(b[data])
      );
    }
  };

  const handlePageClick = (e: any, value: any) => {
    if (props.onSelectPageChange) {
      props.onSelectPageChange((value - 1));
    }
    setItemOffset((value - 1));
  };

  const onAction = (data: any, type: string) => {
    const actionData: any = { data, type };
    props.onActions(actionData);
  };

  const onShowViewModal = (data: any, index: number) => {
    setShowViewData(data);
    setShowViewModal(true);
    if (props?.alertTableStatus) {
      props?.alertTableStatus(true);
    }
    if (props?.alterShowViewData) {
      props?.alterShowViewData(data);
    }
  }

  const handleToggleVisibility = (index: number) => {
    if (isVisibleIndex === index) {
      setIsVisibleIndex(-1);
    } else {
      setIsVisibleIndex(index);
    }
  };

  const handleCopy = (data: any) => {
    navigator.clipboard.writeText(data);
    toast.success("Copied successfully", { position: "top-right" })
  };
  const displayKey = (key: any, index: number) => {
    if (isVisibleIndex === index) {
      return key;
    }
    if (key.length > 10) {
      return '*'.repeat(10);
    }
    return '*'.repeat(key.length);
  };

  return (
    <div>
      <div className="container-fluid px-0">
        <div className="row px-0">
          <div className="col-12">
            <div className="table-responsive">
              {/* style={{ minHeight: items?.length > 0 ? '150px' : "0px", }} */}
              <table className="table table_new_ui">
                <thead className="text-center">
                  <tr className="border border-primary-medium">
                    {props?.TableCols?.map((el, index) => (
                      <React.Fragment key={index}>
                        {el.canShowColumn && (
                          <th className="text-start"
                            onClick={() =>
                              el.sortable ? onSortingField(el.control) : null
                            }
                          >
                            {<span>{el.title}</span>}
                            {/* {canShowDefaultArrow &&
                              index === 0 &&
                              el.sortable && (
                                <i className="bi bi-arrow-down cursor-pointer p-2 "></i>
                              )} */}
                            {sortingField && sortingField === el.control && (
                              <i
                                className={`${sortingOrder === "asc"
                                  ? "bi bi-arrow-down text-white cursor-pointer p-2"
                                  : "bi bi-arrow-up text-white cursor-pointer p-2"
                                  }`}
                              ></i>
                            )}
                          </th>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody className="text-center">
                  {items &&
                    items?.map((data: any, i: number) => (
                      <tr key={i} className="border-bottom">
                        {props?.TableCols?.map((el: any, index: number) => (
                          <React.Fragment key={index}>
                            <td className="text-start">
                              {el.control === "serial_no" && (
                                <span>{i + 1}</span>
                              )}
                              {/* {el.control === "from_name" &&  (
                                <span className="fw-bold">{data[el?.control]}</span>
                              )}
                               {el.control === "name" &&  (
                                <span className="fw-bold">{data[el?.control]}</span>
                              )} */}
                              {el.control === "is_active" && (
                                <span>
                                  {data[el.control] === 1
                                    ? "Active"
                                    : "In-Active"}
                                </span>
                              )}
                              {el.isArrayToString && (
                                <span>{data[el.control]?.toString()}</span>
                              )}
                              {el.control !== "is_active" &&
                                el.control !== "interest_id" &&
                                !el.isArrayToString &&
                                !el.isLink &&
                                !el.isTextOverflowHide &&
                                !el?.isBold &&
                                el?.control !== "status" &&
                                el?.title !== "Role" &&
                                !el?.isDateFormat &&
                                !el?.isHideAndShow &&
                                !el?.isServiceID &&
                                !el?.isShowSubscriptionTypes &&
                                !el?.isButton &&
                                !el?.isSuccessStatus &&
                                !el?.isSubscriptionMode &&
                                (
                                  <span>{data[el.control]}</span>
                                )}

                              {/* {el?.control === 'status' && (
                                <span >{data[el?.control] === 1 ? 'Allocated' : 'Not Allocated'}</span>
                              )} */}
                              {el?.isDateFormat && (
                                <span>{data[el.control] === "" || data[el.control] === null ? "-" : moment(data[el.control]).format("DD-MM-YYYY")}</span>
                              )}
                              {el?.isTimeFormat && (
                                <span>{data[el.control] === "" || data[el.control] === null ? "-" : moment(data[el.control]).format("hh:mm A")}</span>
                              )}
                              {el?.isHideAndShow && (
                                <div>
                                  <span className="fw-bold">
                                    {displayKey(data[el.control], index)}
                                  </span>
                                  <button className="btn bg-transparent shadow border-0 mx-2" onClick={() => handleToggleVisibility(index)}>
                                    {isVisibleIndex === index ? <i title="Hide" className="bi bi-eye-slash"></i> : <i title="Show" className="bi bi-eye"></i>}
                                  </button>
                                  <button className="btn bg-transparent shadow border-0 mx-2" onClick={(e) => handleCopy(data[el?.control])}>
                                    {/* <i className="bi bi-copy"></i>copy */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                                      <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                    </svg>
                                  </button>
                                </div>
                              )}
                              {el?.isServiceID && (
                                <span>{data[el?.control] === "" ? "In Progress" : data[el?.control]}</span>
                              )}
                              {el?.isBold && (
                                <span className="fw-bold">{data[el?.control]}</span>
                              )}
                              {el?.control === "status" && (
                                <span className={`fw-bold text-uppercase ${data[el.control] == 1 ? "text-success" : "text-danger"}`}>{data[el.control] == 1 ? "Active" : "In Active"}</span>
                              )}
                              {el?.isSuccessStatus && (
                                <span className="fw-bold">{data[el?.control] === "SUCCESS" ? <span className="text-success ">Success</span> : <span className="text-danger">Pending</span>}</span>
                              )}
                              {el?.isSubscriptionMode && (
                                <span className="fw-bold">{data[el?.control] === "0" ? <span className="text-success ">Active</span> : <span className="text-danger">In Active</span>}</span>
                              )}
                              {el?.title === "Role" && (
                                <span>{data[el?.control]?.replace(/_/g, ' ')}</span>
                              )}
                              {el?.isButton && (
                                <span><button className="btn btn-primary border-0" onClick={(e) => onAction(data, el?.title)}>{el?.title}</button></span>
                              )}
                              {el.isLink && (
                                <span
                                  className="custom_link"
                                  onClick={() => onAction(data, "link")}
                                >
                                  {data[el.control]?.toString()}
                                </span>
                              )}
                              {el.isTextOverflowHide && (
                                <Tooltip
                                  title={data[el.control]}
                                  placement="top"
                                  arrow
                                  className="custom-tooltip-position"
                                >
                                  <span>
                                    {data[el.control]?.length > 100
                                      ? `${data[el.control].slice(0, 100)}...`
                                      : `${data[el.control]
                                        ? data[el.control]
                                        : ""
                                      }`}
                                  </span>
                                </Tooltip>
                              )}
                              {el.title === "Actions" && (
                                <div className="actions-view">
                                  <span>
                                    {el.isEdit && (
                                      <i
                                        className="bi bi-pencil-square settings-icon-action cursor-pointer edit-icon p-2"
                                        onClick={() => onAction(data, "Edit")}
                                      ></i>
                                    )}
                                    {el.isDelete && (
                                      <i
                                        className="bi bi-trash text-danger settings-icon-action fw-bold icon-size cursor-pointer p-2"
                                        onClick={() => onAction(data, "Delete")}
                                      ></i>
                                    )}
                                    {el.isView && (
                                      <i title="Subscription Types"
                                        className="bi bi-eye-fill settings-icon-action fw-bold icon-size cursor-pointer p-2"
                                        onClick={() => onAction(data, "View")}
                                      ></i>
                                    )}
                                    {el.isShowPaymentPartners && (
                                      <button className="btn btn-primary ms-3 fw-bold border-0" onClick={() => onAction(data, "Payment Partners")}>Payment Partners</button>
                                      // <i title="Payment Partners"
                                      //   className="bi bi-eye-fill settings-icon-action fw-bold icon-size cursor-pointer p-2"
                                      //   
                                      // ></i>
                                    )}
                                  </span>
                                </div>
                              )}
                              {el.control === "button" && (
                                <span>
                                  <span>
                                    <button
                                      className="btn btn-primary border-0"
                                      onClick={() =>
                                        onAction(data, "subscription_plan")
                                      }
                                    >
                                      Subscription Plan
                                    </button>
                                  </span>
                                </span>
                              )}
                              {el?.title === "payment-actions" && (
                                <div className="actions-view">
                                  <span>
                                    {el.isPayment && (
                                      <i
                                        className="bi bi-pencil-square settings-icon-action cursor-pointer edit-icon p-2"
                                        onClick={() => onAction(data, "Payment")}
                                      ></i>
                                    )}</span>
                                </div>
                              )}
                            </td>
                          </React.Fragment>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {items?.length === 0 && (
              <div className="mt-3 text-center">No records found.</div>
            )}
            {!props?.paginationHide && (
              <div className="row my-4">
                <div className="col-md-4 text-start mt-1">
                  {items?.length > 0 && (<>
                    <span className="me-2"> Item per page</span>
                    <select
                      onChange={(e) => onChangeItemPerPage(e.target.value)}
                      value={itemsPerPage}
                    >
                      {itemPerPage.map((page: number, index: number) => {
                        return (
                          <option key={index} value={page}>
                            {page}
                          </option>
                        );
                      })}
                    </select>
                  </>)}
                </div>
                <div className="col-md-8 d-flex justify-content-end">
                  {items?.length > 0 && (
                    <div>
                      <Pagination
                        count={pageCount}
                        defaultPage={itemOffset + 1}
                        showFirstButton
                        showLastButton
                        variant="outlined"
                        shape="rounded"
                        onChange={(e: any, value: any) => handlePageClick(e, value)}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {showViewModal && (
              <>
                <div className=""></div>
                <div className={`modal modal-custom fade ${showViewModal ? "show" : "false"}  ${showViewModal ? "modal-show" : "modal-hide"}`} tab-index="-1" role="dialog" aria-hidden="true">
                  <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content text-start">
                      <div className="modal-header border-bottom-primary mx-4 px-0">
                        <h1 className="modal-title fs-5 fw-light" id="exampleModalLabel"><strong className="text-primary-medium fw-bold">{showViewData?.from_name}</strong> | 25 M | 09793991578</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowViewModal(false)}></button>
                      </div>
                      <div className="modal-body pb-5">
                        <div className="row px-2">
                          <div className="col-md-12">
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label mb-0">Message</label>
                              <p className="f16 border border-1 rounded p-2">Need to find out a vehicle woner of AP29AZ 6834 to clear, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
