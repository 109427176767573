import { DataTableCol } from "../../../../components/data-table/types";

export const SystemParamsDataGridCols: DataTableCol[] = [
    {
        title: 'Name',
        control: 'name',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Type',
        control: 'type',
        sortable: true,
        canShowColumn: true
    },
    // {
    //     title: 'Meta Data',
    //     control: 'metadata',
    //     isStatus: true,
    //     sortable: true,
    //     canShowColumn: true
    // },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        isView: false,
        isAddAddress: true,
        canShowColumn: true
    },
];