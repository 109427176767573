import { DataTableCol } from "../../../../components/data-table/types";

export const DeploymentVersionDataGridCols: DataTableCol[] = [
    {
        title: 'Version',
        control: 'version',
        sortable: true,
        canShowColumn: true,
    },
    {
        title: 'Deployed At',
        control: 'deployed_dt',
        sortable: true,
        canShowColumn: true,
        isDateFormat:true
    },
    {
        title: 'Type',
        control: 'type',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: true,
        isDelete: true,
        isView: false,
        isAddAddress: true,
        canShowColumn: true
    },
];