import React, { useEffect, useState } from "react";
import { GET_PRESIGNED_URL } from "../../../app/services/common.service";
import { S3Helper } from "../../../app/utils/s3-helper";
import Multiselect from "multiselect-react-dropdown";
import toast from "react-hot-toast";

interface Props {
  columns: number;
  userFormData: any;
  cancelRoute: any;
  isEdit: boolean;
  onChildEvent?: (data: string) => void;
  onChangeEventDate?: (data: any) => void;
  onFormSubmitEvent?: (data: any) => void;
  customClass?: any;
  loading?: any;
  custom_button_text?: any;
  customClassName?: any;
  isNotEmptyFormAfterSubmit?: boolean;
  onParentDropdownEvent?: (data: any) => void;
}

export const DynamicForm = (props: Props) => {
  const [formData, setFormData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [formFieldsArray, setFormFieldsArray] = useState<any[]>([]);
  const [image, setImage] = useState<any>(null);
  const [images, setImages] = useState<any>([]);
  useEffect(() => {
    const formsValues: any = {};
    props.userFormData.forEach((element: any) => {
      formsValues[element.name] = element.value ? element.value : "";
      if (element.title === 'formArray') {
        formsValues[element.name] = getFormArray(element)
      }
    });
    setFormData(formsValues);
    setFormFieldsArray([...props.userFormData]);
  }, [props]);

  const handleInput = (e: any, index: number, data: any) => {
    const formData = formFieldsArray;
    const { name, value } = e.target;

    setFormData((prevData: any) => ({
      ...prevData,
      [name]: data?.type === "checkbox" ? e.target.checked : value,
    }));
    // Validate field based on regex pattern
    const field = formFieldsArray[index];

    const { regex, label } = field;
    const newErrors: any = { ...errors };

    if (regex && value && !RegExp(regex).test(value)) {
      newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    } else {
      delete newErrors[name]; // Remove the error if the input is valid
    }

    formData?.forEach((el: any) => {
      if (el?.name === "event_start_date") {
        if (el?.value === "" && field?.isEndDataValidate) {
          newErrors['event_start_date'] = `Please select start date`;
        } else {
          delete newErrors[name];
        }
      }
    })
    formData[index].value =
      data?.type === "checkbox" ? e.target.checked : value;
    setFormFieldsArray([...formData]);
    setErrors(newErrors);
    if (field?.isParentDropDown) {
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent(formData[index]);
      }
    }
  };

  // const handleInputFileUpload = (e: any, index: number, data: any) => {
  //   const imageData = e.target.files[0];
  //   const maxSize = 2 * 1024 * 1024; // 2MB
  //   if (imageData && imageData.size > maxSize) {
  //     const field = formFieldsArray[index];
  //     const { name, value } = e.target;
  //     const { regex, label } = field;
  //     const newErrors: any = { ...errors };
  //     newErrors[name] = `File size exceeds the 2MB limit.`;
  //     setErrors(newErrors);
  //   } else {
  //     const imagesList = [];
  //     imagesList.push(imageData.name);
  //     let payload = {
  //       images: imagesList
  //     }
  //     GET_PRESIGNED_URL(payload).then((res: any) => {
  //       if (res) {
  //         const result = S3Helper.uploadFilesToS3BySigned(res.data?.presigned_urls[imageData.name],
  //           imageData,
  //           imageData.type,
  //         );
  //         const formData = formFieldsArray;
  //         const { name, value } = e.target;

  //         const field = formFieldsArray[index];
  //         const { regex, label } = field;
  //         const newErrors: any = { ...errors };

  //         if (regex && value && !RegExp(regex).test(value)) {
  //           newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
  //         } else {
  //           delete newErrors[name];
  //         }
  //         formData[index].value = res.data?.presigned_urls[imageData.name].split('?')[0];
  //         setFormData((prevData: any) => ({
  //           ...prevData,
  //           [name]: res.data?.presigned_urls[imageData.name].split('?')[0],
  //         }));
  //         const file = e.target.files[0];

  //         if (file) {
  //           const reader = new FileReader();

  //           reader.onloadend = () => {
  //             setImage(reader.result);
  //           };

  //           reader.readAsDataURL(file);
  //         }
  //         setFormFieldsArray([...formData]);
  //         setErrors(newErrors);
  //       }
  //     })
  //   }
  // };
  const handleInputFileUpload = async (e: any, index: number, data: any) => {
    const imageData = e.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB

    if (imageData && imageData.size > maxSize) {
      const field = formFieldsArray[index];
      const { name } = e.target;
      const { regex, label } = field;
      const newErrors: any = { ...errors };
      newErrors[name] = `File size exceeds the 2MB limit.`;
      setErrors(newErrors);
      return;
    }

    const imagesList = [imageData.name];
    const payload = { images: imagesList };

    try {
      const res = await GET_PRESIGNED_URL(payload);

      if (res) {
        const uploadResult = await S3Helper.uploadFilesToS3BySigned(
          res.data?.presigned_urls[imageData.name],
          imageData,
          imageData.type,
        );

        if (uploadResult) {
          const formData = [...formFieldsArray];
          const { name } = e.target;
          const field = formFieldsArray[index];
          const { regex, label } = field;
          const newErrors: any = { ...errors };

          if (regex && imageData.name && !RegExp(regex).test(imageData.name)) {
            newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
          } else {
            delete newErrors[name];
          }

          const imageUrl = res.data?.presigned_urls[imageData.name].split('?')[0];
          formData[index].value = imageUrl;
          setFormData((prevData: any) => ({
            ...prevData,
            [name]: imageUrl,
          }));

          const reader = new FileReader();
          reader.onloadend = () => {
            setImage(reader.result);
            setFormFieldsArray([...formData]);
            setErrors(newErrors);
          };

          reader.onerror = () => {
            newErrors[name] = 'Failed to read file';
            setErrors(newErrors);
          };

          if (imageData) {
            reader.readAsDataURL(imageData);
          }
        }
      }
    } catch (error) {
      const newErrors: any = { ...errors };
      newErrors['upload'] = 'Failed to get presigned URL';
      setErrors(newErrors);
    }
  };


  const onFormSubmit = (e: any, btnObj: any, index: number, subIndex: number) => {
    if (btnObj.isCheckFormValidation) {
      e.preventDefault();
      const isValid = validateForm();
      if (isValid) {
        setErrors({});
        const data: any = { formData, type: btnObj?.action };
        if (props?.onFormSubmitEvent) {
          props?.onFormSubmitEvent(data);
        }
        if (!props?.isNotEmptyFormAfterSubmit) {
          formFieldsArray.forEach((element: any) => {
            element.value = "";
          });
        }
      }
    } else {
      const data: any = { formData, type: btnObj?.action, index, subIndex };
      if (props?.onFormSubmitEvent) {
        props?.onFormSubmitEvent(data);
      }
    }
  }

  const validateForm = () => {
    let isValid = true;
    const newErrors: any = {};

    formFieldsArray?.forEach((field: any) => {
      const { name, label, required, regex, error } = field;
      const value =
        typeof formData[name] === "string"
          ? formData[name]?.trim() || field.value
          : formData[name] || field.value;
      if (required && (!value || value === "")) {
        newErrors[name] = error || `Please enter a ${label?.toLowerCase()}`;
        isValid = false;
      }
      if (regex && value && !RegExp(regex).test(value)) {
        newErrors[name] =
          error || `Please enter a valid ${label?.toLowerCase()}`;
        isValid = false;
      }
    });
    setErrors(newErrors);
    return isValid;
  };
  const handleSubElementInput = (e: any, index: number, subIndex: number, subSubIndex: number, subData: any, data: any) => {

    const formDataArray = formFieldsArray;
    const { name, value } = e.target;


    // setFormData((prevData: any) => ({
    //   ...prevData,
    //   [data?.name]: [...prevData[data?.name], ...[{ [name]: value }]],
    // }));
    const field = formFieldsArray[index];

    const { regex, label } = field;
    const newErrors: any = { ...errors };

    if (regex && value && !RegExp(regex).test(value)) {
      newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
    } else {
      delete newErrors[name];
    }

    formDataArray?.forEach((el: any) => {
      if (el?.name === "event_start_date") {
        if (el?.value === "" && field?.isEndDataValidate) {
          newErrors['event_start_date'] = `Please select start date`;
        } else {
          delete newErrors[name];
        }
      }
    })
    formDataArray[index].fields[subIndex].fields[subSubIndex].value =
      data?.type === "checkbox" ? e.target.checked : value;

    setFormData((prevData: any) => ({
      ...prevData,
      [data?.name]: getFormArray(formDataArray[index]),
    }));
    setFormFieldsArray([...formDataArray]);
    setErrors(newErrors);
    if (field?.isParentDropDown) {
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent(value);
      }
    }
  };

  const getFormArray = (formDataArray: any) => {
    return formDataArray?.fields.map((item: any) => {
      const keyField = item.fields.find((field: any) => field.name === 'key');
      const valueField = item.fields.find((field: any) => field.name.toLowerCase() === 'value');
      return {
        key: keyField.value,
        value: valueField.value
      };
    });
  }

  // const onSelect = (
  //   selectedList: any,
  //   selectedItem: any,
  //   index: number,
  //   element: string
  // ) => {
  //   const formFields = formFieldsArray;
  //   if (selectedItem.value === "ALL") {
  //     formFields[index].value = formFields[index].option;
  //     setFormData((prevData: any) => ({
  //       ...prevData,
  //       [element]: "",
  //     }));
  //     // if (formFields[index]?.isParendDropDown) {
  //     if (props?.onParentDropdownEvent) {
  //       props?.onParentDropdownEvent({
  //         data: formFields[index].option,
  //         isApi: formFields[index].isApiCall,
  //       });
  //     }
  //     // }
  //     setFormFieldsArray([...formFields]);
  //   } else {
  //     setFormData((prevData: any) => ({
  //       ...prevData,
  //       [element]: [...selectedList],
  //     }));
  //     // if (formFields[index]?.isParendDropDown) {
  //     if (props?.onParentDropdownEvent) {
  //       props?.onParentDropdownEvent({
  //         data: selectedList,
  //         isApi: formFields[index].isApiCall,
  //       });
  //     }
  //     // }
  //   }
  // };

  // const onRemove = (
  //   selectedList: any,
  //   removedItem: any,
  //   index: number,
  //   element: string
  // ) => {
  //   if (removedItem.value === "ALL") {
  //     const formFields = formFieldsArray;
  //     if (props?.onParentDropdownEvent) {
  //       props?.onParentDropdownEvent({
  //         data: "",
  //         isApi: formFields[index].isApiCall,
  //       });
  //     }
  //     setFormFieldsArray([...formFields]);
  //   } else {
  //     const formFields = formFieldsArray;
  //     if (props?.onParentDropdownEvent) {
  //       props?.onParentDropdownEvent({
  //         data: selectedList,
  //         isApi: formFields[index].isApiCall,
  //       });
  //     }
  //     setFormFieldsArray([...formFields]);
  //   }
  // };

  const onSelect = (selectedList: any, selectedItem: any, index: number, element: any) => {
    const formFields = formFieldsArray;
    if (selectedItem.value === "ALL") {
      formFields[index].value = formFields[index].option;
      setFormData((prevData: any) => ({
        ...prevData,
        [element]: formFields[index].option,
      }));
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent({
          data: formFields[index].option,
          isApi: formFields[index].isApiCall,
        });
      }
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [element]: selectedList,
      }));
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent({
          data: selectedList,
          isApi: formFields[index].isApiCall,
        });
      }
    }
    setFormFieldsArray([...formFields]);
  };

  const onRemove = (selectedList: any, removedItem: any, index: number, element: any) => {
    const formFields = formFieldsArray;
    if (removedItem.value === "ALL") {
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent({
          data: [],
          isApi: formFields[index].isApiCall,
        });
      }
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [element]: selectedList,
      }));
      if (props?.onParentDropdownEvent) {
        props?.onParentDropdownEvent({
          data: selectedList,
          isApi: formFields[index].isApiCall,
        });
      }
    }
    setFormFieldsArray([...formFields]);
  };

  const handleInputMultipleFileUpload = (event: any, index: any, data: any) => {
    // const files = event.target.files;
    // const fileArray: any = Array.from(files).map((file: any) => URL.createObjectURL(file));
    // setImages(prevImages => prevImages.concat(fileArray));
    // Array.from(files).map((file: any) => URL.revokeObjectURL(file));

    const imageData = event.target.files;
    const imagesList: any = [];
    Array.from(imageData).map((file: any) => {
      imagesList.push(file.name);
    });
    let payload = {
      images: imagesList
    }

    GET_PRESIGNED_URL(payload).then((res: any) => {
      if (res) {
        const URLS: any = [];
        Array.from(imageData).map((file: any) => {
          const result = S3Helper.uploadFilesToS3BySigned(res.data?.presigned_urls[file.name],
            file,
            file.type,
          );
          URLS.push(res.data?.presigned_urls[file.name].split('?')[0])
        });
        const formData = formFieldsArray;
        const { name, value } = event.target;
        formData[index].value = URLS;
        setFormData((prevData: any) => ({
          ...prevData,
          [name]: URLS
        }));
        setImages([...URLS])
        setFormFieldsArray([...formData]);
      }
    })
  };

  const handleCopy = (data: any) => {
    navigator.clipboard.writeText(data);
    toast.success("Password Copied successfully", { position: "top-right" });

    const form = formFieldsArray
    form?.forEach((element: any) => {
      if (element?.type === "button") {
        for (let i = 0; i < element?.buttons?.length; i++) {
          const ele = element?.buttons;
          ele[0].isDisabled = false;
        }
      }
    });
    setFormFieldsArray([...form])

  }

  const handleHideAndShow = (event: any, index: number) => {
    const form = formFieldsArray;
    form[index].type = form[index].type === 'text' ? 'password' : 'text';
    setFormFieldsArray([...form])
  }

  return (
    <div className="row">
      {formFieldsArray?.map((data: any, index: number) => (
        <React.Fragment key={index}>
          {data.title === "input" && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-3 ${data?.customParentClass} text-start`}
            >
              {!data?.formFloating && (
                <label className="form-label d-flex justify-content-start ">
                  {data.label}
                  {data.required && !data?.hideMandatoryStar && (
                    <span className="text-danger fw-bold mx-2">*</span>
                  )}
                </label>
              )}
              <input
                type={data.type}
                name={data.name}
                id={data?.id}
                className={`${data.type === "checkbox" ? "" : "form-control border-primary-medium"} ${data.customClass}`}
                placeholder={data.placeholder}
                onChange={(event) => {
                  handleInput(event, index, data);
                }}
                required={data.required}
                min={data?.min}
                maxLength={data.maxLength}
                value={data.value}
                disabled={data?.isDisabled}
              />
              {data?.formFloating && (
                <label className="form-label ms-3 d-flex justify-content-start">
                  {data.label}
                  {data.required && !data?.hideMandatoryStar && (
                    <span className="text-danger fw-bold mx-2">*</span>
                  )}
                </label>
              )}
              <span className="">
                {data?.isCopy && (<span className="" title="Copy">
                  <button className="btn bg-transparent copy-icon-position shadow border-0 mx-2" onClick={(e) => handleCopy(data?.value)}>
                    {/* <i className="bi bi-copy"></i>copy */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                    </svg>
                  </button>
                </span>)}
                {data?.isView && (<span className="" title="View">
                  <button className="btn bg-transparent eye-icon-position shadow border-0 mx-2" onClick={(e) => handleHideAndShow(data, index)}>
                    {data?.type === 'text' ? <i className="bi bi-eye-slash-fill"></i> :
                      <i className="bi bi-eye-fill"></i>}
                  </button>
                </span>)}
              </span>
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}
          {data.title === "dropdown" && (
            <div className={`col-md-${data?.column ? data?.column : `${12 / props?.columns} mb-2`} ${data?.customParentClass}`}>
              <label className="form-label d-flex justify-content-start">
                {data.label} {data.required}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <select
                name={data.name}
                className={`form-select custom-select border-primary-medium ${data.customClass}`}
                onChange={(event) => {
                  handleInput(event, index, data);
                }}
                disabled={data?.isDisabled}
                value={data.value}
              >
                <option value="" disabled={data?.required}>
                  {data.placeholder}
                </option>
                {data.option?.map((option: any, i: number) => {
                  return (
                    <option key={i} value={option?.value}>
                      {option?.label}
                    </option>
                  );
                })}
              </select>
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}
          {data.title === "formArray" && (
            <div className={`col-md-12 mb-2`}>
              <label className="form-label d-flex justify-content-start">
                {data.label}
              </label>
              <div className="px-3 array_form_border">
                {data?.fields?.map((subData: any, subIndex: number) => {
                  return <div key={subIndex} className="row">
                    {subData?.fields?.map((subSubData: any, subSubIndex: number) => {
                      return <React.Fragment key={subSubIndex}>
                        {subSubData.title === "dropdown" && (
                          <div className={`col-md-${12 / props?.columns} mb-2`}>
                            <label className="form-label d-flex justify-content-start">
                              {subSubData.label} {subSubData.required}
                              {subSubData.required && !subSubData?.hideMandatoryStar && (
                                <span className="text-danger fw-bold mx-2">*</span>
                              )}
                            </label>
                            <select
                              name={subSubData.name}
                              className="form-select custom-select border-primary-medium"
                              onChange={(event) => {
                                handleInput(event, index, subSubData);
                              }}
                              value={subSubData.value}
                            >
                              <option value="" disabled={subSubData?.required}>
                                {subSubData.placeholder}
                              </option>
                              {subSubData.option?.map((option: any, i: number) => {
                                return (
                                  <option key={i} value={option?.value}>
                                    {option?.label}
                                  </option>
                                );
                              })}
                            </select>
                            {Object.keys(errors).length > 0 && (
                              <div className="text-danger text-start">
                                {errors[subSubData.name] || ""}
                              </div>
                            )}
                          </div>
                        )}
                        {subSubData.title === "input" && (
                          <div
                            className={`col-md-${subSubData?.column ? subSubData?.column : `${12 / props?.columns}`
                              } mb-3 ${subSubData?.formfloating ? "form-floating" : ""} text-start`}
                          >
                            {!subSubData?.formfloating && (
                              <label className="form-label d-flex justify-content-start ">
                                {subSubData.label}
                                {subSubData.required && !subSubData?.hideMandatoryStar && (
                                  <span className="text-danger fw-bold mx-2">*</span>
                                )}
                              </label>
                            )}
                            <input
                              type={subSubData.type}
                              name={subSubData.name}
                              id={subSubData?.id}
                              className={`${subSubData.type === "checkbox" ? "" : "form-control border-primary-medium"}`}
                              placeholder={subSubData.placeholder}
                              onChange={(event) => {
                                handleSubElementInput(event, index, subIndex, subSubIndex, subSubData, data,);
                              }}
                              required={subSubData.required}
                              min={subSubData?.min}
                              maxLength={subSubData.maxLength}
                              value={subSubData.value}
                            />
                            {subSubData?.formfloating && (
                              <label className="form-label ms-3 d-flex justify-content-start">
                                {subSubData.label}
                                {subSubData.required && !subSubData?.hideMandatoryStar && (
                                  <span className="text-danger fw-bold mx-2">*</span>
                                )}
                              </label>
                            )}
                            {Object.keys(errors).length > 0 && (
                              <div className="text-danger text-start">
                                {errors[subSubData.name] || ""}
                              </div>
                            )}
                          </div>
                        )}
                        {subSubData?.type === 'button' && <div className={`${subSubData?.class}`}>
                          {subSubData?.buttons.map((button: any, btnIndex: number) => {
                            return <button type="submit" key={btnIndex}
                              className={`btn d-block d-lg-inline-block mb-0 mb-lg-0 w-xs-100 rounded-1 ${button?.class}`} onClick={(e) => onFormSubmit(e, button, index, subIndex)} >
                              {/* {props?.loading ? 'Loading...' : button?.title} */}
                              {button?.icons ? <i className={`${button?.icons}`}></i> : button?.title}
                            </button>
                          })}
                        </div>}
                      </React.Fragment>
                    })}
                  </div>
                })}
              </div>
            </div>
          )}
          {data.title === "multiDropdown" && (
            <>
              <div className={`col-md-${12 / props?.columns} mb-2`}>
                <label className="form-label d-flex justify-content-start">
                  {data.label} {data.required}
                  {data.required && (
                    <span className="text-danger fw-bold mx-2">*</span>
                  )}
                </label>
                <Multiselect
                  placeholder={data.placeholder}
                  options={data.option}
                  selectedValues={data?.value}
                  onSelect={(selectedList: any, selectedItem: any) =>
                    onSelect(selectedList, selectedItem, index, data?.name)
                  }
                  onRemove={(selectedList: any, selectedItem: any) =>
                    onRemove(selectedList, selectedItem, index, data?.name)
                  }
                  isObject={true}
                  avoidHighlightFirstOption={true}
                  showCheckbox={true}
                  hidePlaceholder={true}
                  displayValue="label"
                  style={{
                    chips: { background: "#074795" },
                    searchBox: {
                      height: "37px",
                      overflow: "hidden",
                      padding: "0px 10px 0px 10px",
                    },
                  }}
                />
                {Object.keys(errors).length > 0 && (
                  <div className="text-danger text-start">
                    {errors[data.name] || ""}
                  </div>
                )}
              </div>
            </>
          )}
          {data.title === "textarea" && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <textarea
                name={data.name}
                rows={data?.rows}
                className={`${data.type === "checkbox" ? "" : "form-control border-primary-medium"}`}
                placeholder={data.placeholder}
                onChange={(event) => {
                  handleInput(event, index, data);
                }}
                required={data.required}
                maxLength={data.maxLength}
                value={data.value}
              />
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}
          {(data.title === "file" && !data.isMultipleImages) && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              {(data?.value) && data.accept !== 'application/pdf' && <div style={{ height: '150px', width: "200px", marginBottom: image ? "20px" : "" }}>
                <img src={data?.value} alt="image" key={data?.value} className={`rounded ${image ? "my-2" : ""}`} style={{ height: '100%', width: "100%" }} />
              </div>}
              <input className="mt-4 d-none" id={`InputFile${index}`} type="file" name={data.name} accept={data.accept} onChange={(event) => {
                handleInputFileUpload(event, index, data);
              }} />
              <label htmlFor={`InputFile${index}`} className={`btn btn-primary btn-sm f16 px-4 ${image ? "mt-0" : "mt-1"}`} onChange={(event) => {
                handleInputFileUpload(event, index, data);
              }}>
                Upload
              </label>
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )}

          {/* {(data.title === "file" && data.multipleImagesUpload) && (
            <div
              className={`col-md-${data?.column ? data?.column : `${12 / props?.columns}`} mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.label}
                {data.required && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              {(images.length > 0 || data?.value) && data.accept !== 'application/pdf' && (
                <div className="d-flex flex-wrap" style={{ gap: "10px", marginBottom: data?.value?.length > 0 ? "20px" : "" }}>
                  <Carousel showArrows={true} showThumbs={false}>
                  {data?.value?.map(
                    (data: any) => {
                      return (
                        <div className="border rounded-2" style={{ height: '250px', width: "100%" }}>
                          <img src={data} className="d-block " style={{ height: '100%', width: "100%", objectFit: "cover" }} />
                        </div>
                      );
                    }
                  )}
                  </Carousel>
                  {data?.value?.length == 0 && (
                    <div className='text-center my-2'>No records found</div>
                  )}
                </div>
              )}

              <input className="mt-4 d-none" id={`InputFile${index}`} type="file" name={data.name} accept={data.accept} multiple onChange={(event) => {
                handleInputMultipleFileUpload(event, index, data);
              }} />
              <label htmlFor={`InputFile${index}`} className={`btn btn-primary btn-sm f16 px-4 ${data?.value?.length > 0 ? "mt-0" : "mt-1"}`} onChange={(event) => {
                handleInputFileUpload(event, index, data);
              }}>
                Upload
              </label>
              {Object.keys(errors).length > 0 && (
                <div className="text-danger text-start">
                  {errors[data.name] || ""}
                </div>
              )}
            </div>
          )} */}
          {data.title === "toggle" && (
            <div
              className={`col-md-${data?.column
                ? data?.column
                : `${12 / props?.columns}`
                } mb-2 text-start`}
            >
              <label className="form-label d-flex justify-content-start">
                {data.required && !data?.hideMandatoryStar && (
                  <span className="text-danger fw-bold mx-2">*</span>
                )}
              </label>
              <label className="switch">
                <input type="checkbox" />
                <div><span className="slider round"></span></div>
              </label>
            </div>
          )}
          {data?.type === 'button' && <div className={`${data?.class}`}>
            {data?.buttons.map((button: any, btnIndex: number) => {
              return <button type="submit" key={btnIndex}
                className={`btn d-block d-lg-inline-block mb-0 mb-lg-0 w-xs-100 rounded-1 ${button?.class}`} onClick={(e) => onFormSubmit(e, button, index, -1)} disabled={button?.isDisabled} >
                {props?.loading ? 'Loading...' : button?.title}
              </button>
            })}
          </div>}
        </React.Fragment>
      ))}
    </div>
  );
};
