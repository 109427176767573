import { DataTableCol } from "../../../../../components/data-table/types";

export const RevenueSiteDataGridCols: DataTableCol[] = [
  {
    title: "Content",
    control: "content",
    isLink:true,
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Date",
    control: "date",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Service",
    control: "service",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Product Id",
    control: "product_id",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Operator",
    control: "operator",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "Currency",
    control: "currency",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "New Sub count (Total)",
    control: "new_sub_count_total",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "New Sub Revenue",
    control: "new_sub_revenue",
    sortable: true,
    canShowColumn: true,
  },
  {
    title: "New Sub count (Free)",
    control: "new_sub_count_free",
    sortable: false,
    isEdit: true,
    isDelete:true,
    isSettings:false,
    isAlerts:false,
    canShowColumn: true,
  },
  {
    title: "New Sub count (Charged)",
    control: "new_sub_count_charged",
    sortable: false,
    isEdit: true,
    isDelete:true,
    isSettings:false,
    isAlerts:false,
    canShowColumn: true,
  },
  {
    title: "Re-Sub Count",
    control: "re_Sub_count",
    sortable: false,
    isEdit: true,
    isDelete:true,
    isSettings:false,
    isAlerts:false,
    canShowColumn: true,
  },
  {
    title: "Re-Sub Revenue",
    control: "re_sub_revenue",
    sortable: false,
    isEdit: true,
    isDelete:true,
    isSettings:false,
    isAlerts:false,
    canShowColumn: true,
  },
  {
    title: "Unsub Count",
    control: "unsub_count",
    sortable: false,
    isEdit: true,
    isDelete:true,
    isSettings:false,
    isAlerts:false,
    canShowColumn: true,
  },
  {
    title: "Unsub Count(Same Day)",
    control: "unsub_count_same_day",
    sortable: false,
    isEdit: true,
    isDelete:true,
    isSettings:false,
    isAlerts:false,
    canShowColumn: true,
  },
  {
    title: "Total Revenue",
    control: "total_revenue",
    sortable: false,
    isEdit: true,
    isDelete:true,
    isSettings:false,
    isAlerts:false,
    canShowColumn: true,
  },
  {
    title: "Revenue Share",
    control: "revenue_share",
    sortable: false,
    isEdit: true,
    isDelete:true,
    isSettings:false,
    isAlerts:false,
    canShowColumn: true,
  },
  {
    title: "Total Base",
    control: "total_base",
    sortable: false,
    isEdit: true,
    isDelete:true,
    isSettings:false,
    isAlerts:false,
    canShowColumn: true,
  },
];
