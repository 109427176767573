import API from "../utils/axios";
let common = "/v1/api/deployment";

export const GET_DEPLOYMENT_VERSION = (data: any): Promise<any> => {
  return API.get(`${common}/versions`, { params: data });
};

export const DEPLOYMENT_VERSION_CREATE = (data: any): Promise<any> => {
  return API.post(`${common}/versions`, data);
};

export const DEPLOYMENT_VERSION_UPDATE = (id: any, data: any): Promise<any> => {
  return API.put(`${common}/versions/${id}`, data);
};

export const DEPLOYMENT_VERSION_DELETE = (id: any): Promise<any> => {
  return API.delete(`${common}/versions/${id}`);
};
