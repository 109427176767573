import { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { DynamicForm } from '../../components/form-builder/form';
// import Logo from './../../assets/images/blazon-logo.png';
import LogoTwo from './../../assets/images/ampere-logo.jpeg';
import toast from 'react-hot-toast';
import { GET_USER_COUNTRY_MOBILE_CODE, LOGIN } from '../../app/services/common.service';
import CountryCodes from './../../app/utils/mobile-number-country-code.json';


export const Login = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loginFormData, setLoginFormData] = useState<any>([
    {
      title: "dropdown",
      type: "text",
      label: "",
      name: "mobile_country_code",
      formFloating: true,
      value: '',
      option: CountryCodes,
      regex: "",
      required: true,
      placeholder: "Code",
      error: "Please select code",
      hideMandatoryStar: true,
      column: 3,
      paddingZero: true,
      customParentClass: "pe-0 form-floating",
      customClass: "rounded-end-0"
    },
    {
      title: "input",
      type: "text",
      formFloating: true,
      label: "Mobile Number",
      name: "mobile_number",
      value: "",
      required: true,
      regex: /^(?:\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}[\s.-]?\d{0,4}$/,
      maxLength: "15",
      placeholder: "",
      error: "Please enter mobile number",
      submitButtonName: "Login",
      hideMandatoryStar: true,
      column: 9,
      paddingZero: true,
      customParentClass: "ps-0 form-floating",
      customClass: "rounded-start-0"
    },
    {
      title: "input",
      type: "password",
      formFloating: true,
      label: "Password",
      name: "password",
      value: "",
      required: true,
      regex: "",
      maxLength: "",
      placeholder: "",
      error: "Please enter password",
      customParentClass: "form-floating",
      hideMandatoryStar: true,
    },
    {
      type: "button",
      class: "col-md-12 ms-auto d-flex justify-content-center my-2",
      buttons: [
        {
          title: 'Login',
          action: 'add',
          isCheckFormValidation: true,
          class: 'primary-button btn d-block d-lg-inline-block mb-0 mb-lg-0 w-xs-100 rounded-1 btn border-0 btn-primary px-5 btn-sm f16 me-1 text-uppercase fw-bold py-1'
        },
      ]
    }
  ]);
  const navigation = useNavigate();

  const location = useLocation();
  const locationRef = useRef(location);

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  useEffect(() => {

    navigator.geolocation.getCurrentPosition(function (position) {
      var lat = position.coords.latitude;
      var lon = position.coords.longitude;

      // Use a geolocation API to get country code from lat/lon
      var geolocationAPI = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lon}&localityLanguage=en`;

      fetch(geolocationAPI)
        .then((response) => response.json())
        .then((data) => {
          var countryCode = data.countryCode;
          const locationCountry = CountryCodes.find(
            (country: any) => country?.code === countryCode
          );
          const loginForm = loginFormData;
          loginForm[0].value = locationCountry?.dial_code;
          setLoginFormData([...loginForm]);
        });
    });

    const handlePopState = () => {
      // Perform any action you need to do on back button click
    };

    const handleLocationChange = () => {
      if (locationRef.current.pathname !== window.location.pathname) {
        handlePopState();
      }
    };

    window.addEventListener('popstate', handleLocationChange);

    return window.removeEventListener('popstate', handleLocationChange);
  }, []);

  const onFormSubmitEvent = (event: any) => {
    const data = event;
    const formData = data?.formData
    formData.type = "mobilepass"
    formData.email = ""
    delete formData['undefined']
    setLoading(true);
    LOGIN(formData).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.error, { duration: 2000, position: 'top-right', });
        setLoading(false);
      } else {
        if (res) {
          if (res?.data?.user_role === "SUPERADMIN") {
            sessionStorage.setItem('user_uuid', res?.data?.user_uuid)
            sessionStorage.setItem('user_role', res?.data?.user_role);
            sessionStorage.setItem("jwt_token", res?.data?.jwt_token);
            sessionStorage.setItem('user_name', res?.data?.user_name ? res?.data?.user_name : 'Super Admin');
            toast.success(res.message, { duration: 2000, position: 'top-right', })
            navigation("/dashboard/view");
            setLoading(false);
          } else if (res?.data?.user_role === "CUSTOMER") {
            sessionStorage.setItem('user_uuid', res?.data?.user_uuid)
            sessionStorage.setItem('user_role', res?.data?.user_role);
            sessionStorage.setItem("jwt_token", res?.data?.jwt_token);
            sessionStorage.setItem('user_name', res?.data?.user_name ? res?.data?.user_name : 'Super Admin');
            toast.success(res.message, { duration: 2000, position: 'top-right', })
            navigation("/dashboard/view");
            setLoading(false);
          } else {
            toast.error("Invalid Credentials", { duration: 2000, position: 'top-right', })
            navigation("/")
            setLoading(false);
          }
        }
      }
    });
  }

  return (
    <div className="modal login-bg" style={{ display: "block" }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="">
            <div className="primary-bg login-bg rounded-3 d-flex flex-column justify-content-center">
              <div className="">
                <form className="m-4">
                  <div className="Login-form-content">
                    <h2 className="Login-form-title login_logo text-center fw-bold p-5">
                      <img src={LogoTwo} alt="" className="my-4 login-logo rounded-3" />
                    </h2>
                    <h4 className="Login-form-title text-white text-center fw-bold my-4">LOGIN</h4>
                    <DynamicForm
                      userFormData={loginFormData}
                      columns={1}
                      isEdit={false}
                      onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                      customClass={"display-block"}
                      loading={loading}
                      isNotEmptyFormAfterSubmit={true} cancelRoute={undefined}
                    />
                    <div className="flex-column justify-content-center ">
                      <div className="my-2 text-center">
                        <NavLink
                          to="/register"
                          className="text-white "
                        >
                          Register for portal
                        </NavLink>
                      </div>
                    </div>
                    <div className="d-none d-flex flex-column justify-content-center ">
                      <div className="my-2">
                        <NavLink
                          to="/"
                          className="text-decoration-none text-dark"
                        >
                          Forgot password?
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer/> */}
      </div >
    </div >
  )
}