import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { CustomersTabRoutes } from '../../../../../routes/customer-tab-routes';
import { BreadCrumb } from '../../../../../components/bread-crumb';
import { SiteTabRoutes } from '../../../../../routes/site-tabs-routes';
import { GET_USERS } from '../../../../../app/services/users.service';
import { GET_SITES } from '../../../../../app/services/sites.service';
export const SitesTabInfo = () => {
    const params = useParams();
    const location = useLocation();
    const currentPath = location.pathname.split("/");
    const [loading, setLoading] = useState<boolean>(false);

    const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
        {
            label: "Customers",
            value: "Customers",
            routerLink: "/dashboard/customers",
            isActive: false,
        },
        {
            label: "Customer Name",
            value: "Customer",
            routerLink: `/dashboard/customers/${params?.customer_uuid}/sites`,
            isActive: false,
        },
        {
            label: "Customer Name",
            value: "Sites",
            routerLink: `/dashboard/customers/${params?.customer_uuid}/sites`,
            isActive: false,
        },
        {
            label: "Site Name",
            value: "",
            routerLink: `/dashboard/customers/${params?.customer_uuid}/sites/${params?.site_uuid}/subscriptions`,
            isActive: true,
        },
    ]);

    const sitesData = [
        {
            id: "11",
            site: "www.blazon.com",
            site_name: "Blazon",
            country: "Etisalat-UAE",
        },
        {
            id: "12",
            site: "www.playforia.com",
            site_name: "Playforia",
            country: "DU-UAE",
        },
        {
            id: "13",
            site: "www.games.com",
            site_name: "Games",
            country: "Zain-Kuwait",
        },
    ];

    useEffect(() => {
        const customerId = params?.customer_uuid;
        getCustomerDataById(customerId)
        const siteId = params?.site_uuid;
        const filterSiteData = sitesData?.filter((el?: any) => el?.id === siteId);
        getSiteDataById(siteId)
        // const breadcrumbData = breadcrumbInfo;
        // breadcrumbData[3].value = filterSiteData[0]?.site_name
    }, [])

    const getCustomerDataById = (userUuid: any) => {
        setLoading(true);
        GET_USERS({ uuid: userUuid }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const data = breadcrumbInfo;
                    data[1].value = res?.data?.rows[0]?.name;
                    setBreadcrumbInfo([...data]);
                    setLoading(false);
                }
            }
        });
    }

    const getSiteDataById = (siteData: any) => {
        setLoading(true);
        GET_SITES({ user_uuid: params?.customer_uuid, uuid: siteData }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    const response = res?.data?.rows[0];
                    const data = breadcrumbInfo;
                    data[3].value = response?.code
                    setBreadcrumbInfo([...data])
                    setLoading(false);
                }
            }
        });
    }


    const tabs = [
        {
            id: "1",
            name: "Subscriptions",
            link: `/dashboard/customers/${params?.customer_uuid}/sites/${params?.site_uuid}/subscriptions`,
        },
        {
            id: "2",
            name: "Content",
            link: `/dashboard/customers/${params?.customer_uuid}/sites/${params?.site_uuid}/content`,
        },
        // {
        //     id: "3",
        //     name: "Integrations",
        //     link: `/dashboard/customers/${params?.customer_uuid}/sites/${params?.site_uuid}/integrations`,
        // },
        // {
        //     id: "4",
        //     name: "Reports",
        //     link: `/dashboard/customers/${params?.customer_uuid}/sites/${params?.site_uuid}/reports`,
        // },
    ];

    return (
        <div className='col-md-12 mt-3 content-bg-color'>
            <div className='row mb-3'>
                <div className='col-md-12 px-4'>
                    <BreadCrumb breadCrumbList={breadcrumbInfo}></BreadCrumb>
                </div>
            </div>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {tabs.map((data: any, index: number) => {
                    return <NavLink key={index} to={data?.link} className={`nav-link text-dark px-4 fs-13
                    ${currentPath.length === 6 && data?.name === "Subscriptions"
                            ? "active"
                            : ""
                        } `}>{data?.name}</NavLink>
                })}
            </ul>
            <div className="tab-content shadow p-0" id="myTabContent">
                <SiteTabRoutes />
            </div>
        </div>
    )
}
