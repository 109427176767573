import React, { useEffect, useState } from 'react'
import { DataTable } from '../../../components/data-table'
import { ContactUsDataGridCols } from './contact-us-data-grid-cols';
import { DynamicForm } from '../../../components/form-builder/form';
import { useNavigate } from 'react-router-dom';
import { GET_USERS, USER_CREATE, USER_DELETE, USER_UPDATE } from '../../../app/services/users.service';
import CountryCodes from '../../../app/utils/mobile-number-country-code.json';
import toast from 'react-hot-toast';
import { Loader } from '../../../components/loader';
import { Search } from '../../../components/search/search';
import { GET_CONTACT_FORM } from '../../../app/services/contact-us-form.service';

export const ContactUsPage = () => {
    const navigate = useNavigate();
    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);

    useEffect(() => {
        getCustomers(pageCount);
    }, [])


    const getCustomers = (data: any) => {
        setLoading(true);
        GET_CONTACT_FORM(data).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setTableListData(res?.data);
                    setLoading(false);
                }
            }
        });
    }

    const onActions = (data: any) => {
        if (data?.type === "link") {
            navigate(`/dashboard/contact-us/${data?.data?.uuid}`)
        }
    }
    const onSelectPage = (data: any) => {
        const pageData = pageCount;
        pageData.page = data;
        setPageCount(pageData);
        getCustomers(pageData);
    };

    const onPageChange = (data: any) => {
        const pageData = pageCount;
        pageData.limit = data;
        setPageCount(pageData);
        getCustomers(pageData);
    };

    const onCustomerFormSubmitEvent = (event: any) => {

    }

    const onSearch = (value: any) => {
        const pageData = pageCount;
        pageData.search = value;
        setPageCount(pageData);
        getCustomers(pageData);
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Contact Us Users</h4>
                </div>
            </div>
            <div className='mt-2'>
                <div className='w-100 mt-4 pt-1'>
                    <Search onSearch={(e) => onSearch(e)} subscriptionSearch={true} />
                </div>
            </div>
            <Loader LoadingReducer={loading} />
            <div className='row my-3'>
                <div className='col-md-12 px-0'>
                    <DataTable
                        tableData={tableListData}
                        TableCols={ContactUsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    />
                </div>
            </div>
        </div>
    )
}
