import React from 'react'

interface Props {
    contentData?: any;
    customClass?: any;
    paymentPartners?: any;
    gamesContents?: any;
    blogsContent?: any;
    cardAction?: (data: any) => void;
}
export const CustomCard = (props: Props) => {
    const onCardAction = (e: any, data: any, type: any) => {
        e.stopPropagation();
        const actionData = { data: data, type: type }
        if (props?.cardAction) {
            props?.cardAction(actionData)
        }
    }
    return (
        <>
            {props?.contentData?.map((data: any, index: number) => (
                <div className={`${props?.customClass ? "col-lg-4" : "col-lg-12"} mb-4 cursor-pointer px-3 pt-3`} key={index}>
                    <a className={`text-dark service-list d-flex justify-content-center align-items-center ${props?.gamesContents ? "" : "pt-3 pb-2"} ${data.class} box-shadow`} >
                        <div className={`text-center service-list-item w-100  ${props?.paymentPartners || props?.gamesContents || props?.blogsContent ? "mx-auto " : "d-flex justify-content-between  px-3"}`}>
                            {!props?.paymentPartners && (<>
                                <div>
                                    <div className='text-start fs-5 fw-bold'>{data?.content_title}</div>
                                    <div className='text-start fs-6'>{data?.description}</div>
                                </div>
                                <div className='fw-bold d-flex align-items-center fs-3'>{data?.count}</div>
                            </>)}
                            {props?.paymentPartners && (<div className='d-flex justify-content-center align-items-center' onClick={(e) => onCardAction(e, data, "View")}>
                                <img src={data?.image_url} className='payment-partner-image' />
                            </div>)}
                            {props?.gamesContents && <div className='align-items-center'>
                                <div className='card-image' onClick={(e) => onCardAction(e, data, "Game View")}>
                                    {/* <div className='text-end'><i className="bi bi-trash-fill text-danger p-3 rounded" onClick={(e) => onCardAction(e, data, index, "Game Delete")}></i></div> */}
                                    <img src={data?.media_url} className='games-content-image rounded-3' />
                                </div>
                                <div className='my-2 fw-bold fs-3'>{data.name}</div>
                            </div>}
                            {props?.blogsContent && <div className='align-items-center'>
                                <div className='blog-view' onClick={(e) => onCardAction(e, data, "Blog View")}>
                                    <div className='text-end'><i className="bi bi-trash-fill text-danger p-3 rounded" onClick={(e) => onCardAction(e, data, "Blog Delete")}></i></div>
                                    <div className='my-2 fw-bold fs-3' title={data?.blog_title}>{data?.blog_title?.length > 20 ? `${data?.blog_title?.substring(0, 20)}...` : `${data?.blog_title}`}</div>
                                    <div className='my-2 fw-bold fs-5'>{data?.blog_type}</div>
                                </div>
                                <div className='my-2 text-center d-flex justify-content-center fw-bold fs-3'>{data?.title}</div>
                            </div>}
                            {props?.paymentPartners && (<div>
                                <i className='bi bi-pencil-square me-2' onClick={(e) => onCardAction(e, data, "Edit")}></i>
                                <i className='bi bi-trash text-danger' onClick={(e) => onCardAction(e, data, "Delete")}></i>
                            </div>)}
                        </div>
                    </a>
                </div>
            ))}
        </>
    )
}
