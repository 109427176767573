import React, { useEffect, useState } from 'react'
import { DynamicForm } from '../../../components/form-builder/form';
import Countries from '../../../app/utils/countries.json'
import { GET_PAYMENT_PARTNERS, PAYMENT_PARTNER_CREATE, PAYMENT_PARTNER_UPDATE } from '../../../app/services/payment-partners.service';
import toast from 'react-hot-toast';
import { Loader } from '../../../components/loader';
import { useNavigate, useParams } from 'react-router-dom';
export const PaymentPartnersForm = () => {
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<any>([]);
    const navigation = useNavigate();

    const [formData, setFormData] = useState<any>([
        {
            title: "dropdown",
            type: "dropdown",
            label: "Type",
            name: "type",
            formFloating: false,
            value: '',
            option: [{
                label: 'PAYMENT GATEWAY',
                value: 'PAYMENT_GATEWAY'
            }],
            required: true,
            placeholder: "Please select type",
            error: "Please select type",
            hideMandatoryStar: false,
        },
        {
            title: "input",
            type: "text",
            label: "Code",
            name: "code",
            formFloating: false,
            value: '',
            regex: /^([A-Z]_*)+$/,
            required: true,
            placeholder: "Please enter code",
            error: "Please enter code",
            hideMandatoryStar: false,
        },
        {
            title: "dropdown",
            type: "dropdown",
            label: "Country",
            name: "country",
            formFloating: false,
            value: '',
            option: Countries,
            required: true,
            placeholder: "Please select country",
            error: "Please select country",
            hideMandatoryStar: false,
        },
        {
            title: "file",
            type: "file",
            label: "Image",
            name: "image_url",
            value: "",
            required: false,
            regex: "",
            maxLength: "",
            isMultipleImages: false,
            placeholder: "Upload image",
            error: "Please upload image",
            hideMandatoryStar: true
        },
        {
            title: "input",
            type: "text",
            label: "Host Name",
            formFloating: false,
            name: "hostname",
            value: '',
            required: false,
            placeholder: "Please enter host name",
            error: "Please enter host name",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "text",
            label: "Uri",
            formFloating: false,
            name: "uri",
            value: '',
            regex: /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:\/?#@!$&'()*+,;=%]*)?$/,
            required: false,
            placeholder: "Please enter uri",
            error: "Please enter uri",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "text",
            label: "User Name",
            name: "username",
            formFloating: false,
            value: '',
            regex: "",
            required: false,
            placeholder: "Please enter username",
            error: "Please enter username",
            hideMandatoryStar: true,
        },
        {
            title: "input",
            type: "text",
            label: "Password",
            name: "password",
            formFloating: false,
            value: '',
            regex: "",
            required: false,
            placeholder: "Please enter password",
            error: "Please enter password",
            hideMandatoryStar: true,
        },
        {
            title: "formArray",
            label: 'Header',
            name: 'headers',
            fields: [
                {
                    fields: [
                        {
                            title: "input",
                            type: "text",
                            label: "Key",
                            formFloating: false,
                            name: "key",
                            value: '',
                            required: false,
                            placeholder: "Please enter key",
                            error: "Please enter key",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            title: "input",
                            type: "text",
                            label: "Value",
                            formFloating: false,
                            name: "Value",
                            value: '',
                            required: false,
                            placeholder: "Please enter value",
                            error: "Please enter value",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            type: "button",
                            class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
                            buttons: [
                                {
                                    title: 'Add',
                                    icons: 'bi bi-plus-circle',
                                    action: 'addHeader',
                                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                                }
                            ]
                        },
                    ]
                }
            ]
        },
        {
            title: "formArray",
            label: 'Meta Data',
            name: 'metadata',
            fields: [
                {
                    fields: [
                        {
                            title: "input",
                            type: "text",
                            label: "Key",
                            formFloating: false,
                            name: "key",
                            value: '',
                            required: true,
                            placeholder: "Please enter key",
                            error: "Please enter key",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            title: "input",
                            type: "text",
                            label: "Value",
                            formFloating: false,
                            name: "Value",
                            value: '',
                            required: true,
                            placeholder: "Please enter value",
                            error: "Please enter value",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            type: "button",
                            class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
                            buttons: [
                                {
                                    title: 'Add',
                                    icons: 'bi bi-plus-circle',
                                    action: 'addHeader',
                                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                                }
                            ]
                        },
                    ]
                }
            ]
        },
        {
            type: "button",
            class: "col-md-12 d-flex justify-content-end my-2",
            buttons: [
                {
                    title: 'Create',
                    action: 'add',
                    isCheckFormValidation: true,
                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                },
                {
                    title: 'Cancel',
                    action: 'cancel',
                    class: 'btn btn-outline-primary px-4 btn-sm f16'
                }
            ]
        },
    ])
    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [tableListData, setTableListData] = useState<any>([]);
    const params = useParams();

    useEffect(() => {
        if (params.uuid !== '1') {
            getPaymentPartner(pageCount);
        }

    }, [])


    const getPaymentPartner = (data: any) => {
        data.uuid = params.uuid;
        setLoading(true);
        GET_PAYMENT_PARTNERS(data).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setLoading(false);
                    setIsEdit(true);
                    setSelectedData(res?.data?.rows[0])
                    const formDataObj = formData;
                    formDataObj?.forEach((element: any) => {
                        if (element?.type === "button") {
                            for (let i = 0; i < element?.buttons?.length; i++) {
                                const ele = element?.buttons;
                                ele[0].title = "Update";
                                ele[0].action = "edit";
                            }
                        }
                        if (element.name === 'headers') {
                            const headerData = res?.data?.rows[0][element.name];
                            element.fields = [];
                            for (var key in headerData) {
                                if (headerData.hasOwnProperty(key)) {
                                    element.fields.push(
                                        {
                                            fields: [
                                                {
                                                    title: "input",
                                                    type: "text",
                                                    label: "Key",
                                                    formFloating: false,
                                                    name: "key",
                                                    value: key,
                                                    required: true,
                                                    placeholder: "Please enter key",
                                                    error: "Please enter key",
                                                    hideMandatoryStar: true,
                                                    column: 5
                                                },
                                                {
                                                    title: "input",
                                                    type: "text",
                                                    label: "Value",
                                                    formFloating: false,
                                                    name: "Value",
                                                    value: headerData[key],
                                                    required: true,
                                                    placeholder: "Please enter value",
                                                    error: "Please enter value",
                                                    hideMandatoryStar: true,
                                                    column: 5
                                                },
                                                {
                                                    type: "button",
                                                    class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
                                                    buttons: [
                                                        {
                                                            title: 'Add',
                                                            icons: 'bi bi-plus-circle',
                                                            action: 'addHeader',
                                                            class: 'btn btn-primary px-4 btn-sm f16 me-1'
                                                        }
                                                    ]
                                                },
                                            ]
                                        }
                                    )
                                }
                            }
                            if (element.fields.length > 1) {
                                element.fields?.forEach((subElement: any, index: number) => {
                                    if ((element.fields.length - 1) !== index)
                                        subElement.fields[2].buttons = [
                                            {
                                                title: 'Delete',
                                                icons: 'bi bi-trash',
                                                action: 'deleteHeader',
                                                class: 'btn btn-danger px-4 btn-sm f16 me-1'
                                            }
                                        ]
                                });
                            }

                        } else if (element.name === 'metadata') {
                            const keyData: any = [];
                            const headerData = res?.data?.rows[0][element.name];
                            element.fields = [];
                            for (var key in headerData) {
                                if (headerData.hasOwnProperty(key)) {
                                    element.fields.push(
                                        {
                                            fields: [
                                                {
                                                    title: "input",
                                                    type: "text",
                                                    label: "Key",
                                                    formFloating: false,
                                                    name: "key",
                                                    value: key,
                                                    required: true,
                                                    placeholder: "Please enter key",
                                                    error: "Please enter key",
                                                    hideMandatoryStar: true,
                                                    column: 5
                                                },
                                                {
                                                    title: "input",
                                                    type: "text",
                                                    label: "Value",
                                                    formFloating: false,
                                                    name: "Value",
                                                    value: headerData[key],
                                                    required: true,
                                                    placeholder: "Please enter value",
                                                    error: "Please enter value",
                                                    hideMandatoryStar: true,
                                                    column: 5
                                                },
                                                {
                                                    type: "button",
                                                    class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
                                                    buttons: [
                                                        {
                                                            title: 'Add',
                                                            icons: 'bi bi-plus-circle',
                                                            action: 'addHeader',
                                                            class: 'btn btn-primary px-4 btn-sm f16 me-1'
                                                        }
                                                    ]
                                                },
                                            ]
                                        }
                                    )
                                }
                            }

                            if (element.fields.length > 1) {
                                element.fields?.forEach((subElement: any, index: number) => {
                                    if ((element.fields.length - 1) !== index)
                                        subElement.fields[2].buttons = [
                                            {
                                                title: 'Delete',
                                                icons: 'bi bi-trash',
                                                action: 'deleteHeader',
                                                class: 'btn btn-danger px-4 btn-sm f16 me-1'
                                            }
                                        ]
                                });
                            }
                        } else {
                            element.value = res?.data?.rows[0][element.name];
                        }
                    });
                    setFormData([...formDataObj]);
                }
            }
        });
    }

    const onPaymentPartnerFormSubmitEvent = (event: any) => {
        const data = event;
        if (data?.type === "add") {
            const formData = event?.formData;
            if (formData?.headers) {
                const header: any = {}
                formData?.headers.forEach((element: any) => {
                    header[element.key] = element.value
                });
                formData.headers = header;
            }
            if (formData?.metadata) {
                const metadata: any = {}
                formData?.metadata.forEach((element: any) => {
                    metadata[element.key] = element.value
                });
                formData.metadata = metadata;
            }
            delete formData['undefined'];
            setLoading(true);
            PAYMENT_PARTNER_CREATE(formData).then((res: any) => {
                if (res?.status === "fail") {
                    setLoading(false);
                    toast.error(res.error, { position: "top-right" });

                } else {
                    if (res) {
                        toast.success(res.message, { position: 'top-right' })
                        setLoading(false);
                        navigation(`../payment-partners`)
                    }
                }
            });
        } else if (data?.type === "edit") {
            const formData = data?.formData;
            if (formData?.headers) {
                const header: any = {}
                formData?.headers.forEach((element: any) => {
                    header[element.key] = element.value
                });
                formData.headers = header;
            } if (formData?.metadata) {
                const metadata: any = {}
                formData?.metadata.forEach((element: any) => {
                    metadata[element.key] = element.value
                });
                formData.metadata = metadata;
            }
            delete formData['undefined'];
            setLoading(true);
            PAYMENT_PARTNER_UPDATE(selectedData?.uuid, formData).then((res: any) => {
                if (res.status === "fail") {
                    toast.error(res.error, { position: "top-right" });
                    setLoading(false);
                } else {
                    if (res) {
                        navigation(`../payment-partners`);
                        toast.success(res.message, { position: 'top-right' })
                        setLoading(false);
                    }
                }
            });
        } else if (data?.type === "cancel") {
            navigation(`../payment-partners`)
        } else if (data?.type === 'addHeader') {
            const form = formData;
            form[event.index].fields.push(
                {
                    fields: [
                        {
                            title: "input",
                            type: "text",
                            label: "Key",
                            formFloating: false,
                            name: "key",
                            value: '',
                            required: true,
                            placeholder: "Please enter key",
                            error: "Please enter key",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            title: "input",
                            type: "text",
                            label: "Value",
                            formFloating: false,
                            name: "Value",
                            value: '',
                            required: true,
                            placeholder: "Please enter value",
                            error: "Please enter value",
                            hideMandatoryStar: true,
                            column: 5
                        },
                        {
                            type: "button",
                            class: "col-md-2 d-flex justify-content-end custom_form_btn mt-4 pt-2",
                            buttons: [
                                {
                                    title: 'Add',
                                    icons: 'bi bi-plus-circle',
                                    action: 'addHeader',
                                    class: 'btn btn-primary px-4 btn-sm f16 me-1'
                                }
                            ]
                        },
                    ]
                }
            )
            form[event.index].fields?.forEach((element: any, index: number) => {
                if ((form[event.index].fields.length - 1) !== index)
                    element.fields[2].buttons = [
                        {
                            title: 'Delete',
                            icons: 'bi bi-trash',
                            action: 'deleteHeader',
                            class: 'btn btn-danger px-4 btn-sm f16 me-1'
                        }
                    ]
            });
            setFormData([...form])
        } else if (data?.type === 'deleteHeader') {
            const form = formData;
            form[event.index].fields.splice(event.sunIndex, 1);
            form[event.index].fields?.forEach((element: any, index: number) => {
                if ((form[event.index].fields.length - 1) !== index)
                    element.fields[2].buttons = [
                        {
                            title: 'Delete',
                            icons: 'bi bi-trash',
                            action: 'deleteHeader',
                            class: 'btn btn-danger px-4 btn-sm f16 me-1'
                        }
                    ]
            });
            setFormData([...form]);
        }
    }
    return (
        <div className='container-fluid px-4 content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Create Payment Partner</h4>
                </div>
            </div>
            <div className='row mt-3'>
                <DynamicForm
                    userFormData={formData}
                    columns={2}
                    isEdit={isEdit}
                    onFormSubmitEvent={(e) => onPaymentPartnerFormSubmitEvent(e)}
                    customClass={"display-block"}
                    isNotEmptyFormAfterSubmit={true} cancelRoute={undefined} />
            </div>
        </div>
    )
}
