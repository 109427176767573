import React from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { ContentTabRoutes } from '../../../../routes/content-tab-routes'

export const ContentTabInfo = () => {
    const location = useLocation();
    const currentPath = location.pathname.split("/");
    const userRole = sessionStorage?.getItem('user_role');
    const params = useParams();
    const tabs = [
        {
            id: "1",
            name: "Game",
            link: params?.customer_uuid && params?.site_uuid ? `/dashboard/customers/${params?.customer_uuid}/sites/${params?.site_uuid}/content/game` : params?.customer_site_uuid ? `/dashboard/sites/${params?.customer_site_uuid}/content/game` : `/dashboard/content/game`,
        },
        {
            id: "2",
            name: "Video",
            link: params?.customer_uuid && params?.site_uuid ? `/dashboard/customers/${params?.customer_uuid}/sites/${params?.site_uuid}/content/video` : params?.customer_site_uuid ? `/dashboard/sites/${params?.customer_site_uuid}/content/video` : `/dashboard/content/video`,
        },
        {
            id: "3",
            name: "Blog",
            link: params?.customer_uuid && params?.site_uuid ? `/dashboard/customers/${params?.customer_uuid}/sites/${params?.site_uuid}/content/blog` : params?.customer_site_uuid ? `/dashboard/sites/${params?.customer_site_uuid}/content/blog` : `/dashboard/content/blog`,
        },
    ]
    return (
        <div className='col-md-12 mt-3 p-2'>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                {tabs.map((data: any, index: number) => {
                    return <NavLink key={index} to={data?.link} className={`nav-link text-dark px-4 fs-13
                    ${currentPath.length === (userRole === "SUPERADMIN" ? 7 : userRole === "CUSTOMER" ? 5 : 3) && data?.name === "Game"
                            ? "active"
                            : ""
                        }`}>{data?.name}</NavLink>
                })}
            </ul>
            <div className="tab-content shadow" id="myTabContent">
                <ContentTabRoutes />
            </div>
        </div>
    )
}
