import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";

import { RevenueDataGridCols } from './revenue-dashboard-table-columns';
import { DataTable } from '../../../../components/data-table';
import { DynamicForm } from '../../../../components/form-builder/form';
import { useNavigate } from 'react-router-dom';


export const RevenueDashboard = () => {
  const userRole = sessionStorage?.getItem("user_role");
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const Navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false);

  const [revenueReportsForm, setRevenueReportsForm] = useState<any>([
    {
      title: "dropdown",
      type: "text",
      label: "Customers",
      name: "Customers",
      option: [
        {
          label: "Prasanth",
          value: "Prasanth",
        },
        { label: "Vignesh", value: "Vignesh" },
        {
          label: "Hari Babu",
          value: "Hari Babu",
        },
        {
          label: "Subba Reddy",
          value: "Subba Reddy",
        },
      ],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Customers",
      error: "Please select Customers",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
    },
    {
      title: "input",
      type: "date",
      label: "Dates",
      name: "start_time",
      formFloating: false,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please select start time",
      error: "Please select start time",
      hideMandatoryStar: true,
    },
    {
      title: "dropdown",
      type: "text",
      label: "Products",
      name: "products",
      option: [
        { label: "TyShen Kids[etisalat-uae]", value: "TyShen Kids[etisalat-uae]" },
        { label: "Foodiee[ord-oman]", value: "Foodiee[ord-oman]" },
        { label: "mobilearn Daily[mobily-ksa]", value: "mobilearn Daily[mobily-ksa]" },
        { label: "mobilearn Daily[mobily-ksa]", value: "mobilearn Daily[mobily-ksa]" },
        { label: "WHYRD games[stc-ksa]", value: "WHYRD games[stc-ksa]" },
        { label: "mobillearn[zain-ksa]", value: "mobillearn[zain-ksa]" },
        { label: "Foodiee Daily[voda-egy]", value: "Foodiee Daily[voda-egy]" },
      ],
      value: '',
      regex: "",
      required: true,
      placeholder: "Please select products",
      error: "Please select products",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
      // submitButtonName: "SEARCH",
    },
    {
      title: "input",
      type: "date",
      label: "Dates",
      name: "start_time",
      formFloating: false,
      value: '',
      regex: "",
      required: true,
      placeholder: "Please select start time",
      error: "Please select start time",
      hideMandatoryStar: true,
    },
    {
      type: "button",
      class: "col-md-4 d-flex justify-content-start my-2 mb-4",
      buttons: [
        {
          title: 'Search',
          action: 'add',
          class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 '
        },
        {
          title: 'Cancel',
          action: 'cancel',
          class: 'btn btn-outline-primary px-4 btn-sm f16 height_30'
        }
      ]
    },
  ])

  useEffect(() => {
    const data = revenueReportsForm;
    if (userRole !== "SUPER_ADMIN") {
      const updatedForm = [...revenueReportsForm];
      updatedForm.splice(0, 1); // Remove the first element
      setRevenueReportsForm(updatedForm);
    }
  }, [userRole])


  const onFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {

    } else if (data?.type === "cancel") {

    }
  }

  const [optionsMixedChart] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        columnWidth: "50%"
      }
    },
    stroke: {
      width: [4, 0, 0]
    },
    xaxis: {
      categories: ["site 1", "site 2", "site 3", "site 4", "site 5", "site 6", "site 7", "site 8"]
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8
      }
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 100
    }
  });
  const [seriesMixedChart, setSeriesMixedChart] = useState([
    {
      name: "New Subscription",
      type: "line",
      data: [30, 40, 25, 50, 49, 21, 70, 51]
    },
    {
      name: "Unsubscription",
      type: "column",
      data: [23, 12, 54, 61, 32, 56, 81, 19]
    },
    {
      name: "Renewals",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43]
    },
    {
      name: "Total Revenue",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43]
    }
  ]);

  const ashramsData = {
    data: [
      {
        id: "1",
        site: "site 1",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "2",
        site: "site 2",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "3",
        site: "site 3",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "4",
        site: "site 4",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "5",
        site: "site 5",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "6",
        site: "site 6",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "7",
        site: "site 7",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
      {
        id: "8",
        site: "site 8",
        date: "2024-20-01",
        service: "TyShen Kids",
        product_id: "1114",
        operator: "etisalatuae",
        currency: "AED",
        new_sub_count_total: "151",
        new_sub_revenue: "0",
        new_sub_count_free: "151",
        new_sub_count_charged: "0",
        re_Sub_count: "632",
        re_sub_revenue: "1996.77",
        unsub_count: "77",
        unsub_count_same_day: "6",
        total_revenue: "1996.77",
        revenue_share: "0",
        total_base: "8857",
      },
    ],
  };

  const onActions = (data: any) => {
    if (data.type === "link") {
      Navigate(`/dashboard/reports/revenue/${data.data.id}/revenue-site`)
    }
  }
  const onPageChange = (data: any) => {
    // const pageData = pageCount;
    // pageData.limit = data;
    // setPageCount(pageData);
    // getReports();
  };

  const getReports = () => {
    setLoading(true);
    // GET_ASHRAMAM(pageCount).then((res: any) => {
    //     if (res.status === "fail") {
    //         toast.error(res.message, { position: "top-right" });
    //         setLoading(false);
    //     } else {
    //         if (res) {
    //             setAshramsData(res.data);
    //             setLoading(false);
    //         }
    //     }
    // });
  }

  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getReports();
  };

  return (
    <div>
      <div className='sub-header-primary-color py-2'>
        <div className='h-25  ms-2 text-white border-start border-3 border-danger'>
          {/* <h4 className='text-start ps-3'>Revenue Dashboard</h4> */}
          <div className='text-start  ps-3'>Revenue Analysis (Disclaimer:Reports and revenue figures displayed here are only for marketing purpose only.For final pay-outs Telco's provided report will be applicable.)</div>
        </div>
      </div>
      <div className='col-md-12'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='card rounded-0 bg-white'>
                <div className='card-header text-start py-3'>
                  Revenue Reports
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <DynamicForm
                      userFormData={revenueReportsForm}
                      columns={3}
                      cancelRoute={""}
                      isEdit={false}
                      custom_button_text={'Login'}
                      onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                      customClass={"display-block"}
                      loading={""}
                      isNotEmptyFormAfterSubmit={true}
                    />
                    {/* <div className='mb-2 mb-3'>
                                    <button className='btn btn-secondary  float-end mx-2'>Reset</button>
                                    <button className='btn btn-primary float-end mx-2'><i className="bi bi-search"></i> Search </button>
                                </div> */}
                    <div className="px-2">

                      <div className="row">
                        <div className="col-12 mixed-chart">
                          <div className='card-header text-start py-3 '>
                            <i className="bi bi-postcard"></i> <span className='text-start '>  Revenue Chart </span>
                          </div>
                          <Chart
                            options={optionsMixedChart}
                            series={seriesMixedChart}
                            type="line"
                            height={500}
                          />
                        </div>
                      </div>
                      <div className='card-header text-start py-3 '>
                        <i className="bi bi-postcard"></i><span className='text-start '>  Revenue Table </span>
                        <button className='float-end btn btn-outline-primary py-1 '> Export <i className="bi bi-cloud-arrow-up"></i></button>
                      </div>
                      <DataTable
                        tableData={ashramsData}
                        TableCols={RevenueDataGridCols}
                        onActions={onActions}
                        onPageChange={onPageChange}
                        onSelectPageChange={(e) => onSelectPageinator(e)}
                        pageCount={pageCount} />

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
