import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useNavigate, useParams } from "react-router-dom";
import { DynamicForm } from "../../../../../components/form-builder/form";
import { DataTable } from "../../../../../components/data-table";
import { BreadCrumb } from "../../../../../components/bread-crumb";
import { ROISiteDataGridCols } from "./roi-table-col-grid";

export const ROISite = () => {
  const [pageCount, setPageCount] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const Navigate = useNavigate();
  const params = useParams();

  const [breadcrumbInfo, setBreadcrumbInfo] = useState<any>([
    {
        label: "ROI Reports",
        value: "ROI Reports",
        routerLink: "/dashboard/reports/roi", 
        isActive: false,
    },
    {
        label: "Customer Name",
        value: "Customer : ",
        routerLink: ``,
        isActive: true,
    },
]);
  const [revenueReportsForm, setRevenueReportsForm] = useState<any>([
    {
      title: "dropdown",
      
      type: "text",
      label: "Content Type",
      name: "content",
      option: [
        {
          label: "Game",
          value: "game",
        },
        { label: "Video", value: "video" },
        {
          label: "Blog",
          value: "blog",
        },
      ],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Content Type",
      error: "Please select Content Type",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
    },
    {
      title: "dropdown",
      
      type: "text",
      label: "Contents",
      name: "Contents",
      option: [
        {
          label: "Content 1",
          value: "content1",
        },
        { label: "Content 2", value: "Content2" },
        {
          label: "Content 3",
          value: "Content3",
        },
        {
          label: "Content 4",
          value: "Content4",
        },
        { label: "Content 5", value: "Content5" },
      ],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Contents",
      error: "Please select Contents",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
    },
    {
      title: "input",
      
      type: "date",
      label: "Subscription Dates",
      name: "subscription_dates",
      formFloating: false,
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Subscription Dates",
      error: "Please select Subscription Dates",
      hideMandatoryStar: true,
    },
    {
      title: "input",
      
      type: "date",
      label: "Charging Dates",
      name: "charging_dates",
      formFloating: false,
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Charging Dates",
      error: "Please select Charging Dates",
      hideMandatoryStar: true,
    },
    {
      title: "dropdown",
      
      type: "text",
      label: "Partner",
      name: "partner",
      option: [
        { label: "Fortuner", value: "Fortuner" },
        { label: "BMW", value: "BMW" },
        { label: "Volvo", value: "Volvo" },
        { label: "Thar", value: "Thar" },
        { label: "Audi", value: "Audi" },
        { label: "Kia", value: "Kia" },
        { label: "Tata", value: "Tata" },
      ],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Partner",
      error: "Please select Partner",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
      // submitButtonName: "SEARCH",
    },
    {
      title: "input",
      
      type: "number",
      label: "Campaign",
      name: "campaign",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "",
      hideMandatoryStar: true,
    },
    {
      title: "dropdown",
      
      type: "text",
      label: "Operator",
      name: "operator",
      option: [
        { label: "Fortuner", value: "Fortuner" },
        { label: "BMW", value: "BMW" },
        { label: "Volvo", value: "Volvo" },
        { label: "Thar", value: "Thar" },
        { label: "Audi", value: "Audi" },
        { label: "Kia", value: "Kia" },
        { label: "Tata", value: "Tata" },
      ],
      value: "",
      regex: "",
      required: true,
      placeholder: "Please select Operator",
      error: "Please select Operator",
      hideMandatoryStar: true,
      isParentDropDown: false,
      isApiCall: false,
      // submitButtonName: "SEARCH",
    },
    {
      title: "input",
      
      type: "number",
      label: "CPA (in $)",
      name: "cpa",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "Enter CPA",
      hideMandatoryStar: true,
    },
    {
      title: "input",
      
      type: "number",
      label: "Conversion Rate For USD",
      name: "conversion_rate_for_USD",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "Enter rate for USD",
      hideMandatoryStar: true,
    },

    {
      title: "input",
      
      type: "number",
      label: "Partner Share (in %)",
      name: "partner_share",
      formFloating: false,
      value: "",
      regex: "",
      required: false,
      placeholder: "Enter Partner Share %",
      hideMandatoryStar: true,
    },
    {
      type: "button",
      class: "col-md-8 d-flex justify-content-end my-2 mt-3 pt-1",
      buttons: [
          {
              title: 'Search',
              action: 'add',
              isCheckFormValidation: true,
              class: 'btn btn-primary px-4 btn-sm f16 me-1 height_30 mt-3'
          },
          {
              title: 'Cancel',
              action: 'cancel',
              class: 'btn btn-outline-primary px-4 btn-sm f16 height_30 mt-3'
          }
      ]
  },
  ]);

  const [optionsMixedChart] = useState({
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    stroke: {
      width: [4, 0, 0],
    },
    xaxis: {
      categories: [
        "content 1",
        "content 2",
        "content 3",
        "content 4",
        "content 5",
        "content 6",
        "content 7",
        "content 8",
      ],
    },
    markers: {
      size: 6,
      strokeWidth: 3,
      fillOpacity: 0,
      strokeOpacity: 0,
      hover: {
        size: 8,
      },
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 100,
    },
    plugins: {
      legend: {
        position: "top", // Placing legend at the top
      },
    },
  });

  const [seriesMixedChart, setSeriesMixedChart] = useState([
    {
      name: "New Subscription",
      type: "line",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
    {
      name: "Unsubscription",
      type: "column",
      data: [23, 12, 54, 61, 32, 56, 81, 19],
    },
    {
      name: "Renewals",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43],
    },
    {
      name: "Total Revenue",
      type: "column",
      data: [62, 12, 45, 55, 76, 41, 23, 43],
    },
  ]);

  const onFormSubmitEvent = (event: any) => {
    const data = event;
    if (data?.type === "add") {
    } else if (data?.type === "cancel") {
    }
  };

  const SiteData = [
    {
      id: "1",
      content: "content 1",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "2",
      content: "content 2",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "3",
      content: "content 3",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "4",
      content: "content 4",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "5",
      content: "content 5",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "6",
      content: "content 6",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "7",
      content: "content 7",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "8",
      content: "content 8",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    ];
    const tableData={
        data:SiteData
      }
  const onActions = (data: any) => {
    if (data.type === "link") {
      Navigate(`/dashboard/reports/roi/${params?.site_id}/roi-site/${data.data.id}/roi-content`)
    }
  };
  const onPageChange = (data: any) => {};
  const getReports = () => {
    setLoading(true);
  };
  const onSelectPageinator = (data: any) => {
    const pageData = pageCount;
    pageData.page = data;
    setPageCount(pageData);
    getReports();
  };
  const userData:any = [
    {
      id: "1",
      site: "site 1",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "2",
      site: "site 2",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "3",
      site: "site 3",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "4",
      site: "site 4",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "5",
      site: "site 5",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "6",
      site: "site 6",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "7",
      site: "site 7",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    {
      id: "8",
      site: "site 8",
      date: "2024-20-01",
      service: "TyShen Kids",
      product_id: "1114",
      operator: "etisalatuae",
      currency: "AED",
      new_sub_count_total: "151",
      new_sub_revenue: "0",
      new_sub_count_free: "151",
      new_sub_count_charged: "0",
      re_Sub_count: "632",
      re_sub_revenue: "1996.77",
      unsub_count: "77",
      unsub_count_same_day: "6",
      total_revenue: "1996.77",
      revenue_share: "0",
      total_base: "8857",
    },
    ];
  useEffect(() => {
    const siteId = (params?.site_id);
    const filterData = userData?.filter((el: any) => el?.id === siteId);
    const data = breadcrumbInfo;
    data[1].value = filterData[0].site;
    setBreadcrumbInfo([...data]);
}, [])

  return (
    <div>
        <div className="p-3">
        <BreadCrumb breadCrumbList={breadcrumbInfo}></BreadCrumb>
        </div>
      <div className="sub-header-primary-color py-2">
      <div className='col-md-12 px-4'>
                </div>
        <div className="h-25  ms-2 text-white border-start border-3 border-success">
          {/* <h4 className="text-start ps-3">Reports Dashbaord</h4> */}
          <div className="text-start ps-3">Check the User performance</div>
        </div>
      </div>

      <div className="col-md-12">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card rounded-0 bg-white">
                <div className="card-header text-start py-3">
                  <i className="bi bi-postcard"></i> User Performance
                </div>
                <div className="card-body">
                  <div className="row">
                    <DynamicForm
                      userFormData={revenueReportsForm}
                      columns={3}
                      cancelRoute={""}
                      isEdit={false}
                      custom_button_text={"Login"}
                      onFormSubmitEvent={(e) => onFormSubmitEvent(e)}
                      customClass={"display-block"}
                      loading={""}
                      isNotEmptyFormAfterSubmit={true}
                    />
                  </div>
                </div>
                {/* <div className="mb-2 mb-3">
                  <button className="btn btn-secondary  float-end mx-2">
                    Reset
                  </button>
                  <button className="btn btn-primary float-end mx-2">
                    <i className="bi bi-search"></i> Search{" "}
                  </button>
                </div> */}
                <div className="px-2">
                  <div className="row">
                    <div className="col-12 mixed-chart">
                      <div className="card-header text-start py-3 ">
                        <i className="bi bi-postcard"></i>{" "}
                        <span className="text-start "> User Chart </span>
                      </div>
                      <Chart
                        series={seriesMixedChart}
                        options={optionsMixedChart}
                        type="line"
                        height={500}
                      />
                    </div>
                  </div>
                  <div className="card-header text-start py-3 ">
                    <i className="bi bi-postcard"></i>{" "}
                    <span className="text-start "> User Table </span>
                    <button className="float-end btn btn-outline-primary py-1 ">
                      {" "}
                      Export <i className="bi bi-cloud-arrow-up"></i>
                    </button>
                  </div>
                  <DataTable
                    tableData={tableData}
                    TableCols={ROISiteDataGridCols}
                    onActions={onActions}
                    onPageChange={onPageChange}
                    onSelectPageChange={(e) => onSelectPageinator(e)}
                    pageCount={pageCount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

