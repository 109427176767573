import React, { useEffect, useState } from 'react'
import { DataTable } from '../../../components/data-table'
import { ContactUsDataGridCols } from './contact-us-data-grid-cols';
import { DynamicForm } from '../../../components/form-builder/form';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_USERS, USER_CREATE, USER_DELETE, USER_UPDATE } from '../../../app/services/users.service';
import CountryCodes from '../../../app/utils/mobile-number-country-code.json';
import toast from 'react-hot-toast';
import { Loader } from '../../../components/loader';
import { Search } from '../../../components/search/search';
import { GET_CONTACT_FORM } from '../../../app/services/contact-us-form.service';

export const ContactUsViewPage = () => {
    const params = useParams();

    const [pageCount, setPageCount] = useState<any>({
        limit: 10,
        page: 0,
        uuid: params?.uuid
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [selectedUserData, setSelectedUserData] = useState<any>([]);
    useEffect(() => {
        getCustomers(pageCount);
    }, [])


    const getCustomers = (data: any) => {
        setLoading(true);
        GET_CONTACT_FORM(data).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    setSelectedUserData(res?.data?.rows[0]);
                    setLoading(false);
                }
            }
        });
    }

    return (
        <div className='container-fluid content-bg-color'>
            <div className='row border-bottom-primary'>
                <div className='col-md-9 text-start d-flex align-items-center px-0'>
                    <h4>Contact Us View</h4>
                </div>
            </div>
            <Loader LoadingReducer={loading} />
            <div className='row my-3'>
                <div className='col-md-12 px-0'>
                    <p className='m-0'><strong>Name: </strong>{selectedUserData?.name}</p>
                    <p className='m-0'><strong>Email: </strong>{selectedUserData?.email}</p>
                    <p className='m-0'><strong>Message: </strong>{selectedUserData?.details}</p>
                    <div className='mt-2'>
                        <textarea name="" id="" placeholder='Replay' className='form-control'>
                        </textarea>
                        <button className='btn btn-primary border-0 mt-2'>Send</button>
                    </div>
                    {/* <DataTable
                        tableData={tableListData}
                        TableCols={ContactUsDataGridCols}
                        onActions={(e: any) => onActions(e)}
                        onPageChange={(e: any) => onPageChange(e)}
                        onSelectPageChange={(e) => onSelectPage(e)}
                        pageCount={pageCount}
                    /> */}
                </div>
            </div>
        </div>
    )
}
